define("portal/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['search'],
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    search: null,
    searchType: '',
    searchObserver: Ember.observer('search', function () {
      return Ember.run.debounce(this, 'send', 'startSearch', 1000, true);
    }),
    actions: {
      startSearch() {
        if (this.get('search')) {
          return this.transitionToRoute('search');
        }
      },

      logout() {
        this.get('session').logout().then(() => {
          window.location.reload();
        });
      }

    }
  });

  _exports.default = _default;
});