define("portal/routes/users/show/partners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      controller.set('userPartners', model.get('partners'));
    },

    actions: {
      reloadPartners() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});