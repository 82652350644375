define("portal/controllers/users/show/external", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    nydusRequest: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    rentals: null,
    location: null,
    locations: null,
    integrationSetup: Ember.computed.and('integrationUser.id'),
    integrationLocationSetup: Ember.computed.and('integrationLocation.id'),
    integrationUser: null,
    integrationLocation: null,
    actions: {
      setLocation(location) {
        this.set('location', location);
        this.get('_loadLocation').perform(location.id);
      },

      setExternalLocation(location) {
        let result = {};

        for (let k in location) {
          let camelized = Ember.String.camelize(k);
          result[camelized] = location[k];
        }

        console.log('here', result, this.get('integrationLocation.locationId'));
        this.get('integrationLocation').setProperties(result);
        console.log('after', this.get('integrationLocation.locationId'));
      },

      saveIntegrationUser() {
        this.get('integrationUser').save();
      },

      saveIntegrationLocation() {
        this.get('integrationLocation').save();
      }

    },
    _loadUser: (0, _emberConcurrency.task)(function* () {
      let user;

      try {
        user = yield this.store.findRecord('nydusUser', this.get('model.id'));
      } catch (e) {
        user = this.store.createRecord('nydusUser', {
          userId: this.get('model.id')
        });
      }

      this.set('integrationUser', user);
    }).restartable(),
    _loadLocation: (0, _emberConcurrency.task)(function* (locationId) {
      let rentals = yield this.store.query('rental', {
        location_ids: locationId,
        limit: 100,
        full: true,
        hidden: true
      });
      let location;

      try {
        location = yield this.store.findRecord('nydusLocation', locationId);
      } catch (e) {
        location = this.store.createRecord('nydusLocation', {
          locationId: locationId
        });
      }

      this.set('rentals', rentals);
      this.set('integrationLocation', location);
    }).restartable(),
    _loadLocations: (0, _emberConcurrency.task)(function* () {
      let locations = yield this.store.query('location', {
        owner_id: this.get('model.id')
      }); // request locations as owner

      let user = yield this.apiRequest.request(`auth/actor/${this.get('model.id')}`);
      let options = {
        headers: {
          Authorization: `Token=${user.token}`
        }
      };
      let externalLocations = yield this.get('nydusRequest').post('provider/ingest/locations', options);
      this.set('locations', locations);
      this.set('externalLocations', externalLocations);
    }).restartable()
  });

  _exports.default = _default;
});