define("portal/components/report-ts-queue/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ReportTsQueue = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('args.model'), _dec4 = Ember._action, (_class = class ReportTsQueue extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);

      _initializerDefineProperty(this, "model", _descriptor3, this);

      _defineProperty(this, "tsQueueReportType", 'manual-report');

      _defineProperty(this, "tsQueueMessage", '');

      _defineProperty(this, "failureReasons", ['failure-persona', 'failure-tint']);

      _defineProperty(this, "failureReason", 'failure-persona');

      _initializerDefineProperty(this, "reportToTrustQueue", _descriptor4, this);
    }

    get reportTypes() {
      if (Ember.get(this, 'args.objectType') === 'booking') {
        return ['manual-report', 'driver-verification'];
      }

      return ['manual-report'];
    }

    onCloseAction() {
      if (this.args.onClose) {
        this.args.onClose();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "reportToTrustQueue", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        if (!this.tsQueueReportType || !this.tsQueueMessage) {
          this.flashMessages.danger('Request Type and Message must be set before reporting');
          return;
        }

        try {
          const hasFailureReason = this.tsQueueReportType === 'driver-verification';
          let data = {
            objectId: Number(Ember.get(this, 'model.id')),
            objectType: Ember.get(this, 'args.objectType'),
            score: 10,
            service: this.tsQueueReportType || 'manual-report',
            notes: this.tsQueueMessage
          };

          if (hasFailureReason) {
            data['driverVerificationFailureReason'] = this.failureReason;
          }

          let newRec = yield this.store.createRecord('ts-queue-item', data);
          yield newRec.save();
          this.onCloseAction();

          if (this.args.onSuccess) {
            this.args.onSuccess();
          }
        } catch (err) {
          console.log(err);
          this.flashMessages.danger(`Failed to report: ${err}`);
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onCloseAction", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseAction"), _class.prototype)), _class));
  _exports.default = ReportTsQueue;
});