define("portal/helpers/cloudinary-url", ["exports", "@outdoorsyco/ember-shared-data/helpers/cloudinary-url"], function (_exports, _cloudinaryUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _cloudinaryUrl.default;
    }
  });
  Object.defineProperty(_exports, "cloudinaryUrl", {
    enumerable: true,
    get: function get() {
      return _cloudinaryUrl.cloudinaryUrl;
    }
  });
});