define("portal/components/driver-check/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    actions: {
      deleteDriver(driver) {
        if (confirm('Are you sure, this cannot be undone')) {
          try {
            this.get('adminRequest').delete(`verify/driver/${driver.id}`);
          } catch (err) {
            this.get('flashMessages').danger('There was a problem deleting the driver');
          }

          if (this.get('afterChange')) {
            this.get('afterChange')();
          }
        }
      },

      approveDriver(driver) {
        this.get('adminRequest').request('verify/identity/finalize', {
          method: 'POST',
          data: JSON.stringify({
            driver_id: driver.id,
            score: 'passed'
          })
        });

        if (this.get('afterChange')) {
          this.get('afterChange')();
        }
      },

      denyDriver(driver) {
        this.get('adminRequest').request('verify/identity/finalize', {
          method: 'POST',
          data: JSON.stringify({
            driver_id: driver.id,
            score: 'failed'
          })
        });

        if (this.get('afterChange')) {
          this.get('afterChange')();
        }
      },

      forceCheckr(driver) {
        this.get('adminRequest').request(`/verify/driver/${driver.id}/force`, {
          method: 'POST',
          data: JSON.stringify({
            free_check: true
          })
        });

        if (this.get('afterChange')) {
          this.get('afterChange')();
        }
      }

    }
  });

  _exports.default = _default;
});