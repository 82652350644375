define("portal/controllers/trust-safety-queue", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'service', 'booking_id', 'user_id', 'status'],
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    trustSafetyQueueItems: Ember.computed.alias('model'),
    isEditingNote: false,
    updatedNoteText: '',
    itemToEdit: null,
    threshold: 0,
    sortField: 'score',
    sortDesc: true,
    rejectionConfirmationModalVisible: false,
    itemToReject: undefined,
    perPage: 25,
    page: 1,
    service: null,
    booking_id: null,
    user_id: null,
    status: null,
    bulkStatus: null,
    selectAll: false,
    serviceOptions: Ember.computed(function () {
      let options = [{
        display: '--All--',
        value: null
      }, {
        display: 'sift',
        value: 'sift'
      }, {
        display: 'tint',
        value: 'tint'
      }, {
        display: 'persona',
        value: 'persona'
      }, {
        display: 'manual-report',
        value: 'manual-report'
      }, {
        display: 'driver-verification',
        value: 'driver-verification'
      }, {
        display: 'urgent-driver-verification',
        value: 'urgent-driver-verification'
      }, {
        display: 'request-limit',
        value: 'request-limit'
      }, {
        display: 'stripe-warning',
        value: 'stripe-warning'
      }, {
        display: 'riskified',
        value: 'riskified'
      }];
      return options;
    }),
    statusOptions: Ember.computed(function () {
      return [{
        display: '--Pending--',
        value: null
      }, {
        display: 'unchecked',
        value: 'unchecked'
      }, {
        display: 'cleared',
        value: 'cleared'
      }, {
        display: 'in_review',
        value: 'in_review'
      }, {
        display: 'rejected',
        value: 'rejected'
      }];
    }),
    sortFieldIndicator: Ember.computed('sortField', function () {
      return this.get('sortField') === 'score';
    }),
    scoreSortIndicator: Ember.computed('sortField', 'sortDesc', function () {
      if (this.get('sortField') === 'score' && !this.get('sortDesc')) {
        return '▲';
      }

      return '▼';
    }),
    createdSortIndicator: Ember.computed('sortField', 'sortDesc', function () {
      if (this.get('sortField') !== 'score' && !this.get('sortDesc')) {
        return '▲';
      }

      return '▼';
    }),
    hasSelected: Ember.computed('trustSafetyQueueItems.@each.selected', function () {
      return this.get('trustSafetyQueueItems').any(function (item) {
        return item.selected;
      });
    }),

    loadTsData() {
      this.get('_getTsItems').perform().then(items => {
        this.set('model', items);
      });
    },

    actions: {
      search() {
        this.loadTsData();
      },

      clearFilters() {
        this.setProperties({
          service: null,
          booking_id: null,
          user_id: null,
          status: null
        });
        this.loadTsData();
      },

      sortBy(property) {
        if (this.get('sortField') !== property) {
          this.set('sortField', property);
          this.set('sortDesc', true);
        }

        this.set('sortDesc', !this.get('sortDesc'));
        this.loadTsData();
      },

      updateSiftThreshold() {
        this.get('adminRequest').request(`ts-queue/threshold`, {
          method: 'POST',
          data: JSON.stringify({
            service: 'sift',
            threshold: this.get('threshold')
          })
        }).then(() => {
          this.get('_getSiftThreshold').perform().then(({
            threshold
          }) => this.set('threshold', threshold));
        }).catch(() => {
          this.get('flashMessages').danger('Error setting sift threshold!');
        });
      },

      toggleEditNotesModal(item) {
        if (item.get('objectType') === 'user') {
          Ember.set(item, 'expandNotes', !item.expandNotes);
          return;
        }

        this.setProperties({
          itemToEdit: item ? item : null,
          isEditingNote: !this.get('isEditingNote')
        });
      },

      editNote(item) {
        if (!this.get('updatedNoteText')) {
          return this.get('flashMessages').danger('please add a new note');
        }

        Ember.set('item', 'notes', this.get('updatedNoteText'));
        item.save().then(() => {
          this.set('updatedNoteText', '');
          this.send('loadTsData');
          this.send('toggleEditNotesModal');
        }).catch(err => {
          console.log(err);
          this.get('flashMessages').danger('Error updating note.');
        });
      },

      toggleSelectAll() {
        const value = !this.get('selectAll');
        this.set('selectAll', value);
        this.get('trustSafetyQueueItems').forEach(item => {
          item.set('selected', value);
        });
      },

      toggleSelect(id) {
        let items = this.get('trustSafetyQueueItems');
        const currentItem = items.find(item => item.id === id);
        currentItem.set('selected', !currentItem.selected);
      },

      updateStatus() {
        const items = this.get('trustSafetyQueueItems');
        const selectedIds = items.filter(item => item.selected).map(item => item.objectId);
        this.set('selectAll', false);
        this.get('trustSafetyQueueItems').forEach(item => {
          item.set('selected', false);
        });
        this.get('adminRequest').request(`ts-queue`, {
          method: 'PATCH',
          data: JSON.stringify({
            object_ids: selectedIds,
            status: this.bulkStatus
          })
        }).then(() => {
          this.loadTsData();
        }).catch(() => {
          this.get('flashMessages').danger('Error updating status!');
        });
      }

    },
    _getTsItems: (0, _emberConcurrency.task)(function* () {
      yield this.get('_getSiftThreshold').perform().then(({
        threshold
      }) => this.set('threshold', threshold));
      let sort = this.get('sortField');

      if (this.get('sortDesc')) {
        sort = `-${sort}`;
      }

      let params = {
        status: 'unchecked,in_review',
        order_by: sort
      };

      if (this.status) {
        params.status = this.status;
      }

      if (this.service) {
        params.service = this.service;
      }

      if (this.booking_id) {
        params.booking_id = this.booking_id;
        params.object_type = 'booking';
      }

      if (this.user_id) {
        params.user_id = this.user_id;
        params.object_type = 'user';
      }

      if (this.status) {
        params.status = this.status;
      }

      return yield this.store.query('ts-queue-item', params);
    }),
    _getSiftThreshold: (0, _emberConcurrency.task)(function* () {
      return yield this.get('adminRequest').request('ts-queue/threshold/sift');
    }),
    _updateSiftThreshold: (0, _emberConcurrency.task)(function* () {
      yield this.get('adminRequest').request(`ts-queue/threshold`, {
        method: 'POST',
        data: JSON.stringify({
          service: 'sift',
          threshold: this.get('threshold')
        })
      });
    })
  });

  _exports.default = _default;
});