define("portal/adapters/partner-app", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType() {
      return `partners/:partnerId/apps`;
    },

    buildURL(type, id, snapshot, requestType, query) {
      let url = this._super(...arguments);

      if (snapshot) {
        let partnerId = snapshot.belongsTo('partner', {
          id: true
        });
        url = url.replace(':partnerId', partnerId);
      } else if (query && query.partner_id) {
        url = url.replace(':partnerId', query.partner_id);
        delete query.partner_id;
      }

      return url;
    }

  });

  _exports.default = _default;
});