define("portal/routes/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),
    adminRequest: Ember.inject.service(),

    beforeModel() {
      if (this.session.user.get('adminLevel') < 7) {
        this.transitionTo('search');
      }
    },

    model() {
      return this.store.findAll('integration');
    },

    actions: {
      saveIntegration(model) {
        if (model.save) {
          model.save().then(() => {
            this.get('flashMessages').success('Integration Saved Successfully');
          }).catch(error => {
            this.get('flashMessages').danger('An error has occurred.');
            console.error(error);
          });
        }
      },

      deleteIntegration(integration) {
        if (confirm('Are you sure you want to delete this integration?')) {
          integration.destroyRecord();
        }
      },

      copyIntegration(integration) {
        this.get('adminRequest').post(`integrations/${integration.get('id')}/duplicate`).then(() => {
          this.send('reloadIntegrations');
          this.get('flashMessages').success('Integration copied successfully');
        });
      },

      reloadIntegrations: function reloadIntegrations() {
        this.store.findAll('integration').then(response => {
          this.set('model', response);
        });
      }
    }
  });

  _exports.default = _default;
});