define("portal/serializers/partner", ["exports", "ember-data", "portal/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      partnerApps: {
        deserialize: 'records',
        serialize: false
      }
    },

    normalize(typeClass, hash) {
      if (hash.apps) {
        hash.partner_apps = hash.apps;
      }

      return this._super(typeClass, hash);
    },

    serialize(record, options) {
      const data = this._super(record, options);

      return data;
    }

  });

  _exports.default = _default;
});