define("portal/adapters/booking", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    // need a special handler for bookings, cause sometimes we authenticate users by creating a bookings
    // this is ONLY used for dealer users, when creating a booking as an anonymous user
    handleResponse(status, headers, payload, requestData) {
      if (headers['Anon-Token'] || headers['anon-token']) {
        payload.anon_token = headers['Anon-Token'] || headers['anon-token'];
        payload.anon_user_id = headers['Anon-User-Id'] || headers['anon-user-id'];
      }

      return this._super(status, headers, payload, requestData);
    },

    urlForQuery(query, modelName) {
      let url = this._super(query, modelName);

      if (query.shared) {
        url = url.replace('/bookings', '/bookings/shared');
        delete query.shared;
      }

      return url;
    }

  });

  _exports.default = _default;
});