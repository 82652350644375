define("portal/controllers/incomplete-bookings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const perPage = 2000;

  var _default = Ember.Controller.extend({
    queryParams: ['page'],
    page: 1,
    totalPages: Ember.computed('model.meta.total', function () {
      return (this.get('model.meta.total') || 0) / perPage;
    }),
    sortBy: 'default',
    bookings: Ember.computed.alias('model'),
    sortProperties: ['bookingCreated'],
    sortedBookings: Ember.computed.sort('bookings', 'sortProperties'),
    actions: {
      setPage(page) {
        this.set('page', page);
      },

      sortBy(sortProperties) {
        this.set('sortBy', sortProperties);
        this.set('sortProperties', ['bookingCreated', sortProperties]);
      },

      viewDetails(incomplete) {
        let booking = this.store.findRecord('booking', incomplete.get('id'));
        let notes = this.store.query('note', {
          booking_id: incomplete.get('id'),
          internal: true
        });
        this.setProperties({
          bookingDetails: booking,
          bookingNotes: notes,
          currentIncomplete: incomplete
        });
      },

      archiveBooking(incomplete) {
        incomplete.destroyRecord();
        this.send('closeDetails');
      },

      closeDetails() {
        this.setProperties({
          bookingDetails: null,
          bookingNotes: null
        });
      }

    }
  });

  _exports.default = _default;
});