define("portal/utils/remove-auth-params", ["exports", "@outdoorsyco/ember-shared-data/utils/remove-auth-params"], function (_exports, _removeAuthParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _removeAuthParams.default;
    }
  });
});