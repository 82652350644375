define("portal/components/review-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    review: null,
    editable: true,
    session: Ember.inject.service(),
    reviewInfo: Ember.computed(function () {
      return 'Booking #' + Ember.get(this, 'review.booking.id') + ' Rental #' + Ember.get(this, 'review.rental.id');
    }),
    actions: {
      edit(review) {
        if (this.get('editReview')) {
          this.get('editReview')(review);
        }
      },

      delete(review) {
        if (this.get('deleteReview')) {
          this.get('deleteReview')(review);
        }
      }

    }
  });

  _exports.default = _default;
});