define("portal/components/create-update-rental-tags/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CreateUpdateRentalTags = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class CreateUpdateRentalTags extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "adminRequest", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);

      _defineProperty(this, "rentalTagOperation", this.args.tagOperation ? this.args.tagOperation : 'create');

      _initializerDefineProperty(this, "rentalTagAction", _descriptor3, this);
    }

    onCloseAction() {
      if (this.args.onClose) {
        this.args.onClose();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adminRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rentalTagAction", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        if (!this.args.tagData.name) {
          this.flashMessages.danger('Please specify tag name');
          return;
        }

        if (!this.args.tagData.slug) {
          this.flashMessages.danger('Please specify tag slug');
          return;
        }

        if (this.args.tagOperation && this.args.tagOperation === 'create') {
          yield this.adminRequest.request(`tags`, {
            method: 'POST',
            data: this.args.tagData
          }).then(() => {
            this.flashMessages.success('Rental tag created');

            if (this.args.onClose) {
              this.args.onClose();
            }
          }).catch(() => {
            this.flashMessages.danger('Error when creating rental tag.');
          });
        } else if (this.args.tagOperation && this.args.tagOperation === 'update') {
          if (this.args.tagData && this.args.tagData.id) {
            yield this.adminRequest.request(`tags/${this.args.tagData.id}`, {
              method: 'PATCH',
              data: this.args.tagData
            }).then(() => {
              this.flashMessages.success('Rental Tag ' + this.args.tagData.name + ' updated');

              if (this.args.onClose) {
                this.args.onClose();
              }
            }).catch(response => {
              this.flashMessages.danger(response);
            });
          }
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onCloseAction", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseAction"), _class.prototype)), _class));
  _exports.default = CreateUpdateRentalTags;
});