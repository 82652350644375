define("portal/components/validations/checkr-line/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    check: null,
    afterChange: null,
    canPerform: Ember.computed('check.status', function () {
      if (Ember.get(this, 'check.arguments.booking_id') && !this.booking) {
        return false;
      }

      return true;
    }),
    deleteDriver: (0, _emberConcurrency.task)(function* () {
      if (!Ember.get(this, 'canPerform')) {
        return;
      }

      if (!confirm('Are you sure, this cannot be undone')) {
        return;
      }

      try {
        yield this.get('adminRequest').delete(`verify/driver/${this.check.validation_id}`);
      } catch (err) {
        this.get('flashMessages').danger('There was a problem deleting the driver');
      }

      if (this.get('afterChange')) {
        this.get('afterChange')();
      }
    }),
    approveDriver: (0, _emberConcurrency.task)(function* () {
      if (!Ember.get(this, 'canPerform')) {
        return;
      }

      if (!confirm('Are you sure you want to force approve this driver?')) {
        return;
      }

      yield this.get('adminRequest').request('verify/identity/finalize', {
        method: 'POST',
        data: JSON.stringify({
          driver_id: this.check.validation_id,
          score: 'passed'
        })
      });

      if (this.get('afterChange')) {
        this.get('afterChange')();
      }
    }),
    denyDriver: (0, _emberConcurrency.task)(function* () {
      if (!Ember.get(this, 'canPerform')) {
        return;
      }

      if (!confirm('Are you sure you want to deny this driver?')) {
        return;
      }

      yield this.get('adminRequest').request('verify/identity/finalize', {
        method: 'POST',
        data: JSON.stringify({
          driver_id: this.check.validation_id,
          score: 'failed'
        })
      });

      if (this.get('afterChange')) {
        this.get('afterChange')();
      }
    }),
    forceCheckr: (0, _emberConcurrency.task)(function* () {
      if (!Ember.get(this, 'canPerform')) {
        return;
      }

      yield this.get('adminRequest').request(`/verify/driver/${this.check.validation_id}/force`, {
        method: 'POST',
        data: JSON.stringify({
          free_check: true
        })
      });

      if (this.get('afterChange')) {
        this.get('afterChange')();
      }
    })
  });

  _exports.default = _default;
});