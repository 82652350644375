define("portal/styles/_datepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-datepicker": "ui-datepicker",
    "ui-icon": "ui-icon",
    "ui-icon-circle-triangle-e": "ui-icon-circle-triangle-e",
    "ui-icon-circle-triangle-w": "ui-icon-circle-triangle-w",
    "ui-datepicker-calendar": "ui-datepicker-calendar",
    "available": "available",
    "booked": "booked",
    "ui-state-disabled": "ui-state-disabled",
    "ui-datepicker-other-month": "ui-datepicker-other-month"
  };
  _exports.default = _default;
});