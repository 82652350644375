define("portal/models/partner-app", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    partner: belongsTo('partner'),
    appName: attr('string', {
      defaultValue() {
        return '';
      }

    }),
    appID: attr('string', {
      defaultValue() {
        return '';
      }

    }),
    enabled: attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    defaultApp: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    renterLeadOnlyFeePercentage: attr('number', {
      defaultValue() {
        return 0;
      }

    }),
    ownerLeadOnlyFeePercentage: attr('number', {
      defaultValue() {
        return 0;
      }

    }),
    ownerLeadFeePercentage: attr('number', {
      defaultValue() {
        return 0;
      }

    }),
    renterLeadFeePercentage: attr('number', {
      defaultValue() {
        return 0;
      }

    }),
    created: attr('momentDate'),
    updated: attr('momentDate')
  });

  _exports.default = _default;
});