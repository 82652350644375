define("portal/controllers/users/show/notifications", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserNotificationsController = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('settings.unsubscribed_message_type_ids'), _dec3 = Ember.computed.alias('settings.unsubscribed_channel_ids'), _dec4 = Ember._action, _dec5 = Ember._action, (_class = class UserNotificationsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "adminRequest", _descriptor, this);

      _initializerDefineProperty(this, "unsubscribed", _descriptor2, this);

      _initializerDefineProperty(this, "unchanneled", _descriptor3, this);

      _initializerDefineProperty(this, "saveNotifications", _descriptor4, this);

      _initializerDefineProperty(this, "disablePushNotifications", _descriptor5, this);
    }

    toggleSubscribe(id) {
      if (this.unsubscribed.includes(id)) {
        this.unsubscribed.removeObject(id);
      } else {
        this.unsubscribed.pushObject(id);
      }
    }

    toggleChannel(id) {
      if (this.unchanneled.includes(id)) {
        this.unchanneled.removeObject(id);
      } else {
        this.unchanneled.pushObject(id);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adminRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "unsubscribed", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "unchanneled", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleSubscribe", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSubscribe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleChannel", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "toggleChannel"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saveNotifications", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        try {
          yield this.adminRequest.patch(`users/iterable/${Ember.get(this, 'user.id')}`, {
            data: {
              unsubscribed_message_type_ids: this.settings.unsubscribed_message_type_ids,
              unsubscribed_channel_ids: this.settings.unsubscribed_channel_ids
            }
          });
          this.flashMessages.success('Notifications updated');
        } catch (err) {
          this.flashMessages.danger(err);
        }
      };
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "disablePushNotifications", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        try {
          yield this.adminRequest.post(`admin/users/${Ember.get(this, 'user.id')}/disable-notification-tokens`);
          this.flashMessages.success('Push notifications disabled');
        } catch (err) {
          this.flashMessages.danger(err);
        }
      };
    }
  })), _class));
  _exports.default = UserNotificationsController;
});