define("portal/utils/rental-to-slug", ["exports", "@outdoorsyco/outdoorsy-addon/utils/rental-to-slug"], function (_exports, _rentalToSlug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _rentalToSlug.default;
    }
  });
});