define("portal/routes/users/show/protection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      this.store.query('bundle', {
        owner_id: model.get('id')
      }).then(results => {
        controller.set('bundles', results);
        controller.processBundles();
      });
    }

  });

  _exports.default = _default;
});