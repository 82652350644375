define("portal/components/booking/rental-detail/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['renter-booking', 'col-xs-12'],
    hasDetail: false,
    bookingDetails: Ember.computed.alias('renter.bookingDetails'),

    init() {
      this._super(...arguments);

      if (this.get('renter.bookingDetails.length') === 1) {
        this.set('hasDetail', true);
      }
    },

    bookingsCount: Ember.computed.alias('bookingDetails.length'),
    bookingsOwnerApproveCount: Ember.computed('bookingDetails', function () {
      let bookingDetails = this.get('bookingDetails');
      return bookingDetails.filter(booking => {
        return booking.owner_approved;
      }).length;
    }),
    sortingFrom: ['booking_from', 'owner_id'],
    sortedBookings: Ember.computed.sort('bookingDetails', 'sortingFrom'),
    firstCreatedAt: Ember.computed.alias('sortedBookings.firstObject.booking_created'),
    firstFromDate: Ember.computed.alias('sortedBookings.firstObject.booking_from'),
    firstToDate: Ember.computed.alias('sortedBookings.firstObject.booking_to'),

    click() {
      if (this.get('hasDetail')) {
        return;
      }

      this.set('hasDetail', true);
    },

    actions: {
      showDetail() {
        this.toggleProperty('hasDetail');
      }

    }
  });

  _exports.default = _default;
});