define("portal/adapters/review", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForDeleteRecord(id, modelName, snapshot) {
      let url = this._super(id, modelName, snapshot);

      url = url.replace('/reviews/', '/admin/reviews/');
      return url;
    },

    urlForCreateRecord(modelName, snapshot) {
      let url = this._super(modelName, snapshot);

      url = url.replace('/reviews', '/admin/reviews/import');
      return url;
    },

    urlForUpdateRecord(modelName, snapshot) {
      let url = this._super(modelName, snapshot);

      url = url.replace('/reviews/', '/admin/reviews/');
      return url;
    }

  });

  _exports.default = _default;
});