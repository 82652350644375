define("portal/helpers/driver-check-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.driverCheckUrl = driverCheckUrl;
  _exports.default = void 0;

  // This function receives the params `params, hash`
  function driverCheckUrl(params) {
    const url = params[0];
    const userId = params[1];
    const token = params[2];
    const driverId = params[3];

    if (url && url.indexOf('verification.outdoorsy.co') > -1) {
      return `${url}?driver_user_id=${userId}&token=${token}&driver_id=${driverId}`;
    }

    return url;
  }

  var _default = Ember.Helper.helper(driverCheckUrl);

  _exports.default = _default;
});