define("portal/templates/rentals/show/activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JRVrV/+d",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Rental Activity\"],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"pull-right\"],[8],[0,\"\\n  \"],[5,\"pagination-component\",[],[[\"@current\",\"@total\",\"@perPage\",\"@setPage\",\"@oneWay\"],[[23,0,[\"page\"]],[23,0,[\"total\"]],[23,0,[\"perPage\"]],[23,0,[\"setPage\"]],true]]],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"activity-table\",[],[[\"@activities\"],[[23,0,[\"activities\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/rentals/show/activity.hbs"
    }
  });

  _exports.default = _default;
});