define("portal/routes/rentals/show/activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RentalActivity extends Ember.Route {
    afterModel(model) {
      let controller = this.controllerFor('rentals/show/activity');
      controller.loadActivities.perform(model);
    }

  }

  _exports.default = RentalActivity;
});