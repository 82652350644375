define("portal/templates/users/show/employees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bmpY74MA",
    "block": "{\"symbols\":[\"e\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"table table-striped\"],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"ID\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Name\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Email\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"User Type\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"employees\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[4,\"link-to\",null,[[\"route\",\"model\"],[\"users.show\",[23,1,[\"id\"]]]],{\"statements\":[[1,[23,1,[\"id\"]],false]],\"parameters\":[]},null],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"email\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"userType\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[10,\"colspan\",\"4\"],[8],[0,\"This dealer does not have any employees yet\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/users/show/employees.hbs"
    }
  });

  _exports.default = _default;
});