define("portal/adapters/bundle-rule", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType() {
      return 'bundles/:bundleId/rules';
    },

    buildURL(type, id, snapshot, requestType, query) {
      let url = this._super(type, id, snapshot, requestType);

      if (snapshot) {
        url = url.replace(':bundleId', snapshot.belongsTo('bundle', {
          id: true
        }));
      } else if (query && query.bundle_id) {
        url = url.replace(':bundleId', query.bundle_id);
        delete query.bundle_id;
      }

      return url;
    }

  });

  _exports.default = _default;
});