define("portal/serializers/ugc-category", ["exports", "ember-data", "portal/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      subCategories: {
        deserialize: 'records',
        serialize: false
      },
      tags: {
        deserialize: 'records',
        serialize: false
      }
    },
    keyForRelationship: function keyForRelationship(key) {
      return key;
    },

    normalize(model, hash) {
      hash.subCategories = Ember.makeArray(hash.ugc_sub_categories);
      hash.tags = Ember.makeArray(hash.ugc_tags);
      delete hash.ugc_sub_categories;
      delete hash.ugc_tags;
      return this._super(model, hash);
    }

  });

  _exports.default = _default;
});