define("portal/components/modal-container", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      closeModal() {
        this.sendAction('close'); // eslint-disable-line ember/closure-actions
      },

      absorbClick() {
        return false;
      }

    },

    // HACK: Modal requires a body class
    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)('body').addClass('modal-open');
    },

    didDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)('body').removeClass('modal-open');
    }

  });

  _exports.default = _default;
});