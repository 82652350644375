define("portal/components/edit-review/component", ["exports", "dayjs"], function (_exports, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    review: null,
    rentals: null,
    reviewContent: Ember.computed(function () {
      return [null, 1, 2, 3, 4, 5];
    }),
    // these are MinRental structs from the backend. not all fields are available
    rentalList: Ember.computed('rentals.[]', function () {
      let list = (this.rentals || []).map(r => {
        let name = `${r.get('id')} - ${r.get('name')}`;
        return {
          name,
          value: r.get('id')
        };
      });
      list.unshift({
        name: '-- None --',
        value: null
      });
      return list;
    }),
    newReviewDate: Ember.computed('review.timestamp', {
      get() {
        return (0, _dayjs.default)(this.get('review.timestamp')).format('YYYY-MM-DD');
      },

      set(key, value) {
        this.set('review.timestamp', (0, _dayjs.default)(value).format('YYYY-MM-DDTHH:mm:ssZ'));
        return (0, _dayjs.default)(this.get('review.timestamp')).format('YYYY-MM-DD');
      }

    }),
    ownerCanRespond: Ember.computed('review.user', function () {
      if (this.get('review.name')) {
        return false;
      }

      return this.review.get('reviewer.id') !== this.review.get('owner.id');
    }),
    canAddImages: Ember.computed('review.rental', function () {
      return this.get('review.rental.id') && this.session.user.adminLevel;
    }),
    actions: {
      handleLoading(value) {
        this.setProperties({
          reviewLoading: value
        });
      },

      editReview(model) {
        this.set('review', model);
      },

      closeReview() {
        this.set('review.images', []);
        this.get('review').rollbackAttributes();
        this.set('review', null);

        if (this.get('close')) {
          this.get('close')();
        }
      },

      setRentalOnReview(option) {
        let rental = this.rentals.findBy('id', option.value);
        this.set('review.rental', rental);
        this.set('review.images', []);
      },

      saveReview() {
        let rev = this.get('review'); // reviews created from the admin portal use name/source whereas
        // normal review will not have a name field, but a reviewer_id

        let name = rev.get('name');

        if (name || rev.get('isNew')) {
          if (this.get('newReviewSource')) {
            name += ` from ${this.get('newReviewSource')}`;
          }

          if (!name) {
            this.set('reviewError', 'Please enter a name');
            return;
          }

          this.set('newReviewSource', '');
          rev.set('name', name);
        }

        let date = (0, _dayjs.default)(rev.get('timestamp'));

        if (!date || !date.isValid()) {
          this.set('reviewError', 'Please enter a valid date');
          return;
        }

        rev.set('timestamp', date.toDate());

        if (!rev.get('score') && !rev.excludeFromRating) {
          this.set('reviewError', 'Please select a valid score');
          return;
        }

        if (!rev.get('text')) {
          this.set('reviewError', 'Please enter some review text');
          return;
        }

        this.setProperties({
          reviewLoading: true,
          reviewError: null
        });
        let isNew = rev.get('isNew'); // save or update

        rev.save().then(() => {
          if (this.get('onSave')) {
            this.get('onSave')(isNew);
          } // close modal


          this.set('review', null);
        }).catch(() => {
          this.setProperties({
            reviewLoading: false,
            reviewError: 'There was an error creating your review'
          });
        }).finally(() => {
          this.setProperties({
            reviewLoading: false
          });
        });
      }

    }
  });

  _exports.default = _default;
});