define("portal/helpers/our-fee-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OurFeeDetails = (_dec = Ember.inject.service, (_class = class OurFeeDetails extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);
    }

    compute(params) {
      let detailText = '';

      if (params[0]) {
        let charge = params[0];
        let localBaseCurrency = 'USD';

        if (params[1]) {
          localBaseCurrency = params[1];
        }

        const actualCcFeeFormatted = this.intl.formatNumber(charge.actualCcFee, {
          style: 'currency',
          currency: localBaseCurrency
        });
        const actualOutdoorsyFeeFormatted = this.intl.formatNumber(charge.actualOutdoorsyFee, {
          style: 'currency',
          currency: localBaseCurrency
        });
        const actualOwnerFeeFormatted = this.intl.formatNumber(charge.actualOwnerFee, {
          style: 'currency',
          currency: localBaseCurrency
        });
        const actualServiceFeeFormatted = this.intl.formatNumber(charge.actualServiceFee, {
          style: 'currency',
          currency: localBaseCurrency
        });
        const salesTaxFormatted = this.intl.formatNumber(charge.salesTax, {
          style: 'currency',
          currency: localBaseCurrency
        });
        const fxFeeFormatted = this.intl.formatNumber(charge.fxFee, {
          style: 'currency',
          currency: localBaseCurrency
        });
        detailText += 'CC Fee: ' + actualCcFeeFormatted + ' / ';
        detailText += 'Outdoorsy Fee: ' + actualOutdoorsyFeeFormatted + ' / ';
        detailText += 'Owner Fee: ' + actualOwnerFeeFormatted + ' / ';
        detailText += 'Service Fee: ' + actualServiceFeeFormatted + ' / ';
        detailText += 'Sales Tax: ' + salesTaxFormatted + ' / ';
        detailText += 'FxFee: ' + fxFeeFormatted + ' / ';
      }

      return detailText;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OurFeeDetails;
});