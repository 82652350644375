define("portal/adapters/usage-based-item-tier", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType() {
      return `usage-based-items/:usageBasedItem/tiers`;
    },

    buildURL(type, id, snapshot) {
      let url = this._super(type, id, snapshot);

      url = url.replace(':usageBasedItem', snapshot.belongsTo('usageBasedItem', {
        id: true
      }));
      return url;
    }

  });

  _exports.default = _default;
});