define("portal/routes/bundles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),

    model() {
      return this.store.findAll('bundle');
    },

    actions: {
      saveBundle(model) {
        if (model.save) {
          model.save().then(() => {
            this.get('flashMessages').success('Bundle Saved Successfully');
          }).catch(error => {
            console.error(error);
            this.get('flashMessages').danger('An error has occurred.');
          });
        }
      },

      deleteBundle(integration) {
        if (confirm('Are you sure you want to delete this bundle?')) {
          integration.destroyRecord();
        }
      }

    }
  });

  _exports.default = _default;
});