define("portal/controllers/discount-codes/index", ["exports", "@outdoorsyco/ember-shared-data/controllers/account/discount-codes"], function (_exports, _discountCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discountCodes.default.extend({
    actions: {
      newDiscountCode() {
        /* Bubble to top discount-codes route */
        return true;
      },

      editDiscountCode() {
        /* Bubble to top discount-codes route */
        return true;
      }

    }
  });

  _exports.default = _default;
});