define("portal/serializers/insurance-plan", ["exports", "@outdoorsyco/ember-shared-data/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      hash.has_override_plan = false;

      if (hash.insurance_plan_override) {
        hash.has_override_plan = true;
        hash.insurance_plan_override_id = hash.insurance_plan_override;
        delete hash.insurance_plan_override;
      }

      return this._super(typeClass, hash);
    }

  });

  _exports.default = _default;
});