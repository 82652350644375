define("portal/adapters/card", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType() {
      return 'users/:user_id/cards';
    },

    buildURL(type, id, snapshot, requestType, query = {}) {
      let url = this._super(...arguments);

      if (query.user_id) {
        url = url.replace(':user_id', query.user_id);
      } else {
        url = url.replace(':user_id', snapshot.belongsTo('owner', {
          id: true
        }));
      }

      return url;
    }

  });

  _exports.default = _default;
});