define("portal/styles/components/help-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ember-basic-dropdown-trigger": "ember-basic-dropdown-trigger",
    "basic-tooltip-trigger": "basic-tooltip-trigger",
    "icon": "icon",
    "icon-small": "icon-small",
    "icon-medium": "icon-medium",
    "basic-tooltip": "basic-tooltip",
    "drop-fade-below": "drop-fade-below",
    "drop-fade-above": "drop-fade-above",
    "ember-basic-dropdown-content--below": "ember-basic-dropdown-content--below",
    "ember-basic-dropdown--transitioning-in": "ember-basic-dropdown--transitioning-in",
    "ember-basic-dropdown--transitioning-out": "ember-basic-dropdown--transitioning-out",
    "ember-basic-dropdown-content--above": "ember-basic-dropdown-content--above",
    "basic-tooltip-inner": "basic-tooltip-inner"
  };
  _exports.default = _default;
});