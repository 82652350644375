define("portal/templates/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H6yRNtvA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4\"],[8],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[7,\"img\",true],[10,\"src\",\"https://s3.amazonaws.com/outdoorsy-data/assets/ezgif.com-cd34cfba9e.gif\"],[10,\"alt\",\"\"],[8],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/users/index.hbs"
    }
  });

  _exports.default = _default;
});