define("portal/templates/components/page-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZhYgAYSX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isDots\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\",true],[8],[1,[22,\"page\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"a\",false],[12,\"href\",[29,[[22,\"url\"]]]],[3,\"action\",[[23,0,[]],\"select\"]],[8],[1,[22,\"page\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/components/page-item.hbs"
    }
  });

  _exports.default = _default;
});