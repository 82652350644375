define("portal/adapters/note-to-renters", ["exports", "@outdoorsyco/ember-shared-data/adapters/note-to-renters"], function (_exports, _noteToRenters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _noteToRenters.default.extend({
    namespace: 'v0'
  });

  _exports.default = _default;
});