define("portal/components/pagination-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    total: 10,
    perPage: 10,
    current: 1,
    oneWay: false,
    meta: Ember.computed('perPage', 'current', 'total', function () {
      return `Showing ${(this.get('current') - 1) * this.get('perPage') + 1} - ${Math.min(this.get('total'), (this.get('current') - 1) * this.get('perPage') + this.get('perPage'))} of ${this.get('total')}`;
    }),
    pagination: Ember.computed('total', function () {
      return this.get('total') > this.get('perPage');
    }),
    previousPageAvailable: Ember.computed('current', 'total', function () {
      return this.get('current') > 1;
    }),
    nextPageAvailable: Ember.computed('current', 'total', 'perPage', function () {
      return this.get('current') * this.get('perPage') < this.get('total');
    }),
    noPreviousPage: Ember.computed.not('previousPageAvailable'),
    noNextPage: Ember.computed.not('nextPageAvailable'),
    actions: {
      nextPage() {
        if (this.get('nextPageAvailable')) {
          this.send('setPage', this.get('current') + 1);
        }
      },

      setPage(page) {
        if (!this.get('oneWay')) {
          this.set('current', page);
        }

        this.sendAction('setPage', page); // eslint-disable-line ember/closure-actions
      },

      previousPage() {
        if (this.get('previousPageAvailable')) {
          this.send('setPage', this.get('current') - 1);
        }
      }

    }
  });

  _exports.default = _default;
});