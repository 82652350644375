define("portal/adapters/insurance-plan", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType() {
      return 'insurance-plans';
    },

    buildURL(type, id, snapshot, requestType, query) {
      let url = this._super(type, id, snapshot);

      if (url && requestType === 'query' && query && query.forRentals) {
        url = url.replace(/insurance-plans/, 'rentals/insurance-plans');
        return url;
      }

      return url;
    }

  });

  _exports.default = _default;
});