define("portal/utils/scroll-into-view", ["exports", "@outdoorsyco/ember-shared-data/utils/scroll-into-view"], function (_exports, _scrollIntoView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _scrollIntoView.default;
    }
  });
});