define("portal/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "berD153t",
    "block": "{\"symbols\":[\"flash\",\"component\",\"flash\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"flashMessages\",\"queue\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"flash-messages-container\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"flashMessages\",\"queue\"]]],[[\"tagName\"],[\"li\"]],{\"statements\":[[4,\"flash-message\",null,[[\"flash\"],[[23,1,[]]]],{\"statements\":[[0,\"        \"],[7,\"p\",true],[10,\"class\",\"text-center\"],[8],[1,[23,3,[\"message\"]],false],[9],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[28,\"header-nav\",null,[[\"session\",\"search\",\"searchType\",\"logout\"],[[24,[\"session\"]],[24,[\"search\"]],[24,[\"searchType\"]],[28,\"action\",[[23,0,[]],\"logout\"],null]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"main-app\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"switchback\",[],[[],[]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/application.hbs"
    }
  });

  _exports.default = _default;
});