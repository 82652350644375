define("portal/components/service-integration/component", ["exports", "portal/utils/years", "@outdoorsyco/ember-shared-data/utils/vehicle-types"], function (_exports, _years, _vehicleTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    categories: ['roadside', 'trip-insurance', 'damage-protection', 'supplemental-liability-insurance', 'outdoorsy-service'],
    yearOptions: (0, _years.default)(new Date().getFullYear() + 1, 1940),
    vehicleTypeOptions: (0, _vehicleTypes.default)().get('rv') || [],
    priceSplitOptions: [{
      display: 'None',
      value: ''
    }, {
      display: 'Flat Rate',
      value: 'flat'
    }, {
      display: 'Percent',
      value: 'percent'
    }],
    priceTypeOptions: [{
      display: 'Daily',
      value: 'daily'
    }, {
      display: 'Flat Rate',
      value: 'flat'
    }, {
      display: 'Quoted',
      value: 'quoted'
    }],
    chargeTypeOptions: [{
      display: 'Set Amount',
      value: 'normal'
    }, {
      display: 'Percentage',
      value: 'percent'
    }],
    chargeToOptions: [{
      display: 'Renter',
      value: 'renter'
    }, {
      display: 'Owner',
      value: 'owner'
    }],
    lockStatusOptions: [{
      display: '- None -',
      value: ''
    }, {
      display: 'Handed Off',
      value: 'handed_off'
    }, {
      display: 'Returned',
      value: 'returned'
    }],
    percentTypeOptions: [{
      display: 'Rental amount only',
      value: 'rental_amount'
    }, {
      display: 'Entire Subtotal, including addons',
      value: 'subtotal'
    }, {
      display: 'Entire Total, including taxes',
      value: 'total'
    }],
    bookingEvents: Ember.computed(function () {
      return [{
        display: 'Created',
        value: 'booking.created'
      }, {
        display: 'Sealed',
        value: 'booking.sealed'
      }, {
        display: 'Handled Off',
        value: 'booking.handed_off'
      }, {
        display: 'Returned',
        value: 'booking.returned'
      }, {
        display: 'Expired',
        value: 'booking.expired'
      }];
    }),
    actions: {
      setParam(param, value) {
        // select field with complex object
        if (typeof value === 'object' && value.value !== undefined) {
          this.set(`model.${param}`, value.value);
        } else {
          this.set(`model.${param}`, value);
        }
      },

      saveIntegration() {
        if (!this.get('model.name') || !this.get('model.slug') || !this.get('model.category')) {
          this.set('error', 'Please fill in name, slug, and category fields');
          return;
        }

        if (this.get('saveIntegration')) {
          this.get('saveIntegration')(this.get('model'));
        }
      }

    }
  });

  _exports.default = _default;
});