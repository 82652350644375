define("portal/serializers/user", ["exports", "@outdoorsyco/ember-shared-data/serializers/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _user.default.extend({
    normalize(typeClass, hash) {
      hash.links = hash.links || {};
      hash.links.partners = `/v0/users/${hash.id}/partners?order_by=-created`;
      return this._super(typeClass, hash);
    }

  });

  _exports.default = _default;
});