define("portal/models/user", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/models/user"], function (_exports, _emberData, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  _user.default.reopen({
    referredBy: belongsTo('user', {
      async: true,
      inverse: null
    }),
    // admin only
    admin: attr('boolean', {
      defaultValue: false
    }),
    adminLevel: attr('number'),
    ownerFeePercentage: attr('number'),
    renterFeePercentage: attr('number'),
    ccFeePercentage: attr('number'),
    addonFeePercentage: attr('number'),
    waiveServiceFee: attr('boolean'),
    waiveOwnerFee: attr('boolean'),
    waiveAddonFees: attr('boolean'),
    depositReservation: attr('boolean'),
    fraudReviewComplete: attr('boolean'),
    referredRenterPayoutAmount: attr('centsToDollars'),
    referredOwnerPayoutAmount: attr('centsToDollars'),
    referredRenterCreditAmount: attr('centsToDollars'),
    fraudulent: attr('boolean'),
    banned: attr('boolean'),
    hidden: attr('boolean'),
    partners: hasMany('partner-user', {
      async: true
    }),
    useFeesIncrease: attr('boolean', {
      defaultValue: false
    }),
    useInsuranceBundles: attr('boolean', {
      defaultValue: false
    }),
    lastActivityAt: attr('date'),
    security: attr(),
    inactiveBy: attr('number', {
      defaultValue: 0
    }),
    inactiveOn: attr('string'),
    csmAgentId: attr('number', {
      defaultValue: 0
    }),
    isCsmAgent: attr('boolean', {
      defaultValue: false
    }),
    dealId: attr('string', {
      defaultValue: ''
    }),
    ownerFeeBreakdown: Ember.computed('ownerFeePercentage', 'dealer', function () {
      let base = this.get('ownerFeePercentage');

      if (this.get('dealer')) {
        return [{
          tier: 'Under $1,300',
          value: base
        }, {
          tier: '$1301 - $2500',
          value: base - 1
        }, {
          tier: '$2501 - $5000',
          value: base - 3
        }, {
          tier: '$5001+',
          value: base - 5
        }];
      }

      return [{
        tier: 'All Transactions',
        value: base
      }];
    }),
    renterFeeBreakdown: Ember.computed('renterFeePercentage', 'dealer', function () {
      let base = this.get('renterFeePercentage');

      if (this.get('dealer')) {
        return [{
          tier: 'Under $1,300',
          value: base
        }, {
          tier: '$1301 - $2500',
          value: base - 2
        }, {
          tier: '$2501 - $5000',
          value: base - 3
        }, {
          tier: '$5001+',
          value: base - 4
        }];
      }

      return [{
        tier: 'All Transactions',
        value: base
      }];
    }),
    stripeManagedAccountID: Ember.computed('paymentProcessingInfo.[]', function () {
      let list = this.get('paymentProcessingInfo') || [];

      for (let i = 0; i < list.length; i++) {
        return list[0].managed_account_id;
      }

      return null;
    })
  });

  var _default = _user.default;
  _exports.default = _default;
});