define("portal/styles/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "message-context-description": "message-context-description",
    "message-context": "message-context",
    "media-left": "media-left",
    "media-body": "media-body",
    "booking-status": "booking-status",
    "renter_withdrew": "renter_withdrew",
    "renter_cancelled": "renter_cancelled",
    "owner_declined": "owner_declined",
    "owner_cancelled": "owner_cancelled",
    "expired": "expired",
    "approved": "approved",
    "imminent": "imminent",
    "handed_off": "handed_off",
    "negotiating": "negotiating",
    "conversation": "conversation",
    "current-booking": "current-booking",
    "suggest-different-rental": "suggest-different-rental",
    "canceled-bookings-link": "canceled-bookings-link",
    "button-container": "button-container",
    "btn-xs": "btn-xs",
    "payout": "payout",
    "canceled-bookings": "canceled-bookings",
    "action-buttons": "action-buttons",
    "booking-list": "booking-list",
    "booking-dates": "booking-dates",
    "message-container": "message-container",
    "message": "message",
    "avatar": "avatar",
    "message-body": "message-body",
    "message-body-left": "message-body-left",
    "message-body-right": "message-body-right",
    "message-date": "message-date",
    "message-date-right": "message-date-right",
    "message-date-left": "message-date-left"
  };
  _exports.default = _default;
});