define("portal/adapters/affiliate-network", ["exports", "portal/adapters/custom-adapter"], function (_exports, _customAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customAdapter.default.extend({
    pathForType() {
      return 'affiliate_networks';
    }

  });

  _exports.default = _default;
});