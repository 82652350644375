define("portal/components/copy-to-clipboard-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "wrapper": "wrapper_980ou",
    "white-copy": "white-copy_980ou"
  };
  _exports.default = _default;
});