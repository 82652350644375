define("portal/components/booking/booking-detail/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row booking-detail'],
    store: Ember.inject.service(),
    ownerAcceptPercentage: Ember.computed('booking.owner_accept_percent', function () {
      return parseInt(this.get('booking.owner_accept_percent') * 100, 10);
    }),
    ownerPayout: Ember.computed('booking.booking_owner_payout', function () {
      return this.get('booking.booking_owner_payout') / 100;
    }),
    actions: {
      showBooking(booking) {
        let fullBooking = this.get('store').find('booking', booking.id);
        let notes = this.get('store').query('note', {
          booking: booking.id,
          internal: true
        });
        this.set('bookingDetails', fullBooking);
        this.set('bookingNotes', notes);
      },

      hideBooking() {
        this.set('bookingDetails', null);
        this.set('bookingNotes', null);
      }

    }
  });

  _exports.default = _default;
});