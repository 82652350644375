define("portal/controllers/users/show/transactions", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'status', 'created_gt', 'created_lt'],
    page: 1,
    perPage: 20,
    created_gt: null,
    created_lt: null,
    transactions: Ember.A(),
    types: Ember.A(),
    total: 0,
    sortBy: 'created',
    sortDir: 'desc',
    flashMessages: Ember.inject.service(),
    adminRequest: Ember.inject.service(),
    availableTypes: Ember.A(),

    init() {
      this._super(...arguments);
    },

    selectedTypesFilter: Ember.computed('types.[]', function () {
      let status = this.get('types');

      if (status.length === 0) {
        return 'All';
      }

      return status.map(x => x.replace('_', ' ').capitalize()).join(', ');
    }),
    // This page was previously created to show all transactions for an owner, across bookings
    loadTransactions: (0, _emberConcurrency.task)(function* () {
      let limit = 20;
      let types = this.get('types');

      if (Ember.isArray(types)) {
        types = types.toString();
      }

      let orderBy = this.get('sortBy');

      if (this.get('sortDir') === 'desc') {
        orderBy = `-${orderBy}`;
      }

      let createdGt = this.get('created_gt') ? this.get('created_gt').format('YYYY-MM-DD') : null;
      let createdLt = this.get('created_lt') ? this.get('created_lt').format('YYYY-MM-DD') : null;
      let params = {
        owner_id: this.get('model.id'),
        created_gt: createdGt,
        created_lt: createdLt,
        include_failed: false,
        limit: limit,
        type: types,
        offset: (this.get('page') - 1) * limit,
        order: orderBy
      };
      yield this.store.query('charge', params).then(transactions => {
        this.set('transactions', transactions.toArray());
        this.set('total', transactions.get('meta.total'));
        let hash = {};
        transactions.forEach(t => {
          hash[t.get('type')] = true;
        });
        types = Object.keys(hash);
        this.set('availableTypes', types);
      }).catch(err => {
        this.flashMessages.danger(`We failed to load transactions ${err}`);
      });
    }),
    selectedCreatedDates: Ember.computed('created_gt', 'created_lt', function () {
      let from = this.get('created_gt');
      let to = this.get('created_lt');

      if (!from || !to) {
        return '';
      }

      from = (0, _moment.default)(from);
      to = (0, _moment.default)(to);
      return `${from.format('MMM D, YYYY')} - ${to.format('MMM D, YYYY')}`;
    }),
    actions: {
      setDates(param, from, to) {
        let params = {
          page: 1
        };
        params[`${param}_gt`] = from;
        params[`${param}_lt`] = to;
        this.setProperties(params);
      },

      clearFilters() {
        this.setProperties({
          page: 1,
          types: [],
          created_gt: null,
          created_lt: null
        });
      }

    }
  });

  _exports.default = _default;
});