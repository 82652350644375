define("portal/services/affirm", ["exports", "@outdoorsyco/ember-shared-data/services/affirm"], function (_exports, _affirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _affirm.default;
    }
  });
});