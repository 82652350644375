define("portal/controllers/modals/edit-discount-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    buttonText: Ember.computed('model.isNew', function () {
      if (this.model.get('isNew')) {
        return 'Create Code';
      }

      return 'Update Code';
    })
  });

  _exports.default = _default;
});