define("portal/serializers/partner-user", ["exports", "ember-data", "portal/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      partner: {
        serialize: 'ids',
        deserialize: 'ids'
      }
    },

    normalize(typeClass, hash) {
      if (hash.partner) {
        hash.partner.type = 'partner';
      }

      return this._super(typeClass, hash);
    },

    serialize(record, options) {
      const data = this._super(record, options);

      data.partner_id = parseInt(data.partner_id);
      return data;
    }

  });

  _exports.default = _default;
});