define("portal/routes/bundles/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      let service = this.store.createRecord('bundle');
      controller.set('model', service);
    }

  });

  _exports.default = _default;
});