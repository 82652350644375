define("portal/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AUmVpQuc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"spinner\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"spinner\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"rect1\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"rect2\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"rect3\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"rect4\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"rect5\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});