define("portal/components/edit-affiliate/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    affiliate: null,

    init() {
      this._super(...arguments);

      this.set('availableNetworks', this.get('store').query('affiliateNetwork', {}));
    },

    currentTemplateVars: Ember.computed('affiliate.{keys}', function () {
      let vars = Ember.copy(this.get('affiliate.keys') || {}, true);
      delete vars.outdoorsy_url_params;
      return vars;
    }),
    currentURLVars: Ember.computed('affiliate.{keys}', function () {
      let vars = Ember.copy(this.get('affiliate.keys') || {}, true);
      return vars.outdoorsy_url_params;
    }),
    actions: {
      updateTemplateVars(newVars) {
        let original = Ember.copy(this.get('affiliate.keys') || {}, true);
        this.set('affiliate.keys', Ember.assign(newVars, {
          outdoorsy_url_params: original.outdoorsy_url_params
        }));
      },

      updateURLVars(newVars) {
        let original = Ember.copy(this.get('affiliate.keys') || {}, true);
        this.set('affiliate.keys', Ember.assign(original, {
          outdoorsy_url_params: newVars
        }));
      },

      saveChanges() {
        this.get('affiliate').save().then(() => {
          if (this.get('onClose')) {
            this.get('onClose')();
          }
        }).catch(() => {
          this.get('flashMessages').danger('There was an issue saving your changes');
        });
      }

    }
  });

  _exports.default = _default;
});