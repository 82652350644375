define("portal/routes/users/show/affiliate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let id = this.paramsFor('users.show').user_id;
      return this.store.query('affiliate', {
        'filter[user_id]': id
      });
    },

    actions: {
      reloadRoute() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});