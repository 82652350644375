define("portal/components/notes-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    noteBody: null,
    noteColor: null,
    notes: null,

    init() {
      this._super(...arguments);

      this.noteSort = ['note.created:desc'];
      this.notesArray = [];
      this.colorOptions = [{
        display: 'No color',
        value: null
      }, {
        display: 'Red',
        value: 'red'
      }, {
        display: 'Blue',
        value: 'blue'
      }, {
        display: 'Green',
        value: 'green'
      }, {
        display: 'Yellow',
        value: 'yellow'
      }];
    },

    sortedNotes: Ember.computed.sort('model.notes', 'noteSort'),

    _save(model) {
      this.setProperties({
        savingNote: true,
        error: false
      });
      let data = {
        text: this.get('noteBody'),
        label: this.get('noteColor'),
        [model.get('constructor.modelName')]: model,
        internal: true
      };
      let note = this.get('store').createRecord('note', data);
      note.save().then(() => {
        this.setProperties({
          savingNote: false,
          noteBody: null
        });
      }).catch(() => {
        this.setProperties({
          error: 'There was an error saving your note',
          savingNote: false
        });
      });
    },

    actions: {
      deleteNote(note) {
        note.destroyRecord();
      },

      saveNote() {
        if (this.get('noteBody')) {
          if (this.model.then) {
            this.model.then(m => {
              this._save(m);
            });
          } else {
            this._save(this.model);
          }
        }
      }

    }
  });

  _exports.default = _default;
});