define("portal/components/partner-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    partners: null,
    selectedPartner: null,
    viewAppsPartner: null,
    selectedPartnerApp: null,
    actions: {
      // partners
      createPartner() {
        let partner = this.get('store').createRecord('partner');
        this.set('selectedPartner', partner);
      },

      savedPartner(p) {
        this.set('selectedPartner', null);

        if (this.get('savedPartner')) {
          this.get('savedPartner')(p);
        }
      },

      setCurrentPartner(partner) {
        let cp = this.get('selectedPartner');

        if (cp && partner === undefined) {
          cp.rollbackAttributes();
        }

        this.set('selectedPartner', partner);
      },

      deleteCurrentPartner(partner) {
        if (window.confirm(`Are you sure you want to remove ${partner.get('name')}?`)) {
          partner.destroyRecord().then(() => {
            if (this.get('deletedPartner')) {
              this.get('deletedPartner')();
            }
          });
        }
      },

      // apps
      savedPartnerApp(p) {
        this.set('selectedPartnerApp', null);

        if (this.get('savedPartnerApp')) {
          this.get('savedPartnerApp')(p);
        }
      },

      createNewPartnerApp(partner) {
        let partnerApp = this.get('store').createRecord('partner-app', {
          partner
        });
        this.set('selectedPartnerApp', partnerApp);
      },

      viewAppsPartner(partner) {
        this.set('viewAppsPartner', partner);
      },

      setCurrentPartnerApp(app) {
        let cpa = this.get('selectedPartnerApp');

        if (cpa && app === undefined) {
          cpa.rollbackAttributes();
        }

        this.set('selectedPartnerApp', app);
      },

      deleteCurrentPartnerApp(app) {
        if (window.confirm(`Are you sure you want to remove ${app.get('appName')}?`)) {
          app.destroyRecord().then(() => {
            if (this.get('deletedPartnerApp')) {
              this.get('deletedPartnerApp')();
            }
          });
        }
      }

    }
  });

  _exports.default = _default;
});