define("portal/routes/users/show/rentals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel(model) {
      let controller = this.controllerFor('users/show/rentals');
      controller.get('_loadRentals').perform(model);
    }

  });

  _exports.default = _default;
});