define("portal/models/location", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr('string'),
    phone: attr('string'),
    taxRate: belongsTo('tax', {
      async: true,
      inverse: null
    }),
    taxRates: hasMany('tax', {
      async: true
    }),
    specialHours: hasMany('special-hour', {
      async: false,
      inverse: null
    }),
    street: attr('string'),
    streetEtc: attr('string'),
    city: attr('string'),
    state: attr('string'),
    zip: attr('string'),
    country: attr('string'),
    lat: attr('number'),
    lng: attr('number'),
    customInsuranceText: attr('string'),
    paymentProcessingInfoId: attr('number'),
    eligibleForSystemTaxRates: attr('boolean', {
      defaultValue: false
    }),
    eligibleForCustomTaxRates: attr('boolean', {
      defaultValue: false
    }),
    taxSplitEligible: attr('boolean', {
      defaultValue: false
    }),
    optOutMarketplaceTaxes: attr('boolean'),
    stateTaxId: attr('string'),
    mondayPickupUnavailable: attr('boolean'),
    mondayDropoffUnavailable: attr('boolean'),
    mondayPickupStart: attr('number'),
    mondayPickupEnd: attr('number'),
    mondayDropoffStart: attr('number'),
    mondayDropoffEnd: attr('number'),
    tuesdayPickupUnavailable: attr('boolean'),
    tuesdayDropoffUnavailable: attr('boolean'),
    tuesdayPickupStart: attr('number'),
    tuesdayPickupEnd: attr('number'),
    tuesdayDropoffStart: attr('number'),
    tuesdayDropoffEnd: attr('number'),
    wednesdayPickupUnavailable: attr('boolean'),
    wednesdayDropoffUnavailable: attr('boolean'),
    wednesdayPickupStart: attr('number'),
    wednesdayPickupEnd: attr('number'),
    wednesdayDropoffStart: attr('number'),
    wednesdayDropoffEnd: attr('number'),
    thursdayPickupUnavailable: attr('boolean'),
    thursdayDropoffUnavailable: attr('boolean'),
    thursdayPickupStart: attr('number'),
    thursdayPickupEnd: attr('number'),
    thursdayDropoffStart: attr('number'),
    thursdayDropoffEnd: attr('number'),
    fridayPickupUnavailable: attr('boolean'),
    fridayDropoffUnavailable: attr('boolean'),
    fridayPickupStart: attr('number'),
    fridayPickupEnd: attr('number'),
    fridayDropoffStart: attr('number'),
    fridayDropoffEnd: attr('number'),
    saturdayPickupUnavailable: attr('boolean'),
    saturdayDropoffUnavailable: attr('boolean'),
    saturdayPickupStart: attr('number'),
    saturdayPickupEnd: attr('number'),
    saturdayDropoffStart: attr('number'),
    saturdayDropoffEnd: attr('number'),
    sundayPickupUnavailable: attr('boolean'),
    sundayDropoffUnavailable: attr('boolean'),
    sundayPickupStart: attr('number'),
    sundayPickupEnd: attr('number'),
    sundayDropoffStart: attr('number'),
    sundayDropoffEnd: attr('number'),
    pickupStartTime: attr('number'),
    pickupEndTime: attr('number'),
    dropoffStartTime: attr('number'),
    dropoffEndTime: attr('number')
  });

  _exports.default = _default;
});