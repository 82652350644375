define("portal/controllers/payout-issues", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    queryParams: ['page', 'booking_id', 'failure_reason'],
    page: 1,
    perPage: 100,
    total: 0,
    booking_id: null,
    failure_reason: null,
    actions: {
      setBooking() {
        Ember.run.debounce(this, 'send', 'loadData', 500);
      },

      setFailureReason() {
        Ember.run.debounce(this, 'send', 'loadData', 500);
      },

      setPage(page) {
        this.set('page', page);
        this.send('loadData');
      },

      loadData() {
        if (this.page <= 0) {
          this.set('page', 1);
        }

        let offset = (this.page - 1) * this.perPage;
        let data = {
          limit: this.perPage,
          offset: offset
        };

        if (parseInt(this.booking_id)) {
          data.booking_id = this.booking_id;
          data.offset = 0;
        }

        if (this.failure_reason) {
          data.failure_reason = this.failure_reason;
        }

        this.get('adminRequest').request(`payout-issues`, {
          method: 'GET',
          data: data
        }).then(results => {
          this.set('model', results);
          const headers = results.fetchResponseMeta.headers;
          this.set('total', headers.get('Total-Results'));
        });
      },

      delete(issue) {
        this.get('adminRequest').delete(`payout-issues/${issue.booking_id}`).then(() => {
          this.get('model').removeObject(issue);
        }).catch(() => {
          this.get('flashMessages').danger('Error deleting issue');
        });
      }

    }
  });

  _exports.default = _default;
});