define("portal/controllers/rentals/show/pricing", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    pricingRequest: Ember.inject.service(),

    // called by route's setupController
    async setup() {
      const rulesResponse = await this.pricingRequest.request(`rules?rental_id=${this.model.id}`);
      const todayResponse = await this.getPrices(this.today, this.today);
      return this.setProperties({
        rules: rulesResponse.data,
        todayResponse
      });
    },

    pricingForDateRange: Ember.computed('responseForDateRange', function () {
      return this._transformedObject(this.responseForDateRange);
    }),
    today: Ember.computed(function () {
      return (0, _moment.default)().format('YYYY-MM-DD');
    }),
    dailyChangesForToday: Ember.computed('todayResponse', function () {
      const transformedObject = this._transformedObject(this.todayResponse);

      return transformedObject.daily_changes[this.today];
    }),
    actions: {
      async setDates(startDate, endDate) {
        this.set('selectedDates', `${startDate} - ${endDate}`);
        const response = await this.getPrices(startDate, endDate);
        this.set('maxPotentialPrice', response.max_price * 0.01);
        this.set('actualPrice', response.final_price * 0.01);
        this.set('minDays', response.minimum_booking_days);
        this.set('responseForDateRange', response);
      }

    },

    async getPrices(startDate, endDate) {
      let rentalId = this.model.id; //if we have a child vehicle we always need to use the parent's price

      if (this.model.parent && this.model.parent.get('id')) {
        rentalId = this.model.parent.get('id');
      }

      return await this.pricingRequest.request(`prices/calculate/${rentalId}?booking_start=${startDate}&booking_end=${endDate}`);
    },

    // sort by date, and add rule descriptions
    _transformedObject(originalObject) {
      if (!originalObject) {
        return null;
      }

      const transformedObject = Ember.assign({}, originalObject);
      const transformedDailyChanges = {};
      Object.keys(transformedObject.daily_changes || {}).sort().forEach(date => {
        const transformedDailyChange = transformedObject.daily_changes[date];
        transformedDailyChange.forEach(priceObject => {
          const ruleId = priceObject.rule_id;

          if (ruleId) {
            const matchingRule = this.rules.find(rule => {
              return Number(rule.id) === Number(ruleId);
            });

            if (matchingRule) {
              priceObject.rule_description = matchingRule.attributes.description;
            }
          }
        });
        transformedDailyChanges[date] = transformedDailyChange;
      });
      transformedObject.daily_changes = transformedDailyChanges;
      return transformedObject;
    }

  });

  _exports.default = _default;
});