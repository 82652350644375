define("portal/styles/_flash-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "flash-messages-container": "flash-messages-container",
    "scale-in": "scale-in",
    "expand": "expand",
    "alert": "alert",
    "alert-success": "alert-success",
    "alert-danger": "alert-danger",
    "fade-in": "fade-in"
  };
  _exports.default = _default;
});