define("portal/controllers/users/show/claims", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    loadClaims: (0, _emberConcurrency.task)(function* () {
      this.set('loadingClaims', true);
      yield this.store.query('claim', {
        user_id: this.model.get('id')
      }).then(claims => {
        this.set('claims', claims.toArray());
      }).catch(err => {
        this.flashMessages.danger(`We failed to load claims ${err}`);
      }).finally(() => {
        this.set('loadingClaims', false);
      });
    })
  });

  _exports.default = _default;
});