define("portal/templates/bookings/show/iterable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hbO0ByPo",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"Hey there soldier, should you be here?\"],[9],[0,\"\\n  \"],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"This page is for generating test events for Iterable/other campaign systems. It sends targetted events through our odc-metrics system\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"h1\",true],[8],[0,\"Step 1. Check this how cute this is!\"],[9],[0,\"\\n\"],[7,\"img\",true],[10,\"style\",\"max-width:500px;max-height:300px;\"],[11,\"src\",[29,[\"https://odc-eye-bleach.s3-us-west-2.amazonaws.com/\",[23,0,[\"imageNumber\"]],\".jpg\"]]],[10,\"alt\",\"cute cat\"],[8],[9],[0,\"\\n\\n\"],[7,\"h1\",true],[8],[0,\"Step 2. Select an event\"],[9],[0,\"\\n\"],[5,\"select\",[],[[\"@value\",\"@options\",\"@searchField\",\"@onChange\",\"@placeholder\"],[[23,0,[\"selectedEvent\"]],[23,0,[\"eventOptions\"]],\"name\",[28,\"action\",[[23,0,[]],\"eventSelected\"],null],\"Select an event\"]],{\"statements\":[[0,\"\\n  \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\\n\"],[7,\"h1\",true],[8],[0,\"Step 3. Template data generator\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"  margin-bottom-0.5 \"],[8],[0,\"\\n  \"],[5,\"textarea-input\",[[12,\"style\",\"height: 30em;\"]],[[\"@value\",\"@onChange\"],[[23,0,[\"tempData\"]],[28,\"fn\",[[28,\"mut\",[[23,0,[\"tempData\"]]],null]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"button\",[[12,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"perform\",[[23,0,[\"_sendEvent\"]]],null]],null]]],[[\"@variant\",\"@disabled\"],[\"brand-primary\",[23,0,[\"_sendEvent\",\"isRunning\"]]]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"if\",[[23,0,[\"_sendEvent\",\"isRunning\"]],\"Sending...\",\"Send Event\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/bookings/show/iterable.hbs"
    }
  });

  _exports.default = _default;
});