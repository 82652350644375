define("portal/adapters/driver-application", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DriverApplicationAdapter extends _application.default {
    pathForType() {
      return 'driver-applications';
    }

  }

  _exports.default = DriverApplicationAdapter;
});