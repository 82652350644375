define("portal/helpers/phone-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(params) {
      let intl = this.get('intl');
      let empty, number;
      number = params[0];
      empty = params[1] || intl.t('term-no-phone');

      if (number) {
        number = number.replace(/[^0-9]/gi, '');

        if (number.length === 10) {
          return number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }

        return number;
      }

      return empty;
    }

  });

  _exports.default = _default;
});