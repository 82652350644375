define("portal/controllers/users/show/reviews", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    page: 1,
    perPage: 100,
    total: 0,
    editReview: null,
    rentals: null,
    reviewType: 'owner',
    loadReviews: (0, _emberConcurrency.task)(function* () {
      let revParams = {};

      if (this.reviewType === 'owner') {
        revParams.owner_id = this.get('model.id');
      } else if (this.reviewType === 'user') {
        revParams.user_id = this.get('model.id');
      } else if (this.reviewType === 'reviewer') {
        revParams.reviewer_id = this.get('model.id');
      } else {
        revParams.owner_id = this.get('model.id');
      }

      revParams.limit = this.perPage;
      revParams.offset = (this.get('page') - 1) * this.perPage;
      yield this.store.query('review', revParams).then(reviews => {
        this.set('total', reviews.meta.total ? reviews.meta.total : 0);
        this.set('reviews', reviews);
      });
    }),
    actions: {
      newReview() {
        this.set('editReview', this.store.createRecord('review', {
          user: this.model
        }));
      },

      setType(type) {
        this.set('page', 1);
        this.set('reviewType', type);
        this.loadReviews.perform();
      },

      editReview(model) {
        this.set('editReview', model);
      },

      closeReview() {
        this.set('editReview', null);
      },

      onSave(newModel) {
        // we don't need to reload when review is updated
        if (newModel) {
          this.loadReviews.perform();
        }
      },

      deleteReview(review) {
        if (confirm('Are you sure you want to delete this review? It cannot be recovered.')) {
          review.destroyRecord().then(() => {
            this.loadReviews.perform();
          });
        }
      },

      setPage(page) {
        this.set('page', page);
        this.loadReviews.perform();
      }

    }
  });

  _exports.default = _default;
});