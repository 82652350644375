define("portal/components/roamly-info/component", ["exports", "ember-concurrency", "portal/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    user: null,
    checkoutURL: null,
    dashboardURL: null,
    scheduleURL: null,
    sourceOptions: null,
    selectedSource: null,
    fullScheduleURL: Ember.computed('scheduleURL', function () {
      if (!this.scheduleURL) {
        return null;
      }

      const urlSplit = (this.scheduleURL || '').split('?');
      const searchParams = new URLSearchParams(urlSplit[1]);
      searchParams.append('name', [this.user.firstName, this.user.lastName].join(' '));
      searchParams.append('email', this.user.email);
      searchParams.append('a2', this.user.phone);
      searchParams.append('utm_campaign', 'admin-portal-schedule');
      return `${urlSplit[0]}?${searchParams.toString()}`;
    }),

    init() {
      this._super(...arguments);

      this.getRoamlyInfo.perform();
      this.sourceOptions = [{
        display: '-- Select a reason --',
        value: null
      }, {
        display: 'Outbound call - schedule',
        value: 'admin-portal-outbound-schedule'
      }, {
        display: 'Outbound call - transfer',
        value: 'admin-portal-outbound-transfer'
      }, {
        display: 'Outbound call - email request',
        value: 'admin-portal-outbound-email-request'
      }, {
        display: 'Listing coach - schedule',
        value: 'admin-portal-listing-coach-schedule'
      }, {
        display: 'Listing coach - transfer',
        value: 'admin-portal-listing-coach-transfer'
      }, {
        display: 'Listing coach - email request',
        value: 'admin-portal-listing-coach-email-request'
      }, {
        display: 'Support - schedule',
        value: 'admin-portal-support-schedule'
      }, {
        display: 'Support - transfer',
        value: 'admin-portal-support-transfer'
      }, {
        display: 'Support - email request',
        value: 'admin-portal-support-email-request'
      }, {
        display: 'Agent - data import',
        value: 'admin-portal-agent-data-import'
      }];
    },

    getRoamlyInfo: (0, _emberConcurrency.task)(function* () {
      this.set('checkoutURL', null);
      this.set('dashboardURL', null);
      this.set('scheduleURL', null);
      let subdomain = _environment.default.APP.runtimeEnvironment === 'production' ? 'www' : 'staging';

      try {
        let response = yield this.apiRequest.request(`roamly/users/${this.get('user.id')}`);

        if (response !== null && response !== void 0 && response.quote_id) {
          let agentURL = `https://${subdomain}.roamly.com/agents/quotes/${response === null || response === void 0 ? void 0 : response.quote_id}`;
          this.set('dashboardURL', agentURL);
        }

        this.set('scheduleURL', response === null || response === void 0 ? void 0 : response.schedule_url);
        this.set('checkoutURL', response === null || response === void 0 ? void 0 : response.checkout_url);
        this.set('roamlyStatus', response === null || response === void 0 ? void 0 : response.status);
      } catch (e) {
        console.log(e);
        this.flashMessages.danger('There was an issue loading info from Roamly');
      }
    }).drop(),
    startRoamlyQuote: (0, _emberConcurrency.task)(function* () {
      if (!this.dashboardURL && !this.selectedSource) {
        this.flashMessages.danger('Please select a reason before quoting');
        return;
      }

      try {
        let data = {};

        if (this.selectedSource) {
          data.source = this.selectedSource;
        }

        let response = yield this.apiRequest.post(`roamly/users/${this.get('user.id')}/import`, {
          data: data
        });
        let subdomain = _environment.default.APP.runtimeEnvironment === 'production' ? 'www' : 'staging';
        this.set('scheduleURL', response === null || response === void 0 ? void 0 : response.schedule_url);
        this.set('dashboardURL', `https://${subdomain}.roamly.com/agents/quotes/${response === null || response === void 0 ? void 0 : response.quote_id}`);
        this.set('checkoutURL', response === null || response === void 0 ? void 0 : response.checkout_url);
      } catch (e) {
        console.log(e);
        this.flashMessages.danger('There was an issue submitting your request');
      }
    }).drop(),
    _login: function _login() {
      return this.apiRequest.request(`auth/actor/${this.get('user.id')}`);
    }
  });

  _exports.default = _default;
});