define("portal/components/date-picker", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    picker: null,
    minDate: null,
    maxDate: null,
    rightAlign: false,
    date: null,
    readonly: true,
    classNames: ['date-field'],
    setMinDate: Ember.observer('minDate', function () {
      if (this.get('minDate')) {
        let date = (0, _moment.default)(this.get('minDate'));

        if (date.isValid()) {
          this.$().datepicker('option', 'minDate', date.toDate());

          if (!this.get('date') || (0, _moment.default)(this.get('date')).isBefore(date)) {
            let newDate = date.clone().add(3, 'days');
            this.sendAction('onChange', newDate.format('MM/DD/YYYY')); //eslint-disable-line ember/closure-actions

            this.$().datepicker('setDate', newDate.toDate());
          }
        }
      }
    }),

    didInsertElement() {
      let date = this.get('date');
      let min = this.get('minDate');
      let max = this.get('maxDate');
      this.$().datepicker({
        minDate: min && min.isValid && min.isValid() ? min.format('MM/DD/YYYY') : undefined,
        maxDate: max && max.isValid && max.isValid() ? max.format('MM/DD/YYYY') : undefined,
        dateFormat: 'mm/dd/yy',
        defaultDate: date ? date.toDate() : undefined
      });

      if (date) {
        date = (0, _moment.default)(date);
        this.$().datepicker('setDate', date.toDate());
        this.$().on('change', () => {
          this.set('date', this.$().val()); // eslint-disable-next-line ember/closure-actions

          this.sendAction('onChange', (0, _moment.default)(this.$().val()));
        });
      } else if (this.get('allowBlank')) {
        this.$().on('change', () => {
          this.set('date', (0, _moment.default)(this.$().val())); // eslint-disable-next-line ember/closure-actions

          this.sendAction('onChange', (0, _moment.default)(this.$().val()));
        });
      }
    },

    willDestroyElement() {
      this.$().datepicker('destroy');
    }

  });

  _exports.default = _default;
});