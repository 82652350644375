define("portal/routes/renter-bookings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      }
    },

    model(params) {
      let page = params.page || 1;
      let limit = 20;
      let offset = (page - 1) * limit;
      return this.store.query('renterBooking', {
        limit,
        offset
      });
    }

  });

  _exports.default = _default;
});