define("portal/components/user-info/component", ["exports", "portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    environment: Ember.inject.service(),
    session: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    apiError: Ember.inject.service(),
    booking: null,
    model: null,
    loginDetails: false,
    payoutID: '',
    userToBan: false,
    userToBanData: null,
    userToUnbanish: false,
    userToUnbanishData: null,
    otherBanReason: null,
    hideUserDialog: false,
    hideUserDialogTitle: 'Hide User',
    hideUserDialogFunc: 'hideUser',
    userToHideReason: null,

    init() {
      this._super(...arguments);

      this.userToBanData = {
        reason: null,
        notes: ''
      };
      this.userToUnbanishData = {
        reason: null,
        notes: ''
      };
    },

    urlPath(app) {
      let booking = this.get('booking.id');

      if (app === 'wheelbase' && booking) {
        return `/reservations/${booking}`;
      } else if (booking) {
        return `/bookings/${booking}`;
      }

      return '';
    },

    canUnBanUser: Ember.computed.alias('canBanUser'),
    canBanUser: Ember.computed('model.dealer', function () {
      if (!this.model) {
        return false;
      }

      let adminLevel = this.session.user.adminLevel;

      if (this.model.dealer && adminLevel >= 10) {
        return true;
      }

      return !this.model.dealer && adminLevel > 6;
    }),
    appsUsed: Ember.computed('model.metadata.apps', function () {
      let appsObject = this.get('model.metadata.apps');

      if (!appsObject) {
        return [];
      }

      let sorted = Object.entries(appsObject).sort((a, b) => b[1].last_activity_at < a[1].last_activity_at).map(entry => {
        // Normalize for the cases where _host means _owner and _guest means _renter
        if (entry[0] === 'outdoorsy_android_host') {
          return 'outdoorsy_android_owner';
        } else if (entry[0] === 'outdoorsy_android_guest') {
          return 'outdoorsy_android_renter';
        }

        return entry[0];
      });
      return sorted;
    }),
    proPageUrl: Ember.computed('model.id', function () {
      const host = _environment.default.APP.outdoorsyHost || 'https://www.outdoorsy.com';
      const userId = this.get('model.id');
      return `${host}/users/${userId}`;
    }),
    actions: {
      banUser() {
        let userToBanDetails = Object.assign({}, this.get('userToBanData'));

        if (!userToBanDetails || !userToBanDetails.reason) {
          this.get('flashMessages').danger('Please select a reason!');
          return;
        }

        if (userToBanDetails.reason === 'Other') {
          if (!this.otherBanReason) {
            this.get('flashMessages').danger('Please type a other reason');
            return;
          } else if (this.otherBanReason.length < 10) {
            this.get('flashMessages').danger('The other reason text need to be at least 10 characters');
            return;
          }

          userToBanDetails.reason = 'Other - ' + this.otherBanReason;
        }

        return this.get('adminRequest').request(`admin/users/${this.get('model.id')}/ban`, {
          method: 'POST',
          data: JSON.stringify(userToBanDetails)
        }).then(() => {
          this.set('userToBan', false);
          return this.get('model').reload();
        }).catch(response => {
          this.apiError.danger(response);
        });
      },

      unbanUser() {
        let userToUnbanishData = this.get('userToUnbanishData');

        if (!userToUnbanishData || !userToUnbanishData.reason) {
          this.get('flashMessages').danger('Please select a reason!');
          return;
        }

        return this.get('adminRequest').request(`admin/users/${this.get('model.id')}/unban`, {
          method: 'POST',
          data: JSON.stringify(userToUnbanishData)
        }).then(() => {
          this.set('userToUnbanish', false);
          return this.get('model').reload();
        }).catch(response => {
          this.apiError.danger(response);
        });
      },

      banUserDialog(openDialog) {
        this.set('userToBan', openDialog);
      },

      unbanUserDialog(openDialog) {
        this.set('userToUnbanish', openDialog);
      },

      hideUserDialog(openDialog, type, func) {
        this.set('hideUserDialog', openDialog);
        this.set('userToHideReason', null);

        if (openDialog && type && func) {
          this.set('hideUserDialogTitle', type);
          this.set('hideUserDialogFunc', func);
        }
      },

      hideUser() {
        if (!this.userToHideReason) {
          this.get('flashMessages').danger('Please add a reason!');
          return;
        }

        return this.get('adminRequest').request(`admin/users/${this.get('model.id')}/hide`, {
          method: 'POST',
          data: {
            admin_note: this.userToHideReason
          }
        }).then(() => {
          this.set('hideUserDialog', false);
          return this.get('model').reload();
        }).catch(response => {
          this.apiError.danger(response);
        });
      },

      unhideUser() {
        if (!this.userToHideReason) {
          this.get('flashMessages').danger('Please add a reason!');
          return;
        }

        return this.get('adminRequest').request(`admin/users/${this.get('model.id')}/unhide`, {
          method: 'POST',
          data: {
            admin_note: this.userToHideReason
          }
        }).then(() => {
          this.set('hideUserDialog', false);
          return this.get('model').reload();
        }).catch(response => {
          this.apiError.danger(response);
        });
      },

      syncPayouts() {
        let params = {};
        let payoutID = this.get('payoutID');
        let msg = 'Are you sure you want to sync payouts this user?';

        if (payoutID !== '') {
          msg = `Are you sure you want to sync payouts for payout with id: ${payoutID}?`;
          params.payout_id = payoutID;
        }

        if (!confirm(msg)) {
          return false;
        }

        return this.get('adminRequest').request(`admin/users/${this.get('model.id')}/sync-payouts`, {
          method: 'POST',
          data: JSON.stringify(params)
        }).then(() => {
          this.get('flashMessages').success('Queued job to process transactions.');
        });
      },

      loginWheelbase: function loginWheelbase() {
        let host = _environment.default.APP.wheelbaseHost || 'https://dashboard.wheelbasepro.com';
        let actorId = this.get('session.user.id');

        this._login().then(response => {
          let uri = `${this.urlPath('wheelbase')}?user_id=${this.model.get('id')}&token=${response.token}&actor_id=${actorId}`;
          let link = `${host}${uri}`;
          this.set('loginWheelBaseLink', link);
          this.set('loginWheelBaseLinkUri', uri);
          this.set('loginLink', '');
          this.set('loginLinkUri', '');
          this.send('toggleLoginDetails');
        });
      },

      loginOutdoorsy() {
        let actorId = this.get('session.user.id');

        this._login().then(response => {
          let isOwner = this.as === 'owner';
          let userId = Ember.get(this, 'model.id');
          let origin = isOwner ? this.environment.originForDashboard : this.environment.originForReserve;
          let path = isOwner ? 'dashboard' : 'trips';
          let uri = `${path}?user_id=${userId}&token=${response.token}&actor_id=${actorId}`;
          let link = `${origin}/${uri}`;
          this.set('loginLink', link);
          this.set('loginLinkUri', uri);
          this.set('loginWheelBaseLink', '');
          this.set('loginWheelBaseLinkUri', '');
          this.send('toggleLoginDetails');
        });
      },

      loginCheckout() {
        let host = _environment.default.APP.checkoutHost || 'https://checkout.wheelbasepro.com';
        let actorId = this.get('session.user.id');
        let booking = this.get('booking.id');
        let path = 'reserve';

        if (booking) {
          path = `bookings/${this.get('booking.id')}`;
        }

        this._login().then(response => {
          let uri = `/${path}?user_id=${this.get('model.id')}&token=${response.token}&actor_id=${actorId}&owner_id=${this.get('model.ownedBy.id')}`;
          let link = `${host}${uri}`;
          this.set('loginCheckoutLink', link);
          this.set('loginCheckoutLinkUri', uri);
          this.send('toggleLoginDetails');
        });
      },

      toggleLoginDetails() {
        this.toggleProperty('loginDetails');
      }

    },
    _login: function _login() {
      return this.apiRequest.request(`auth/actor/${this.get('model.id')}`);
    }
  });

  _exports.default = _default;
});