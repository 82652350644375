define("portal/models/comm-log", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    serviceId: attr('string'),
    serviceType: attr('string'),
    senderId: attr('number'),
    receiverId: attr('number'),
    conversationId: attr('number'),
    messageId: attr('number'),
    sent: attr('date'),
    delivered: attr('date'),
    opened: attr('date'),
    clicked: attr('date'),
    latestStatus: attr('string'),
    latestStatusTime: attr('date'),
    error: attr('string'),
    errorOccurred: attr('date')
  });

  _exports.default = _default;
});