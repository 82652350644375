define("portal/utils/geo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDistance = getDistance;

  function getDistance(lat1, lon1, lat2, lon2) {
    const R = 6371;

    let deg2rad = deg => deg * (Math.PI / 180);

    let dLon = deg2rad(lon2 - lon1);
    let dLat = deg2rad(lat2 - lat1);
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c * 0.62; //distance in miles

    return d;
  }
});