define("portal/styles/renter-bookings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "renter-bookings": "renter-bookings",
    "renter-booking": "renter-booking",
    "booking-detail": "booking-detail",
    "detail-label": "detail-label",
    "background-green": "background-green",
    "background-red": "background-red"
  };
  _exports.default = _default;
});