define("portal/components/validations/persona-line/component", ["exports", "portal/components/validations/evident-line/component", "portal/components/validations/evident-line/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    checkName: 'Persona',
    externalLink: Ember.computed('check.external_id', function () {
      return `https://withpersona.com/dashboard/inquiries/${Ember.get(this, 'check.external_id')}`;
    }),

    _retryMessage() {
      let msg = 'Are you sure you want to create a new validation request? This will void the existing one.';

      if (Ember.get(this, 'check.status') === 'passed') {
        msg = `This is a PASSED driver check! If this verification belongs to another driver on the booking ask cs-eng to re-assign it. ${msg}`;
      }

      return msg;
    },

    // allowing persona to be re-tried, even after successful attempt
    canRetry: Ember.computed('check.status', function () {
      let status = Ember.get(this, 'check.status');
      return status !== 'pending' && status !== 'processing';
    })
  });

  _exports.default = _default;
});