define("portal/components/card-payment-form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NEW_CARD = 'Add a new card';
  const USE_PAYPAL = 'Pay with PayPal';
  const SAME_CARD = 'Use the same card';

  var _default = Ember.Component.extend({
    // user we need to pull cards for
    user: null,
    // if true, we prompt the user to enter in a new card
    allowNewCard: true,
    // if true, we prompt the user to use the already entered card
    // this is used mostly int he checkout flow to allow them
    // to leverage the same card for both the deposit and SD
    allowSameCard: false,
    processorCountry: null,

    async init() {
      this._super(...arguments);

      this.set('availableOptions', await this.fetchAvailableOptions.perform());
      this.setInitialSelectedCard();
    },

    store: Ember.inject.service(),
    fetchAvailableOptions: (0, _emberConcurrency.task)(function* () {
      try {
        const cards = yield this.store.query('card', {
          user_id: Ember.get(this, 'user.id'),
          country: this.processorCountry || 'US'
        });
        let selectable = [];

        if (this.allowNewCard) {
          selectable.push(NEW_CARD);
        }

        if (this.allowSameCard) {
          selectable.push(SAME_CARD);
        }

        if (this.allowPaypal) {
          selectable.push(USE_PAYPAL);
        }

        if (cards.length > 0) {
          cards.forEach(card => {
            selectable.push(card);
          });
        }

        return selectable;
      } catch (error) {
        this.logger.error(error);
      }
    }),

    setInitialSelectedCard() {
      const matchingCard = this.cardId && this.availableOptions.find(option => {
        return Number(option.id) === Number(this.cardId);
      });

      this._changeCard(matchingCard || null);
    },

    _changeCard(card) {
      this.set('selected', card);
      this.onSelect(card);
    },

    actions: {
      changeCard(card) {
        this._changeCard(card);
      },

      onStripeElementReady(stripeElement) {
        Ember.tryInvoke(this, 'onStripeElementReady', [stripeElement]);
      },

      onStripeElementChange(stripeElement, event) {
        Ember.tryInvoke(this, 'onStripeElementChange', [stripeElement, event]);
      }

    }
  });

  _exports.default = _default;
});