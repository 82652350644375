define("portal/controllers/users/show/protection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    marginOptions: Ember.A(['Default', 'Medium', 'Low']),
    selectedMargin: 'Default',
    drivableBundles: Ember.A(),
    towableBundles: Ember.A(),
    bundleRules: Ember.computed(function () {
      return this.get('store').peekAll('bundle-rule');
    }),
    hasOwnerRules: Ember.computed('bundleRules.[]', function () {
      const defaultWheelbaseBundleIDs = this.get('store').peekAll('bundle').map(b => b.get('id'));
      let count = 0;
      this.get('bundleRules').forEach(r => {
        if (defaultWheelbaseBundleIDs.includes(r.get('bundle.id')) && r.get('isOwnerRule')) {
          count++;
        }
      });
      return count > 0;
    }),

    processBundles() {
      let plans = this.get('bundles');
      this.get('drivableBundles').clear();
      this.get('towableBundles').clear();
      let drivableBuckets = new Map();
      let towableBuckets = new Map();
      plans.forEach(plan => {
        let priceMap = {
          towable: new Map(),
          drivable: new Map()
        };
        plan.get('rules').forEach(rule => {
          if (rule.get('type') === 'pricing') {
            let data = rule.get('rule') || {};
            let types = data.vehicle_types || [];
            let key = `${data.min_value}-${data.max_value}`;

            if (types.includes('towable')) {
              if (!priceMap['towable'].has(key)) {
                priceMap['towable'].set(key, {
                  rule: rule,
                  min_value: data.min_value,
                  max_value: data.max_value
                });
                towableBuckets.set(key, {
                  min: data.min_value,
                  max: data.max_value
                });
              } else if (rule.get('isOwnerRule')) {
                let obj = priceMap['towable'].get(key);
                obj.additionalRules = Ember.A(obj.additionalRules);
                obj.additionalRules.addObject(rule);
              }
            } else if (types.includes('drivable')) {
              if (!priceMap['drivable'].has(key)) {
                priceMap['drivable'].set(key, {
                  rule: rule,
                  min_value: data.min_value,
                  max_value: data.max_value
                });
                drivableBuckets.set(key, {
                  min: data.min_value,
                  max: data.max_value
                });
              } else if (rule.get('isOwnerRule')) {
                let obj = priceMap['drivable'].get(key);
                obj.additionalRules = Ember.A(obj.additionalRules);
                obj.additionalRules.addObject(rule);
              }
            } else if (types.includes('a')) {
              key = 'Class A >= 36ft';

              if (!priceMap['drivable'].has(key)) {
                priceMap['drivable'].set(key, {
                  rule: rule,
                  min_value: Number.MAX_SAFE_INTEGER
                });
                drivableBuckets.set(key, {
                  key: key,
                  rate: data.rate,
                  min: Number.MAX_SAFE_INTEGER
                });
              } else if (rule.get('isOwnerRule')) {
                let obj = priceMap['drivable'].get(key);
                obj.additionalRules = Ember.A(obj.additionalRules);
                obj.additionalRules.addObject(rule);
              }
            }
          }
        });
        let towableBundle = {
          name: plan.get('name'),
          tier: plan.get('tier'),
          vehicleType: 'towable',
          prices: [...priceMap['towable'].values()].sortBy('min_value', 'max_value')
        };
        this.get('towableBundles').addObject(towableBundle);
        let drivableBundle = {
          name: plan.get('name'),
          tier: plan.get('tier'),
          vehicleType: 'drivable',
          prices: [...priceMap['drivable'].values()].sortBy('min_value', 'max_value')
        };
        this.get('drivableBundles').addObject(drivableBundle);
      });
      this.set('drivableBuckets', [...drivableBuckets.values()].sortBy('min', 'max'));
      this.set('towableBuckets', [...towableBuckets.values()].sortBy('min', 'max'));
      this.get('drivableBundles').forEach(b => this.addRulePlaceholders(b, this.get('drivableBuckets')));
      this.get('towableBundles').forEach(b => this.addRulePlaceholders(b, this.get('towableBuckets')));
    },

    addRulePlaceholders(bundle, headers) {
      for (let i = 0; i < bundle.prices.length; i++) {
        if (!(headers[i].key && bundle.prices[i].rule.vehicleTypes.includes('a')) && (headers[i].min !== bundle.prices[i].min_value || headers[i].max !== bundle.prices[i].max_value)) {
          bundle.prices.splice(i, 0, {
            placeholder: true
          });
        }
      }
    },

    actions: {
      saveChanges() {
        this.get('store').peekAll('bundle-rule').forEach(rule => {
          if (rule.hasDirtyAttributes) {
            rule.save().catch(() => {
              this.get('flashMessages').danger(`Unable to save rule price of ${rule.get('rate')}`);
            });
          }
        });
      },

      rollbackChanges() {
        this.get('store').peekAll('bundle-rule').forEach(rule => {
          if (rule.hasDirtyAttributes) {
            rule.rollbackAttributes();
          }
        });
      },

      createDealerRules() {
        this.apiRequest.post('bundles/wheelbase/copy', {
          data: {
            owner_id: parseInt(this.get('model.id'))
          }
        }).then(() => {
          window.location.reload(true);
        }).catch(err => {
          let msg = err.payload.error;
          this.get('flashMessages').danger(`Error creating dealer pricing rules: ${msg}`);
        });
      },

      deleteOwnerRules() {
        this.apiRequest.delete(`bundles/wheelbase/rules?owner_id=${this.get('model.id')}`).then(() => {
          window.location.reload(true);
        }).catch(e => {
          let msg = e.payload.error;
          this.get('flashMessages').danger(`Unable to delete dealer custom rules: ${msg}`);
        });
      }

    }
  });

  _exports.default = _default;
});