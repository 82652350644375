define("portal/routes/users/show/credits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      controller.set('credits', this.store.query('earned-credit', {
        user_id: model.get('id')
      }));
    }

  });

  _exports.default = _default;
});