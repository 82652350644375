define("portal/helpers/belongs-to-id", ["exports", "@outdoorsyco/ember-shared-data/helpers/belongs-to-id"], function (_exports, _belongsToId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _belongsToId.default;
    }
  });
  Object.defineProperty(_exports, "belongsToId", {
    enumerable: true,
    get: function get() {
      return _belongsToId.belongsToId;
    }
  });
});