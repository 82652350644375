define("portal/models/message", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    booking: belongsTo('booking', {
      async: true
    }),
    rental: belongsTo('rental', {
      async: true
    }),
    conversation: belongsTo('conversation', {
      async: true
    }),
    files: hasMany('file', {
      async: true
    }),
    text: attr('string'),
    recipient: belongsTo('user', {
      async: true
    }),
    sender: belongsTo('user', {
      async: true
    }),
    actor: belongsTo('user', {
      async: true,
      inverse: null
    }),
    timestamp: attr('date'),
    created: attr('date'),
    read: attr('boolean'),
    spam: attr('boolean'),
    sortable: attr('number'),
    clicked: attr('date'),
    opened: attr('date'),
    latestStatus: attr('string'),
    rentalId: attr('string'),

    /**
     * dealer only prop right now.
     * a message is flagged when content in copy is detected as taking the renter
     * off-platform or to their own site to make reservation
     */
    flagged: attr('boolean', {
      defaultValue: false
    }),

    /**
     * the text that was captured in message to mark as flagged
     */
    flaggedText: attr('string'),
    failedToSendState: Ember.computed('latestStatus', function () {
      let status = this.get('latestStatus');
      let rejects = ['deferral', 'hard_bounce', 'soft_bounce', 'spam', 'reject'];
      return rejects.includes(status);
    }),
    unread: Ember.computed('read', function () {
      // we don't care about unread messages that aren't to me
      return !this.get('isYou') && this.get('read') === false;
    }),
    isYou: Ember.computed('sender.id', function () {
      return !this.get('sender') || this.get('sender.id') === this.get('session.userId') || this.get('sender.id') === this.get('session.ownedById');
    })
  });

  _exports.default = _default;
});