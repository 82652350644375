define("portal/templates/components/pagination-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bUdiVhIe",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"pagination\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"meta\"],[8],[1,[22,\"meta\"],false],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[12,\"disabled\",[22,\"noPreviousPage\"]],[3,\"action\",[[23,0,[]],\"previousPage\"]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-chevron-left\"],[8],[9],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[12,\"disabled\",[22,\"noNextPage\"]],[3,\"action\",[[23,0,[]],\"nextPage\"]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-chevron-right\"],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/components/pagination-component.hbs"
    }
  });

  _exports.default = _default;
});