define("portal/routes/integrations/request-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel() {
      let controller = this.controllerFor('integrations/request-log');
      Ember.run.scheduleOnce('afterRender', () => {
        controller.send('loadRequestLogs');
      });
    }

  });

  _exports.default = _default;
});