define("portal/controllers/partners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showAffiliates: false,
    actions: {
      showAffiliates(val) {
        this.set('showAffiliates', val);
      },

      createAffiliate() {
        let affiliate = this.store.createRecord('affiliateNetwork');
        this.set('activeAffiliate', affiliate);
      },

      cancelActiveAffiliate() {
        this.set('activeAffiliate', null);
        this.send('reloadRoute');
      },

      editAffiliate(affiliate) {
        this.set('activeAffiliate', affiliate);
      }

    }
  });

  _exports.default = _default;
});