define("portal/templates/components/modal-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OWcmvzw0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"modal-container\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal fade in\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"modal-dialog \",[22,\"modalClass\"]]]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n          \"],[7,\"button\",false],[12,\"class\",\"close\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"closeModal\"],[[\"bubbles\"],[false]]],[8],[0,\"\\n            \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[7,\"i\",true],[10,\"class\",\"fa fa-remove\"],[8],[9],[9],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[\"modal-body \",[22,\"modalBodyClass\"]]]],[8],[0,\"\\n          \"],[14,1],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-backdrop fade in\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/components/modal-container.hbs"
    }
  });

  _exports.default = _default;
});