define("portal/adapters/charge", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType() {
      return 'bookings/:booking_id/transactions';
    },

    urlForQuery(query, modelName) {
      let url = this._super(query, modelName);

      if (query.booking_id) {
        url = url.replace(':booking_id', query.booking_id);
        delete query.booking_id;
      } else {
        url = url.replace('/bookings/:booking_id', '');
      }

      return url;
    },

    buildURL(type, id, snapshot, requestType, query) {
      let url = this._super(type, id, snapshot, requestType, query);

      if (snapshot) {
        url = url.replace(':booking_id', snapshot.belongsTo('booking', {
          id: true
        }));
      }

      return url;
    }

  });

  _exports.default = _default;
});