define("portal/components/partner-app-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    app: null,
    actions: {
      savePartnerApp() {
        let cpa = this.get('app');

        if (!cpa) {
          return;
        }

        cpa.save().then(app => {
          if (this.get('savedApp')) {
            this.get('savedApp')(app);
          }
        });
      }

    }
  });

  _exports.default = _default;
});