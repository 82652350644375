define("portal/controllers/discount-codes", ["exports", "portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    rentalCategories: Ember.computed(function () {
      return ['rv', 'stay', 'campground'];
    }),
    isProduction: Ember.computed(function () {
      return _environment.default.APP.runtimeEnvironment === 'production';
    }),
    actions: {
      newDiscountCode() {
        let code = this.store.createRecord('discountCode');
        this.set('editDiscountCode', code);
      },

      editDiscountCode(code) {
        this.set('editDiscountCode', code);
      },

      deleteDiscountCode(code) {
        let confirmed = confirm('Are you sure you want to delete this discount code');

        if (confirmed === true) {
          return code.destroyRecord();
        }
      }

    }
  });

  _exports.default = _default;
});