define("portal/routes/users/show/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel(model) {
      let controller = this.controllerFor('users/show/notes');
      controller.set('userNotes', this.store.query('note', {
        user_id: model.id,
        internal: true
      }));
    }

  });

  _exports.default = _default;
});