define("portal/models/review", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/models/review"], function (_exports, _emberData, _review) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  _review.default.reopen({
    imported: attr('boolean', {
      defaultValue: false
    })
  });

  var _default = _review.default;
  _exports.default = _default;
});