define("portal/components/note-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LslP8wM+",
    "block": "{\"symbols\":[\"@value\",\"@onChange\",\"&attrs\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"for\",\"\"],[10,\"class\",\"form-label\"],[8],[1,[28,\"t\",[\"leave-a-note\"],null],false],[9],[0,\"\\n      \"],[5,\"textarea-input\",[[13,3],[12,\"data-test-note-input-admin-note\",\"\"]],[[\"@value\",\"@onChange\"],[[23,1,[]],[23,2,[]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/components/note-input/template.hbs"
    }
  });

  _exports.default = _default;
});