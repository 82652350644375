define("portal/routes/discount-codes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    templateName: 'discount-codes/index',

    model() {
      return this.store.query('discountCode', {
        apply_to_field: 'service_fee'
      });
    }

  });

  _exports.default = _default;
});