define("portal/controllers/users/show/bookings", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'status', 'created_gt', 'created_lt', 'from_gt', 'from_lt'],
    page: 1,
    perPage: 20,
    created_gt: null,
    created_lt: null,
    from_gt: null,
    from_lt: null,
    bookings: [],
    total: 0,
    status: [],
    sortBy: 'created',
    sortDir: 'desc',
    statusMap: {
      negotiating: 0,
      expired: 0,
      returned: 0,
      owner_cancelled: 0,
      owner_declined: 0,
      renter_cancelled: 0,
      renter_withdrew: 0,
      approved: 0,
      imminent: 0,
      handed_off: 0
    },
    flashMessages: Ember.inject.service(),
    availableStatuses: Ember.A(),
    selectedStatusFilter: Ember.computed('status.[]', function () {
      let status = this.get('status');

      if (status.length === 0) {
        return 'All';
      }

      return status.map(x => x.replace('_', ' ').capitalize()).join(', ');
    }),
    loadReservations: (0, _emberConcurrency.task)(function* () {
      this.set('loadingReservations', true);
      let limit = 20;
      let status = this.get('status');

      if (Ember.isArray(status)) {
        status = status.toString();
      }

      let orderBy = this.get('sortBy');

      if (this.get('sortDir') === 'desc') {
        orderBy = `-${orderBy}`;
      }

      let params = {
        for_user_id: this.get('model.id'),
        from_gt: this.get('from_gt'),
        from_lt: this.get('from_lt'),
        created_gt: this.get('created_gt'),
        created_lt: this.get('created_lt'),
        include_abandoned: true,
        limit: limit,
        status: status,
        offset: (this.get('page') - 1) * limit,
        order: orderBy
      };

      if (this.get('passenger')) {
        params.shared = true;
      }

      yield this.store.query('booking', params).then(bookings => {
        this.set('bookings', bookings.toArray());
        this.set('total', bookings.get('meta.total'));
        let hash = JSON.parse(JSON.stringify(this.statusMap));
        bookings.forEach(b => {
          hash[b.get('status')] += 1;
        });
        let statuses = Ember.A();
        Object.keys(hash).forEach(k => {
          statuses.pushObject(Ember.Object.create({
            value: k,
            count: hash[k]
          }));
        });
        this.set('availableStatuses', statuses);
      }).catch(err => {
        this.flashMessages.danger(`We failed to load bookings ${err}`);
      }).finally(() => {
        this.set('loadingReservations', false);
      });
    }),
    selectedCreatedDates: Ember.computed('created_gt', 'created_lt', function () {
      let from = this.get('created_gt');
      let to = this.get('created_lt');

      if (!from || !to) {
        return '';
      }

      from = (0, _moment.default)(from);
      to = (0, _moment.default)(to);
      return `${from.format('MMM D, YYYY')} - ${to.format('MMM D, YYYY')}`;
    }),
    selectedDepartDates: Ember.computed('from_gt', 'from_lt', function () {
      let from = this.get('from_gt');
      let to = this.get('from_lt');

      if (!from || !to) {
        return '';
      }

      from = (0, _moment.default)(from);
      to = (0, _moment.default)(to);
      return `${from.format('MMM D, YYYY')} - ${to.format('MMM D, YYYY')}`;
    }),
    actions: {
      setDates(param, from, to) {
        let params = {
          page: 1
        };
        params[`${param}_gt`] = from;
        params[`${param}_lt`] = to;
        this.setProperties(params);
        this.loadReservations.perform();
      },

      setParam(param, value) {
        this.set('page', 1);
        this.set(param, value);
        this.loadReservations.perform();
      },

      setPage(page) {
        this.set('page', page);
        this.loadReservations.perform();
      },

      setPassenger(value) {
        if (this.passenger === value) {
          return;
        }

        this.set('passenger', value);
        this.loadReservations.perform();
      },

      setSort(fieldName, direction) {
        this.setProperties({
          sortBy: fieldName,
          sortDir: direction,
          page: 1
        });
        this.loadReservations.perform();
      },

      clearFilters() {
        this.setProperties({
          page: 1,
          status: [],
          created_gt: null,
          created_lt: null,
          from_lt: null,
          from_gt: null
        });
        this.loadReservations.perform();
      }

    }
  });

  _exports.default = _default;
});