define("portal/templates/users/show/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ivhB30r",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\",true],[10,\"class\",\"fa fa-spin fa-refresh\"],[8],[9],[0,\" Loading\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/users/show/loading.hbs"
    }
  });

  _exports.default = _default;
});