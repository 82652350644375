define("portal/controllers/users/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    simpleRequest: Ember.inject.service(),
    adminRequest: Ember.inject.service(),
    firstName: null,
    lastName: null,
    businessName: null,
    street: null,
    city: null,
    state: null,
    zip: null,
    country: null,
    slug: null,
    lat: null,
    lng: null,
    headers: {
      'Content-Type': 'application/json'
    },
    email: Ember.computed('slug', function () {
      return `dealer+${this.get('slug')}@outdoorsy.co`;
    }),
    password: Ember.computed('slug', function () {
      return `${this.get('slug')}1`;
    }),
    actions: {
      createUser() {
        if (!this.get('firstName') || !this.get('lastName') || !this.get('lat') || !this.get('lng') || !this.get('businessName') || !this.get('slug')) {
          this.set('error', 'Please fill in all fields');
          return;
        }

        this.setProperties({
          error: null,
          loadingMessage: 'Creating user...'
        });
        this.get('simpleRequest').request('auth/creds/create', {
          method: 'POST',
          data: JSON.stringify({
            first_name: this.get('firstName'),
            last_name: this.get('lastName'),
            email: this.get('email'),
            password: this.get('password'),
            country: this.get('country')
          })
        }).then(response => {
          this.setProperties({
            loadingMessage: 'Updating user preferences...',
            userId: response.user_id,
            token: response.token
          });
          return this.get('simpleRequest').request(`users/${this.get('userId')}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token=${this.get('token')}`
            },
            data: JSON.stringify({
              address: {
                street: this.get('street'),
                city: this.get('city'),
                state: this.get('state'),
                country: this.get('country')
              },
              business: {
                name: this.get('businessName')
              },
              avatar_url: 'http://res.cloudinary.com/outdoorsy/image/upload/v1480034569/demo-assets/logo.jpg',
              mast_image_url: 'http://res.cloudinary.com/outdoorsy/image/upload/v1480034641/demo-assets/demo-back.png'
            })
          });
        }).then(() => {
          this.set('loadingMessage', 'Setting admin properties...');
          return this.get('adminRequest').request(`admin/users/${this.get('userId')}`, {
            method: 'POST',
            data: JSON.stringify({
              pro: true,
              owner_fee_percentage: 6,
              renter_fee_percentage: 10,
              cc_fee_percentage: 2,
              addon_fee_percentage: 0,
              waive_owner_fee: false,
              waive_service_fee: true,
              waive_addon_fees: true,
              dealer: true,
              slug: this.get('slug'),
              permissions: {
                process_payments: true
              }
            })
          });
        }).then(() => {
          this.set('loadingMessage', 'Creating primary location');
          return this.get('simpleRequest').request('locations', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token=${this.get('token')}`
            },
            data: JSON.stringify({
              name: 'Main Location',
              street: this.get('street'),
              city: this.get('city'),
              state: this.get('state'),
              country: this.get('country'),
              zip: this.get('zip'),
              lat: this.get('lat'),
              lng: this.get('lng')
            })
          });
        }).then(() => {
          this.setProperties({
            loadingMessage: null,
            complete: true
          });
        }).catch(() => {
          this.setProperties({
            loadingMessage: null,
            error: true
          });
        });
      }

    }
  });

  _exports.default = _default;
});