define("portal/components/card-payment-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ABOZeINq",
    "block": "{\"symbols\":[\"card\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[5,\"select\",[[12,\"data-test-cc-select\",\"\"]],[[\"@value\",\"@onChange\",\"@options\",\"@placeholder\"],[[23,0,[\"selected\"]],[28,\"action\",[[23,0,[]],\"changeCard\"],null],[23,0,[\"availableOptions\"]],[23,0,[\"placeholder\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[\"brand\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"comp-card-payment-form-info\"],[[\"brand\",\"lastFour\",\"expMonth\",\"expYear\"],[[23,1,[\"brand\"]],[23,1,[\"lastFour\"]],[23,1,[\"expMonth\"]],[23,1,[\"expYear\"]]]]],false],[0,\"\\n      \"],[5,\"badge\",[],[[\"@variant\"],[\"gray\"]],{\"statements\":[[1,[23,1,[\"displayPaymentProcessor\"]],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,0,[\"selected\"]],[28,\"t\",[\"ui-add-card\"],null]],null]],null,{\"statements\":[[0,\"  \"],[5,\"label\",[[12,\"id\",\"credit\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"form-credit-card\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"input/stripe-card\",[],[[\"@id\",\"@onStripeElementReady\",\"@onStripeElementChange\"],[\"credit\",[28,\"action\",[[23,0,[]],\"onStripeElementReady\"],null],[28,\"action\",[[23,0,[]],\"onStripeElementChange\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/components/card-payment-form/template.hbs"
    }
  });

  _exports.default = _default;
});