define("portal/models/partner", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr('string', {
      defaultValue() {
        return '';
      }

    }),
    slug: attr('string', {
      defaultValue() {
        return '';
      }

    }),
    active: attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    created: attr('momentDate'),
    updated: attr('momentDate'),
    partnerApps: hasMany('partner-app')
  });

  _exports.default = _default;
});