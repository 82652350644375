define("portal/utils/cp-utils", ["exports", "calendar-picker/utils/cp-utils"], function (_exports, _cpUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _cpUtils.default;
    }
  });
});