define("portal/components/edit-js-object/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PaDxgDWE",
    "block": "{\"symbols\":[\"val\",\"key\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"table\"],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Key\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Value\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[28,\"-each-in\",[[24,[\"value\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,2,[]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[7,\"button\",false],[12,\"class\",\"btn btn-danger btn-xs\"],[3,\"action\",[[23,0,[]],\"removeKey\",[23,2,[]]]],[8],[0,\"Remove\"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[8],[0,\"\\n        \"],[1,[28,\"input-field\",null,[[\"value\",\"placeholder\"],[[24,[\"newKey\"]],\"Key\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[0,\"\\n        \"],[1,[28,\"input-field\",null,[[\"value\",\"placeholder\"],[[24,[\"newVal\"]],\"Value\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"btn btn-success btn-xs\"],[3,\"action\",[[23,0,[]],\"addKey\"]],[8],[0,\"Add\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/components/edit-js-object/template.hbs"
    }
  });

  _exports.default = _default;
});