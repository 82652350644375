define("portal/routes/handoffs/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    templateName: 'handoffs/index',
    adminRequest: Ember.inject.service(),

    model() {
      return this.adminRequest.request(`admin/handoffs/stats`);
    },

    afterModel(model) {
      let pct = model.percentage_missing_exif_data || 0;
      model.display_exif_percentage = `${pct * 100}%`;
    }

  });

  _exports.default = _default;
});