define("portal/models/similar-w9", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    emailFromGigwage: attr('string'),
    contractorId: attr('number'),
    userId: attr('number'),
    hasUserCompleted: attr('boolean')
  });

  _exports.default = _default;
});