define("portal/components/sort-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JXYLBtpZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"sortBy\"]],[24,[\"fieldName\"]]],null]],null,{\"statements\":[[0,\"   \"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[28,\"if\",[[24,[\"sortAscending\"]],\"fa-caret-up\",\"fa-caret-down\"],null]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/components/sort-link/template.hbs"
    }
  });

  _exports.default = _default;
});