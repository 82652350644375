define("portal/serializers/activity", ["exports", "ember-data", "portal/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    // API -> what the model expects
    normalize(typeClass, hash) {
      hash.booking_id = hash.booking_info !== null ? hash.booking_info.id : undefined;
      hash.booking_simple_id = hash.booking_info !== null ? hash.booking_info.id : undefined;
      hash.rental_id = hash.rental_info !== null ? hash.rental_info.id : undefined;
      hash.receiver_id = hash.receiver_info !== null ? hash.receiver_info.id : undefined;
      hash.actor_id = hash.actor_info !== null ? hash.actor_info.id : undefined;
      hash.actor_simple_id = hash.actor_info !== null ? hash.actor_info.id : undefined;
      hash.actor_first_name = hash.actor_info !== null ? hash.actor_info.first_name : undefined;
      hash.actor_last_name = hash.actor_info !== null ? hash.actor_info.last_name : undefined;
      hash.actor_avatar_url = hash.actor_info !== null ? hash.actor_info.avatar_url : undefined;
      hash.booking_from = hash.booking_info !== null ? hash.booking_info.from : undefined;
      hash.booking_to = hash.booking_info !== null ? hash.booking_info.to : undefined;
      hash.timestamp = hash.created;
      return this._super(typeClass, hash);
    }

  });

  _exports.default = _default;
});