define("portal/routes/user-notice-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      this.controllerFor('user-notice-events').send('loadData');
    }

  });

  _exports.default = _default;
});