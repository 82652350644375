define("portal/utils/get-window-height", ["exports", "ember-mobile-core/utils/get-window-height"], function (_exports, _getWindowHeight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _getWindowHeight.default;
    }
  });
});