define("portal/services/features", ["exports", "ember-feature-flags/services/features", "portal/config/environment"], function (_exports, _features, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _features.default.extend({
    config: _environment.default,

    init() {
      this._super(...arguments);

      if (_environment.default.featureFlags) {
        this.setup(_environment.default.featureFlags);
      }
    }

  });

  _exports.default = _default;
});