define("portal/controllers/rentals/show/competitive", ["exports", "ember-concurrency", "moment", "portal/utils/geo"], function (_exports, _emberConcurrency, _moment, _geo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    statData: null,
    pricingRequest: Ember.inject.service(),
    chart: null,
    _loadCompSet: (0, _emberConcurrency.task)(function* (rentalId) {
      let data = yield this.get('pricingRequest').request(`prices/compset/${rentalId}`);
      let rentals = data.rentals ? data.rentals : [];
      rentals.forEach(rental => {
        rental.distance = (0, _geo.getDistance)(rental.location.latitude, rental.location.longitude, this.get('model.location.lat'), this.get('model.location.lng'));
      });
      this.set('compDataLength', rentals.length);
      this.set('compData', rentals);
    }),
    _loadStats: (0, _emberConcurrency.task)(function* (rentalId) {
      let startDate = (0, _moment.default)().format('YYYY-MM-DD');
      let endDate = (0, _moment.default)().add(30, 'days').format('YYYY-MM-DD');
      let tripLength = 1;
      let data = yield this.get('pricingRequest').request(`prices/similar/${rentalId}?start_day=${startDate}&end_day=${endDate}`);
      let prices = yield this.get('pricingRequest').request(`prices/calculate/batch/${rentalId}/days/${tripLength}?start_day=${startDate}&end_day=${endDate}`);
      data.forEach(day => {
        day.date = (0, _moment.default)(day.date, 'YYYY-MM-DD');
        day.booked_percentage = Number(day.booked_percentage);
      });
      data = data.sort((a, b) => {
        return a.date - b.date;
      });
      let xAxis = ['x'];
      let bookedPrice = ['Booked prices'];
      let unbookedPrice = ['Unbooked prices'];
      let bookedPercentage = ['Utilization'];
      let searchVolume = ['Search volume'];
      let actualPrices = ['Rental price'];
      prices.booking_prices.forEach(day => {
        actualPrices.push(day.price * 0.01);
      });
      data.forEach(day => {
        xAxis.push(day.date);
        bookedPrice.push(day.average_booked_price * 0.01);
        unbookedPrice.push(day.average_unbooked_price * 0.01);
        bookedPercentage.push(day.booked_percentage);
        searchVolume.push(day.search_count);
      });
      this.set('data', {
        x: 'x',
        columns: [xAxis, bookedPercentage, bookedPrice, unbookedPrice, searchVolume, actualPrices],
        type: 'line',
        types: {
          Utilization: 'bar'
        },
        axes: {
          Utilization: 'y2'
        },
        colors: {
          Utilization: '#E6E6FA',
          'Search volume': '#FF8C00',
          'Booked prices': '#006400',
          'Unbooked prices': '#4682B4',
          'Rental price': '#42f4e2'
        },
        regions: {
          'Search volume': [{
            style: 'dashed'
          }]
        }
      });
      this.set('axis', {
        x: {
          type: 'timeseries',
          tick: {
            format: '%Y-%m-%d'
          }
        },
        y: {
          range: {
            min: 0
          },
          padding: {
            top: 0,
            bottom: 0
          },
          min: 0
        },
        y2: {
          show: true,
          range: {
            min: 0,
            max: 100
          },
          padding: {
            bottom: 0,
            top: 0
          },
          min: 0,
          max: 100
        }
      });
      this.set('pricingInfo', data);
    })
  });

  _exports.default = _default;
});