define("portal/components/validations/roamly-risk-line/component", ["exports", "portal/components/validations/evident-line/component", "portal/components/validations/evident-line/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    checkName: 'Roamly Risk',
    // TODO add tessera dashboard link with external_id in URL
    externalLink: Ember.computed('check.external_id', function () {
      return '';
    }),

    _retryMessage() {
      let msg = 'Are you sure you want to create a new validation request? This will void the existing one.';

      if (Ember.get(this, 'check.status') === 'passed') {
        msg = `This is a PASSED verification check! ${msg}`;
      }

      return msg;
    },

    // allowing tessera to be re-tried, even after successful attempt
    canRetry: Ember.computed('check.status', function () {
      let status = Ember.get(this, 'check.status');
      return status !== 'pending' && status !== 'processing';
    })
  });

  _exports.default = _default;
});