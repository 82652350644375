define("portal/helpers/map-icon-options", ["exports", "@outdoorsyco/ember-shared-data/helpers/map-icon-options"], function (_exports, _mapIconOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mapIconOptions.default;
    }
  });
  Object.defineProperty(_exports, "mapIconOptions", {
    enumerable: true,
    get: function get() {
      return _mapIconOptions.mapIconOptions;
    }
  });
});