define("portal/styles/spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "spinner": "spinner",
    "stretchdelay": "stretchdelay",
    "rect2": "rect2",
    "rect3": "rect3",
    "rect4": "rect4",
    "rect5": "rect5"
  };
  _exports.default = _default;
});