define("portal/components/edit-js-object/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    newKey: '',
    newVal: '',
    value: null,
    actions: {
      removeKey(key) {
        if (Ember.isBlank(key)) {
          return;
        }

        let original = Ember.copy(this.get('value') || {}, true);
        delete original[key];

        if (this.get('onChange')) {
          this.get('onChange')(original);
        }
      },

      addKey() {
        let key = this.get('newKey');
        let val = this.get('newVal');

        if (Ember.isBlank(key) || Ember.isBlank(val)) {
          return;
        }

        let original = Ember.copy(this.get('value') || {}, true);
        original[key] = val;

        if (this.get('onChange')) {
          this.get('onChange')(original);
        }

        this.set('newKey', '');
        this.set('newVal', '');
      }

    }
  });

  _exports.default = _default;
});