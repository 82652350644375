define("portal/styles/spaces", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ptn": "ptn",
    "pvn": "pvn",
    "pan": "pan",
    "ptx": "ptx",
    "pvx": "pvx",
    "pax": "pax",
    "pts": "pts",
    "pvs": "pvs",
    "pas": "pas",
    "ptm": "ptm",
    "pvm": "pvm",
    "pam": "pam",
    "ptl": "ptl",
    "pvl": "pvl",
    "pal": "pal",
    "prn": "prn",
    "phn": "phn",
    "prx": "prx",
    "phx": "phx",
    "prs": "prs",
    "phs": "phs",
    "prm": "prm",
    "phm": "phm",
    "prl": "prl",
    "phl": "phl",
    "pbn": "pbn",
    "pbx": "pbx",
    "pbs": "pbs",
    "pbm": "pbm",
    "pbl": "pbl",
    "pln": "pln",
    "plx": "plx",
    "pls": "pls",
    "plm": "plm",
    "pll": "pll",
    "mtn": "mtn",
    "mvn": "mvn",
    "man": "man",
    "mtx": "mtx",
    "mvx": "mvx",
    "max": "max",
    "mts": "mts",
    "mvs": "mvs",
    "mas": "mas",
    "mtm": "mtm",
    "mvm": "mvm",
    "mam": "mam",
    "mtl": "mtl",
    "mvl": "mvl",
    "mal": "mal",
    "mrn": "mrn",
    "mhn": "mhn",
    "mrx": "mrx",
    "mhx": "mhx",
    "mrs": "mrs",
    "mhs": "mhs",
    "mrm": "mrm",
    "mhm": "mhm",
    "mrl": "mrl",
    "mhl": "mhl",
    "mbn": "mbn",
    "mbx": "mbx",
    "mbs": "mbs",
    "mbm": "mbm",
    "mbl": "mbl",
    "mln": "mln",
    "mlx": "mlx",
    "mls": "mls",
    "mlm": "mlm",
    "mll": "mll"
  };
  _exports.default = _default;
});