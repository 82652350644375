define("portal/controllers/users/show/rentals", ["exports", "portal/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    page: 1,
    perPage: 20,
    total: 0,
    rentals: null,
    originLink: Ember.computed(function () {
      return _environment.default.APP.outdoorsyHost || 'https://www.outdoorsy.com';
    }),
    _loadRentals: (0, _emberConcurrency.task)(function* (model) {
      let params = {
        owner_id: model.get('id'),
        status: 'all',
        full: true,
        limit: this.perPage,
        offset: (this.page - 1) * this.perPage,
        rejected: true,
        // include rejected for admins
        hidden: true
      };
      this.set('isLoading', true);
      yield this.store.query('rental', params).then(response => {
        this.set('rentals', response);
        this.set('total', response.meta.total);
      });
      this.set('isLoading', false);
    }).restartable(),
    actions: {
      setPage(page) {
        this.set('page', page);
        this.get('_loadRentals').perform(this.model);
      }

    }
  });

  _exports.default = _default;
});