define("portal/templates/discount-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hpnAY7F6",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Discount Codes\"],null],false],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"editDiscountCode\"]]],null,{\"statements\":[[4,\"modal-container\",null,[[\"title\",\"close\"],[\"Edit Discount Code\",[28,\"toggle-action\",[\"editDiscountCode\",[23,0,[]]],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"edit-discount-code\",null,[[\"model\",\"rentalCategories\",\"isPortal\",\"isProduction\",\"closeModal\"],[[24,[\"editDiscountCode\"]],[24,[\"rentalCategories\"]],true,[23,0,[\"isProduction\"]],\"closeModal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/discount-codes.hbs"
    }
  });

  _exports.default = _default;
});