define("portal/routes/users/show/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserNotificationsRoute = (_dec = Ember.inject.service, (_class = class UserNotificationsRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "adminRequest", _descriptor, this);
    }

    get user() {
      return this.modelFor('users.show');
    }

    async model() {
      let response = await this.adminRequest.request(`users/iterable/${this.user.id}`);
      let obj = Ember.Object.create(response);

      if (obj.get('unsubscribed_message_type_ids') === undefined) {
        obj.set('unsubscribed_message_type_ids', Ember.A());
      }

      if (obj.get('unsubscribed_channel_ids') === undefined) {
        obj.set('unsubscribed_channel_ids', Ember.A());
      }

      return obj;
    }

    setupController(controller, model) {
      controller.setProperties({
        settings: model,
        user: this.user
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adminRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UserNotificationsRoute;
});