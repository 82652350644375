define("portal/routes/bookings/show/external", ["exports", "portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    apiRequest: Ember.inject.service(),

    model() {
      let params = this.paramsFor('bookings/show');
      let booking = this.modelFor('bookings/show');
      let host = _environment.default.APP.nydusHost; // let host = 'http://localhost:5000';
      // get owner auth token and request raw external booking details

      return this.apiRequest.request(`auth/actor/${booking.get('owner.id')}`).then(user => {
        let options = {
          headers: {
            Authorization: `Token=${user.token}`
          }
        };
        return this.apiRequest.request(`${host}/provider/bookings/${params.booking_id}/raw`, options).then(res => {
          return res;
        }).catch(err => {
          return err;
        });
      });
    }

  });

  _exports.default = _default;
});