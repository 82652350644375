define("portal/components/external-vehicle/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'tr',
    rental: null,
    integrationRental: null,

    didInsertElement() {
      this.get('_loadRental').perform();
    },

    actions: {
      toggleExternal() {
        this.get('rental').toggleProperty('external');
        this.get('rental').save();
      },

      saveIntegrationRental() {
        this.get('integrationRental').save();
      }

    },
    _loadRental: (0, _emberConcurrency.task)(function* () {
      let rental;

      try {
        rental = yield this.get('store').find('nydusRental', this.get('rental.id'));
      } catch (e) {
        rental = this.get('store').createRecord('nydusRental', {
          rentalId: this.get('rental.id'),
          locationId: this.get('rental.currentLocation.id'),
          userId: this.get('rental.owner.id')
        });
      }

      this.set('integrationRental', rental);
    })
  });

  _exports.default = _default;
});