define("portal/session-stores/application", ["exports", "ember-simple-auth/session-stores/cookie"], function (_exports, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cookie.default.extend({
    cookieName: 'outdoorsy-session',
    cookieExpirationTime: 2592000,
    logger: Ember.computed(function () {
      return Ember.getOwner(this).lookup('logger:outdoorsy');
    }),

    restore() {
      // we're blowing up cause a bad cookie is getting set in the CMS pages so when we land on
      // on an ember page this method is erring. This is hopefully a temporary fix to just load
      // the page
      try {
        // see if original method works
        return this._super(...arguments);
      } catch (error) {
        // if not just return empty object and log the error
        this.logger.error(error);
        return Ember.RSVP.resolve({});
      }
    }

  });

  _exports.default = _default;
});