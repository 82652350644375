define("portal/routes/users/show/employees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel(model) {
      let controller = this.controllerFor('users/show/employees');
      controller.set('employees', this.store.query('user', {
        owned_by_id: model.get('id'),
        employee: true
      }));
    }

  });

  _exports.default = _default;
});