define("portal/controllers/-csv-export", ["exports", "portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create({
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),
    adminRequest: Ember.inject.service(),
    csvRoute: '',
    _pollAttempts: 0,
    actions: {
      cancelCSV() {
        this.setProperties({
          csvDownload: null,
          loadingCSV: false
        });
      },

      pollCSV(token) {
        let path = this.get('csvRoute');
        this.adminRequest.request(`${path}?csv-token=${token}`, {
          method: 'HEAD'
        }).then(() => {
          this.setProperties({
            csvDownload: `${_environment.default.APP.apiHost}/v0/${path}?csv-token=${token}`
          });
        }).catch(() => {
          this.incrementProperty('_pollAttempts'); // only poll for 2 minutes

          if (this.get('_pollAttempts') < 120) {
            Ember.run.later(this, function () {
              this.send('pollCSV', token);
            }, 1000);
          } else {
            this.set('loadingCSV', false);
            this.flashMessages.danger('There was an issue generating your file.');
          }
        });
      },

      getCSV() {
        if (Ember.isEmpty(this.get('csvRoute'))) {
          console.log('No csvRoute specified');
          return;
        }

        if (typeof this._getQueryParams !== 'function') {
          console.log('You must implemented _getQueryParams');
          return;
        }

        this.set('loadingCSV', true);

        let query = this._getQueryParams(true);

        query.csv = true;
        this.adminRequest.request(this.get('csvRoute'), {
          method: 'GET',
          data: query
        }).then(response => {
          let token = response['csv-token'];

          if (token) {
            this.set('_pollAttempts', 0);
            this.send('pollCSV', token);
          }
        }).catch(() => {
          this.set('loadingCSV', false);
          this.flashMessages.danger('There was an issue generating your file.');
        });
      }

    }
  });

  _exports.default = _default;
});