define("portal/services/environment", ["exports", "@outdoorsyco/ember-shared-data/services/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _environment.default;
    }
  });
});