define("portal/helpers/rental-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rentalImage = rentalImage;
  _exports.default = void 0;

  function rentalImage(params
  /*, hash*/
  ) {
    let image = params[0];
    let size = params[1] || 'default';

    switch (size) {
      case 'tiny':
        return image.replace('/outdoorsy/image/upload', '/outdoorsy/image/upload/a_exif,c_fill,e_improve,h_50,w_50');

      case 'thumbnail':
        return image.replace('/outdoorsy/image/upload', '/outdoorsy/image/upload/a_exif,c_fill,e_improve,h_300,w_300');

      case 'listing':
        return image.replace('/outdoorsy/image/upload', '/outdoorsy/image/upload/a_exif,c_fill,e_improve,h_300,w_450/c_scale,e_overlay,g_south_east,l_outdoorsy-logo,w_100,x_10,y_10');

      default:
        return image.replace('/outdoorsy/image/upload', '/outdoorsy/image/upload/c_fill,e_improve,h_600,w_900/c_scale,e_overlay,g_south_east,l_outdoorsy-logo,w_150,x_10,y_10');
    }
  }

  var _default = Ember.Helper.helper(rentalImage);

  _exports.default = _default;
});