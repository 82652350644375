define("portal/routes/rentals/show/index", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, rental) {
      this._super(...arguments);

      this.fetchRentalTagsTask.perform(controller, rental);
    },

    fetchRentalTagsTask: (0, _emberConcurrency.task)(function* (controller, rental) {
      let tags = yield this.store.findAll('tag');
      let rentalTags = yield rental.load('tags');
      let tag = tags.findBy('slug', 'healthcarehero');
      let supportingHealthcareHeroes = Boolean(rentalTags.findBy('slug', 'healthcarehero'));
      controller.set('healthcareHeroTag', tag);
      controller.set('wasSupportingHealthcareHeroes', supportingHealthcareHeroes);
      controller.set('isSupportingHealthcareHeroes', supportingHealthcareHeroes);
    })
  });

  _exports.default = _default;
});