define("portal/models/payment-gateway", ["exports", "@outdoorsyco/ember-shared-data/models/payment-gateway"], function (_exports, _paymentGateway) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _paymentGateway.default;
    }
  });
});