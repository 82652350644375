define("portal/components/bundle-rule/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rule: null,
    flashMessages: Ember.inject.service(),
    ruleTypeOptions: ['pricing', 'eligibility'],
    actions: {
      saveRule(rule) {
        this.set('loadingMessage', 'Saving...');
        rule.save().then(() => {
          this.get('flashMessages').success('Rule Saved Successfully');
        }).catch(() => {
          this.get('flashMessages').danger('An error has occurred.');
        }).finally(() => {
          this.set('loadingMessage', null);
        });
      },

      deleteRule(rule) {
        if (confirm('Are you sure you want to delete this rule?')) {
          rule.destroyRecord();
        }
      }

    }
  });

  _exports.default = _default;
});