define("portal/helpers/avatar-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.avatarImage = avatarImage;
  _exports.default = void 0;

  function avatarImage(params
  /*, hash*/
  ) {
    let image = params[0];
    let firstName = params[1];
    let lastName = params[2];

    if (image) {
      return image.replace('/outdoorsy/image/upload', '/outdoorsy/image/upload/a_exif,c_fill,g_faces,h_300,w_300');
    }

    let initials = `${(firstName || ' ')[0]}${(lastName || ' ')[0]}`;
    return `https://res.cloudinary.com/outdoorsy/image/upload/a_exif,c_fill,g_faces,h_300,w_300/l_text:Arial_140:${initials},co_rgb:94a596/v1456770611/guest-avatar-blank_bhkpsv.jpg`;
  }

  var _default = Ember.Helper.helper(avatarImage);

  _exports.default = _default;
});