define("portal/controllers/reports/tax-forms", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    queryParams: ['updated_gte', 'updated_lte', 'page', 'contractor_id', 'tin_check_status', 'user_id', 'completed', 'form_type'],
    page: 1,
    perPage: 50,
    totalResultCount: 0,
    data: null,
    formTypes: null,
    tinCheckStatuses: null,
    completedStatus: null,

    init() {
      this._super(...arguments);

      this.data = [];
      this.formTypes = ['w9', '1099K'];
      this.tinCheckStatuses = ['pending', 'success', 'failure', 'mismatch', 'error'];
      this.completedStatus = ['true', 'false'];
      this.updated_gte = (0, _moment.default)().subtract('days', 15).format('YYYY-MM-DD');
      this.updated_lte = (0, _moment.default)().format('YYYY-MM-DD');
    },

    fromDate: Ember.computed('updated_gte', function () {
      let from = this.get('updated_gte');

      if (typeof from === 'string') {
        from = (0, _moment.default)(from, 'YYYY-MM-DD');
      }

      return from;
    }),
    toDate: Ember.computed('updated_lte', function () {
      let to = this.get('updated_lte');

      if (typeof to === 'string') {
        to = (0, _moment.default)(to, 'YYYY-MM-DD');
      }

      return to;
    }),
    totalPages: Ember.computed('totalResultCount', function () {
      let total = this.get('totalResultCount') || 0;
      let perPage = this.get('perPage');
      return Math.ceil(total / perPage);
    }),
    selectedDates: Ember.computed('updated_gte', 'updated_lte', function () {
      return `${this.get('updated_gte')} - ${this.get('updated_lte')}`;
    }),
    isSpecificSearch: Ember.computed('user_id', 'form_id', 'contractor_id', function () {
      let canAdjustDates = !this.user_id && !this.form_id && !this.contractor_id;
      return !canAdjustDates;
    }),
    actions: {
      loadData() {
        this._loadData();
      },

      setCompleted(input) {
        this.set('completed', input);

        this._loadData();
      },

      setTinCheckStatus(input) {
        this.set('tin_check_status', input);

        this._loadData();
      },

      setUserId(input) {
        this.set('user_id', input);

        this._loadData();
      },

      setFormType(type) {
        this.set('form_type', type);

        this._loadData();
      },

      setPage(page) {
        this.set('page', page);

        this._loadData();
      },

      setDates(startDate, endDate) {
        this.set('updated_gte', (0, _moment.default)(startDate).format('YYYY-MM-DD'));
        this.set('updated_lte', (0, _moment.default)(endDate).format('YYYY-MM-DD'));

        this._loadData();
      },

      submit() {
        this._loadData();
      }

    },

    _loadData() {
      let params = this._getQueryParams();

      this.set('loading', true);
      this.get('adminRequest').request('/admin/user-tax-form-report', {
        data: params
      }).then(response => {
        this.set('totalResultCount', Number(response.meta.total));
        this.set('data', response.data.reports);
      }).catch(() => {
        this.get('flashMessages').danger('An error has occurred.');
      }).finally(() => {
        this.set('loading', false);
      });
    },

    _getQueryParams() {
      let limit = this.get('perPage');
      let offset = (this.get('page') - 1) * limit;
      let params = {
        updated_gte: this.updated_gte,
        updated_lte: this.updated_lte,
        contractor_id: this.contractor_id,
        form_id: this.form_id,
        form_type: this.form_type,
        user_id: this.user_id,
        has_user_completed: this.completed,
        tin_check_status: this.tin_check_status,
        offset: offset,
        limit: limit
      };
      return params;
    }

  });

  _exports.default = _default;
});