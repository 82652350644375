define("portal/routes/bookings/show/charges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    cardTokens: Ember.inject.service(),

    async afterModel(booking) {
      await this.cardTokens.setupStripe(booking.countryForPayment);
    },

    async setupController(controller, model) {
      this._super(controller, model);

      let bookingPpi = await Ember.get(model, 'paymentProcessor');
      controller.set('bookingPaymentProcessor', bookingPpi); // load refund and reversal reasons, might be overkill for now

      controller.loadReasons.perform();
      return controller.set('charges', this.store.query('charge', {
        booking_id: model.get('id')
      }));
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        return controller.setProperties({
          chargeCardName: null,
          chargeCardNumber: null,
          chargeCardCVC: null,
          chargeCardExpMonth: null,
          chargeCardExpYear: null,
          chargeCard: null
        });
      }
    }

  });

  _exports.default = _default;
});