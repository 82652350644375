define("portal/models/booking", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/models/booking"], function (_exports, _emberData, _booking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  _booking.default.reopen({
    ownerFeePercentage: attr('number'),
    renterFeePercentage: attr('number'),
    referredRenterPayoutAmount: attr('centsToDollars'),
    referredOwnerPayoutAmount: attr('centsToDollars'),
    referredRenterPaid: attr('boolean'),
    referredOwnerPaid: attr('boolean'),
    fraudulent: attr('boolean'),
    feesLocked: attr('boolean'),
    ownerPayoutAdjustment: attr('centsToDollars'),
    partner: attr(),
    partnerApp: belongsTo('partner-app'),
    external: attr('boolean'),
    cancelReason: attr('string'),
    feeSummary: attr(),
    revenueSummary: attr(),
    payoutActualTransferAmount: attr('centsToDollars'),
    securityDepositClaimedAmount: attr('centsToDollars'),
    securityDepositRefundAmount: attr('centsToDollars'),
    securityDepositPayoutAmount: attr('centsToDollars'),
    originalBookingSource: attr('string'),
    premiumServicesLeadInsuranceCategories: Ember.computed(function () {
      return ['renter_lead_only_fee', 'renter_lead_fee', 'renter_insurance_fee', 'owner_lead_fee', 'owner_lead_only_fee', 'owner_insurance_fee'];
    }),
    premiumServicesWithoutLeadInsuranceFees: Ember.computed('premiumServices.[]', 'premiumServicesLeadInsuranceCategories.[]', function () {
      return this.get('premiumServices').filter(ps => {
        return (this.get('premiumServicesLeadInsuranceCategories') || []).indexOf(ps.get('category')) === -1;
      });
    }),
    renterLeadAndInsuranceServices: Ember.computed('premiumServices.[]', function () {
      return this.get('premiumServices').filter(ps => {
        let category = ps.get('category');
        return ['renter_lead_fee', 'renter_lead_only_fee', 'renter_insurance_fee'].indexOf(category) !== -1;
      });
    }),
    ownerLeadAndInsuranceServices: Ember.computed('premiumServices.[]', function () {
      return this.get('premiumServices').filter(ps => {
        let category = ps.get('category');
        return ['owner_lead_fee', 'owner_lead_only_fee', 'owner_insurance_fee'].indexOf(category) !== -1;
      });
    }),
    isCancelled: Ember.computed('status', function () {
      return ['renter_cancelled', 'owner_cancelled'].includes(this.status);
    })
  });

  var _default = _booking.default;
  _exports.default = _default;
});