define("portal/adapters/ugc-place", ["exports", "@outdoorsyco/ember-shared-data/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'v0',

    pathForType() {
      return `ugc/places`;
    },

    urlForQuery() {
      let host = this.get('host');
      let namespace = this.get('namespace');
      return `${host}/${namespace}/ugc/places`;
    }

  });

  _exports.default = _default;
});