define("portal/controllers/users/show/partners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    userController: Ember.inject.controller('users.show'),
    actions: {
      addPartner() {
        let partner = this.store.createRecord('partner-user', {
          user: this.get('userController.model')
        });
        this.set('currentUserPartner', partner);
      },

      cancelCurrentUserPartner() {
        this.get('currentUserPartner').rollbackAttributes();
        this.set('currentUserPartner', null);
      },

      editUserPartner(up) {
        this.set('currentUserPartner', up);
      },

      deleteUserPartner(up) {
        if (window.confirm(`Are you sure you want to remove ${up.get('partner.name')}?`)) {
          up.destroyRecord().then(() => {
            this.send('reloadPartners');
          });
        }
      },

      reloadList() {
        this.set('currentUserPartner', null);
        this.send('reloadPartners');
      }

    }
  });

  _exports.default = _default;
});