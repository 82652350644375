define("portal/controllers/insurance-plans/index", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    adminRequest: Ember.inject.service(),
    savedPlans: Ember.computed.filterBy('model', 'isNew', false),
    activePlans: Ember.computed.filterBy('savedPlans', 'hidden', false),
    hiddenPlans: Ember.computed.filterBy('savedPlans', 'hidden', true),
    dealerOnly: false,
    showHidden: false,
    watchFilters: Ember.observer('dealerOnly', 'showHidden', function () {
      Ember.run.debounce(this, 'send', 'reloadPlans', 500);
    }),
    copyInsurancePlan: (0, _emberConcurrency.task)(function* (plan) {
      yield this.adminRequest.post(`insurance-plans/${plan.id}/duplicate`);
      this.send('reloadPlans');
      this.get('flashMessages').success('Insurance Plan copied successfully');
    }),
    deleteInsurancePlan: (0, _emberConcurrency.task)(function* (plan) {
      let confirmed = confirm('Are you sure you want to delete this plan?');

      if (confirmed) {
        yield plan.destroyRecord();
        this.get('flashMessages').success('Insurance Plan deleted');
      }
    }),
    actions: {
      newInsurancePlan() {
        let plan = this.store.createRecord('insurancePlan');
        this.set('editInsurancePlan', plan);
      },

      editInsurancePlan(plan) {
        this.set('editInsurancePlan', plan);
      },

      planSaved()
      /*plan*/
      {
        this.set('editInsurancePlan', null);
        this.send('reloadPlans');
      },

      closeModal() {
        if (!this.get('editInsurancePlan.isNew') && this.get('editInsurancePlan.hasDirtyAttributes')) {
          this.get('editInsurancePlan').rollbackAttributes();
        }

        this.set('editInsurancePlan', null);
      },

      reloadPlans: function reloadPlans() {
        this.store.query('insurance-plan', {
          unregistered_only: false,
          SkipHiddenCheck: this.get('showHidden') ? 'true' : 'false',
          SkipDealerCheck: this.get('dealerOnly') ? 'false' : 'true',
          dealer_only: this.get('dealerOnly'),
          SkipLocalityCheck: true
        }).then(response => {
          this.set('model', response);
        });
      }
    }
  });

  _exports.default = _default;
});