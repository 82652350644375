define("portal/serializers/special-hour", ["exports", "portal/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      if (hash.pickup) {
        hash.pickup_unavailable = hash.pickup.unavailable;
        hash.pickup_start_time = hash.pickup.start_time;
        hash.pickup_end_time = hash.pickup.end_time;
      }

      if (hash.dropoff) {
        hash.dropoff_unavailable = hash.dropoff.unavailable;
        hash.dropoff_start_time = hash.dropoff.start_time;
        hash.dropoff_end_time = hash.dropoff.end_time;
      }

      return this._super(typeClass, hash);
    },

    serialize(record, options) {
      let data = this._super(record, options);

      data.pickup = {
        start_time: data.pickup_start_time,
        end_time: data.pickup_end_time,
        unavailable: data.pickup_unavailable
      };
      data.dropoff = {
        start_time: data.dropoff_start_time,
        end_time: data.dropoff_end_time,
        unavailable: data.dropoff_unavailable
      };
      delete data.pickup_start_time;
      delete data.pickup_end_time;
      delete data.pickup_unavailable;
      delete data.dropoff_start_time;
      delete data.dropoff_end_time;
      delete data.dropoff_unavailable;
      return data;
    }

  });

  _exports.default = _default;
});