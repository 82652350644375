define("portal/components/copy-to-clipboard-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hzt7ULRh",
    "block": "{\"symbols\":[\"&default\",\"@customContent\"],\"statements\":[[7,\"span\",false],[12,\"class\",[29,[[28,\"local-class\",[\"wrapper\"],[[\"from\"],[\"portal/components/copy-to-clipboard-button/styles\"]]]]]],[12,\"title\",[23,0,[\"title\"]]],[3,\"on\",[\"click\",[23,0,[\"copyData\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"showSuccess\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[10,\"src\",\"/img/show-success.svg\"],[10,\"alt\",\"Successfully copied\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"useWhiteVariant\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[11,\"class\",[29,[[28,\"local-class\",[\"white-copy\"],[[\"from\"],[\"portal/components/copy-to-clipboard-button/styles\"]]]]]],[10,\"src\",\"/img/copy.svg\"],[10,\"alt\",\"Copy to clipboard\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"img\",true],[10,\"src\",\"/img/copy.svg\"],[10,\"alt\",\"Copy to clipboard\"],[8],[9],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/components/copy-to-clipboard-button/template.hbs"
    }
  });

  _exports.default = _default;
});