define("portal/routes/rentals/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),

    model(params) {
      return this.store.findRecord('rental', params.rental_id);
    }

  });

  _exports.default = _default;
});