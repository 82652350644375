define("portal/controllers/bookings/show", ["exports", "ember-concurrency", "portal/helpers/belongs-to-id"], function (_exports, _emberConcurrency, _belongsToId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    adminRequest: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    environment: Ember.inject.service(),
    hidePeopleInfo: false,
    numberOfNewNotes: 0,
    loadUserNotes: (0, _emberConcurrency.task)(function* (booking) {
      try {
        let bookingId = Ember.get(booking, 'id');
        let fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 1);
        let url = `admin/notes?internal=true&booking_id=${bookingId}&from=${fromDate.toISOString()}`;
        let data = yield this.adminRequest.request(url);
        this.set('numberOfNewNotes', data.length);
      } catch (e) {
        this.set('numberOfNewNotes', 0);
      }
    }),
    charges: Ember.computed('model', function () {
      return this.store.peekAll('charge');
    }),
    isProduction: Ember.computed(function () {
      return window.location.host === 'portal.outdoorsy.com';
    }),
    noExistingPayout: Ember.computed('model', 'charges.[]', function () {
      return this.get('charges').filterBy('booking.id', Ember.get(this, 'model.id')).filterBy('type', 'payout').length === 0;
    }),
    campgroundListingPage: Ember.computed('campground', function () {
      let campground = this.get('campground');

      if (campground) {
        return `${this.environment.getOriginFor('search')}${campground.slug}`;
      }

      return '';
    }),
    campgroundAddress: Ember.computed('campground', function () {
      let campground = this.get('campground');

      if (campground) {
        return `${campground.address.street} ${campground.address.city}, ${campground.address.state} ${campground.address.postal_code}`;
      }

      return '';
    }),

    _responseMessage(response, msg) {
      let error = msg;

      if (response && response.errors && response.errors.length) {
        let title = response.errors[0].title;
        let body = response.errors[0].detail;
        error = `${title} - ${body}`;
      } else if (response && response.payload && response.payload.error) {
        error = response.payload.error;
      }

      return error;
    },

    loadTSQueueItems: (0, _emberConcurrency.task)(function* (booking) {
      let items = yield this.store.query('ts-queue-item', {
        status: 'unchecked,in_review,rejected',
        booking_id: Ember.get(booking, 'id'),
        owner_id: (0, _belongsToId.belongsToId)([booking, 'owner']),
        renter_id: (0, _belongsToId.belongsToId)([booking, 'renter'])
      });
      Ember.set(this, 'tsQueueItems', items);
    }),
    loadBookingDrivers: (0, _emberConcurrency.task)(function* (booking) {
      try {
        let drivers = yield this.apiRequest.request(`bookings/${Ember.get(booking, 'id')}/drivers`);
        this.set('bookingDrivers', drivers.length);
        this.set('verifiedDrivers', drivers.filter(driver => driver.role === 'driver' && driver.verified).length);
      } catch (e) {
        this.set('bookingDrivers', 0);
      }
    }),
    loadCampground: (0, _emberConcurrency.task)(function* (booking) {
      try {
        let rentalSummary = Ember.get(booking, 'rentalSummary');
        let {
          campground_id
        } = rentalSummary.campsite_summary || {};
        let campground = yield this.get('adminRequest').request(`admin/campgrounds/${campground_id}`);
        this.set('campground', campground);
      } catch (e) {
        this.set('campground', null);
      }
    }),
    actions: {
      togglePeopleNav() {
        this.toggleProperty('hidePeopleInfo');
      },

      recalculateBooking() {
        this.set('loadingRecalculate', true);
        this.get('adminRequest').post(`bookings/${this.get('model.id')}/recalculate`).then(() => {
          this.model.reload();
          this.set('loadingRecalculate', false);
        }).catch(() => {
          alert('Could not recalculate');
          this.set('loadingRecalculate', false);
        });
      },

      completeFraudReview() {
        this.set('loading', true);
        this.get('adminRequest').post(`users/${this.get('model.owner.id')}/approve_payouts`).then(() => {
          this.model.get('owner').then(owner => owner.reload());
        }).catch(response => {
          let error = this._responseMessage(response, 'Fraud review could not be completed');

          alert(error);
        }).finally(() => {
          this.set('loading', false);
        });
      },

      sendPayoutOwner() {
        let confirmed = confirm('Are you sure you want to send the owner the total payout?');

        if (confirmed) {
          this.set('loading', true);
          this.get('adminRequest').post(`bookings/${this.get('model.id')}/payout`).then(() => {
            this.get('model.owner').then(owner => owner.reload());
          }).catch(response => {
            let error = this._responseMessage(response, 'Payout could not be completed');

            alert(error);
          }).finally(() => {
            this.set('loading', false);
          });
        }
      },

      closeTSModal() {
        this.set('reportToTrustAndSafety', null);
      },

      successTSReport() {
        this.loadTSQueueItems.perform(this.model);
      }

    }
  });

  _exports.default = _default;
});