define("portal/adapters/premium-service", ["exports", "portal/config/environment", "@outdoorsyco/ember-shared-data/adapters/premium-service"], function (_exports, _environment, _premiumService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _premiumService.default.extend({
    namespace: 'v0',
    host: _environment.default.APP.apiHost,

    // ember-simple-auth
    // NOTE: Have to override here since PremiumService extends from @outdoorsyco/ember-shared-data/premium-service
    authorize(xhr) {
      let accessToken = this.get('session.authKey');

      if (Ember.isPresent(accessToken)) {
        xhr.setRequestHeader('Admin', `Token=${accessToken.replace('guest:', '')}`);
      }
    }

  });

  _exports.default = _default;
});