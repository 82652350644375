define("portal/routes/users/show/drivers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    adminRequest: Ember.inject.service(),

    afterModel(model) {
      let controller = this.controllerFor('users/show/drivers');
      this.get('adminRequest').request(`admin/users/${model.get('id')}/inbound_log`).then(results => {
        controller.set('inboundLogs', results);
      }).catch(() => {// gracefully handle the error
      });
    }

  });

  _exports.default = _default;
});