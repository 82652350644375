define("portal/services/verify-id", ["exports", "@outdoorsyco/ember-shared-data/services/verify-id"], function (_exports, _verifyId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _verifyId.default;
    }
  });
});