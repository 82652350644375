define("portal/adapters/activity", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType(modelName) {
      const path = Ember.String.decamelize(modelName);
      return `users/:user_id/${path}`;
    },

    buildURL(type, id, snapshot, requestType, query) {
      let url = this._super(type, id, snapshot);

      if (snapshot) {
        url = url.replace(':user_id', snapshot.belongsTo('receiver', {
          id: true
        }));
      } else if (query) {
        url = url.replace(':user_id', query.user_id);
      }

      return url;
    }

  });

  _exports.default = _default;
});