define("portal/styles/pages/trust-safety-queue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "trust-safety-queue": "trust-safety-queue"
  };
  _exports.default = _default;
});