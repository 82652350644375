define("portal/helpers/item-index", ["exports", "@outdoorsyco/ember-shared-data/helpers/item-index"], function (_exports, _itemIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _itemIndex.default;
    }
  });
  Object.defineProperty(_exports, "itemIndex", {
    enumerable: true,
    get: function get() {
      return _itemIndex.itemIndex;
    }
  });
});