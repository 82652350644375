define("portal/templates/driver-checks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZVam3U+j",
    "block": "{\"symbols\":[\"driver\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"table table-striped\"],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Booking\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Dates\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Driver Info\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"stalled-driver\",null,[[\"driver\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"well\"],[8],[0,\"\\n        Nothing to see here!\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/driver-checks.hbs"
    }
  });

  _exports.default = _default;
});