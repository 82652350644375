define("portal/routes/reports/tax-forms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel() {
      this._super(...arguments); // eslint-disable-next-line ember/no-controller-access-in-routes


      let controller = this.controllerFor('reports/tax-forms'); // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions

      Ember.run.scheduleOnce('render', controller, function () {
        this.send('loadData');
      });
    }

  });

  _exports.default = _default;
});