define("portal/adapters/user", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    // Employees get deleted via a different endpoint.
    // There is no support for deleting anything but Employees
    // So....here we are
    buildURL(type, id, snapshot, requestType) {
      let url = this._super(...arguments);

      if (requestType === 'deleteRecord') {
        url = url.replace('users', 'users/dealers');
      }

      return url;
    }

  });

  _exports.default = _default;
});