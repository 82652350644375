define("portal/transforms/meta", ["exports", "@outdoorsyco/ember-shared-data/transforms/meta"], function (_exports, _meta) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _meta.default;
    }
  });
});