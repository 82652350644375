define("portal/models/integration", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/models/integration"], function (_exports, _emberData, _integration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  // Reopen for admin specific fields.
  _integration.default.reopen({
    serviceEndpoint: attr('string'),
    authToken: attr('string'),
    serviceData: attr('', {
      defaultValue() {
        return {};
      }

    }),
    supportPhone: attr('string'),
    eventTriggers: attr('array', {
      defaultValue() {
        return [];
      }

    }),
    chargeTo: attr('string', {
      defaultValue() {
        return 'renter';
      }

    }),
    defaultEnabled: attr('boolean'),
    dealerDefaultEnabled: attr('boolean'),
    lockOnBookingStatus: attr('string'),
    allowDealerToRequire: attr('boolean'),
    bookingDisabled: attr('boolean'),
    isTaxable: attr('boolean'),
    required: attr('boolean'),
    dealerSplitType: attr('string'),
    dealerFlatSplit: attr('centsToDollars'),
    dealerPercentSplit: attr('number'),

    setJsonField(fieldName, value) {
      let hash = {};

      try {
        hash = JSON.parse(value);
      } catch (e) {
        this.set(`${fieldName}Error`, e);
        return value;
      }

      this.set(`${fieldName}Error`, null);
      this.set(fieldName, hash);
      return value;
    },

    serviceDataString: Ember.computed('config', {
      get() {
        return JSON.stringify(this.get('serviceData'), null, 2);
      },

      set(key, value) {
        return this.setJsonField('serviceData', value);
      }

    }),
    requiredFieldString: Ember.computed('requiredFields', {
      get() {
        return JSON.stringify(this.get('requiredFields'), null, 2);
      },

      set(key, value) {
        return this.setJsonField('requiredFields', value);
      }

    }),
    countriesString: Ember.computed('countries.[]', {
      get() {
        return this.get('countries').join(',');
      },

      set(key, value) {
        this.set('countries', value.split(',').map(x => x.trim()));
        return value;
      }

    }),
    regionsString: Ember.computed('regions.[]', {
      get() {
        return this.get('regions').join(',');
      },

      set(key, value) {
        this.set('regions', value.split(',').map(x => x.trim()));
        return value;
      }

    }),
    renterRegionExclusionsString: Ember.computed('renterRegionExclusions.[]', {
      get() {
        return this.get('renterRegionExclusions').join(',');
      },

      set(key, value) {
        this.set('renterRegionExclusions', value.split(',').map(x => x.trim()));
        return value;
      }

    })
  });

  var _default = _integration.default;
  _exports.default = _default;
});