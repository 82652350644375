define("portal/models/search/image", ["exports", "@outdoorsyco/ember-shared-data/models/search/image"], function (_exports, _image) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _image.default;
    }
  });
});