define("portal/components/booking-info/component", ["exports", "ember-concurrency", "ember-window-mock", "dayjs", "portal/config/environment"], function (_exports, _emberConcurrency, _emberWindowMock, _dayjs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    cancelForgiven: false,
    editingBank: false,
    hasLocation: Ember.computed(function () {
      let booking = Ember.get(this, 'booking'); // booking might be a promise which doesn't directly have belongsTo

      if (booking && booking.belongsTo) {
        return booking.belongsTo('location').id() !== '0';
      }

      return false;
    }),
    originLink: Ember.computed(function () {
      return _environment.default.APP.outdoorsyHost || 'https://www.outdoorsy.com';
    }),

    init() {
      this._super(...arguments);

      if (Ember.get(this, 'booking.status') === 'owner_cancelled') {
        Ember.get(this, 'checkForgivenCancellations').perform();
      }
    },

    rsaIdDate: Ember.computed('booking.{from,includeCoachnet}', function () {
      if (Ember.get(this, 'booking.includeCoachnet') === false) {
        return null;
      }

      let from = Ember.get(this, 'booking.from');
      return from ? (0, _dayjs.default)(from).subtract(4, 'day').format('YYYY-MM-DD') : null;
    }),
    canEditBank: Ember.computed('banks.[]', 'booking.paymentProcessor.archived', function () {
      if (this.get('session.user.adminLevel') <= 1) {
        return false;
      }

      let pp = Ember.get(this, 'booking.paymentProcessor');

      if (!pp) {
        return true;
      }

      return Ember.get(pp, 'archived') || Ember.get(this, 'banks.length') > 1 || Ember.get(pp, 'managedAccountId') === '';
    }),
    bookingPaymentProcessorIdStr: Ember.computed('booking.paymentProcessor.id', function () {
      return String(Ember.get(this, 'booking.paymentProcessor.id'));
    }),
    bankList: Ember.computed(function () {
      if (this.get('session.user.adminLevel') <= 1) {
        return [];
      }

      return this.store.query('paymentProcessor', {
        owner_id: Ember.get(this, 'booking.owner.id'),
        archived: true,
        country: Ember.get(this, 'booking.paymentProcessor.processorCountry')
      });
    }),
    rentalInfo: Ember.computed(function () {
      return '#' + Ember.get(this, 'booking.rental.id') + ' - ' + Ember.get(this, 'booking.rental.name');
    }),
    bookingSourceLabel: Ember.computed(function () {
      let source = Ember.get(this, 'booking.originalBookingSource');

      if (source === 'wheelbase') {
        return 'DOU';
      }

      return source;
    }),
    updateBookingBank: (0, _emberConcurrency.task)(function* (bank) {
      if (!_emberWindowMock.default.confirm('Are you sure you want to update the connected bank?')) {
        return;
      }

      let url = `admin/bookings/${Ember.get(this, 'booking.id')}/bank`;

      try {
        yield this.adminRequest.post(url, {
          data: {
            bank_id: Number(bank.id)
          }
        });
        Ember.set(this, 'editingBank', false);
        yield this.booking.reload();
      } catch (error) {
        let msg = 'An error has occurred.';

        if (error && error.payload) {
          msg = error.payload.error;
        }

        Ember.get(this, 'flashMessages').danger(msg);
      }
    }),
    canEditPartnerApp: Ember.computed('booking.status', 'partnerAppList.length', function () {
      if (this.get('session.user.adminLevel') <= 2) {
        return false;
      }

      if (['negotiating', 'draft', 'approved', 'imminent'].indexOf(this.get('booking.status')) === -1) {
        return false;
      } // restrict to those created by owners for now


      if (this.booking && this.booking.belongsTo) {
        if (this.booking.belongsTo('owner').id() !== this.booking.belongsTo('createdBy').id()) {
          return false;
        }
      } else {
        return false;
      }

      let partnerId = Ember.get(this, 'booking.partner.id');
      return partnerId && Ember.get(this, 'partnerAppList.length') > 1;
    }),
    // used for select find-by matching
    partnerAppIdString: Ember.computed('booking.partnerApp.id', function () {
      return String(Ember.get(this, 'booking.partnerApp.id'));
    }),
    partnerAppList: Ember.computed('booking.partner.id', function () {
      let partnerId = Ember.get(this, 'booking.partner.id');

      if (!partnerId) {
        return [];
      }

      return this.store.query('partner-app', {
        partner_id: partnerId
      });
    }),
    updateBookingPartnerApp: (0, _emberConcurrency.task)(function* (app) {
      if (!_emberWindowMock.default.confirm('Are you sure you want to update the partner app? This will recalculate all fee services.')) {
        return;
      }

      let url = `admin/bookings/${Ember.get(this, 'booking.id')}`;

      try {
        yield this.adminRequest.patch(url, {
          data: {
            partner_app_id: Number(app.id)
          }
        });
        Ember.set(this, 'editingPartnerApp', false);
        yield this.booking.reload();
      } catch (error) {
        let msg = 'An error has occurred.';

        if (error && error.payload) {
          msg = error.payload.error;
        }

        Ember.get(this, 'flashMessages').danger(msg);
      }
    }),
    forgiveOwner: (0, _emberConcurrency.task)(function* () {
      if (!_emberWindowMock.default.confirm('Are you sure?')) {
        return;
      }

      let url = `admin/bookings/${Ember.get(this, 'booking.id')}/forgive-cancellation`;
      yield this.adminRequest.post(url).catch(error => {
        let msg = 'An error has occurred.';

        if (error && error.payload) {
          msg = error.payload.error;
        }

        Ember.get(this, 'flashMessages').danger(msg);
      }).finally(() => {
        Ember.set(this, 'cancelForgiven', true);
      });
    }),
    checkForgivenCancellations: (0, _emberConcurrency.task)(function* () {
      let activities = yield this.store.query('activity', {
        booking_id: Ember.get(this, 'booking.id'),
        limit: 1,
        user_id: Ember.get(this, 'booking.owner.id'),
        type: 'forgive_cancellation'
      });
      let cancelForgiven = Ember.isPresent(activities);
      Ember.set(this, 'cancelForgiven', cancelForgiven);
    })
  });

  _exports.default = _default;
});