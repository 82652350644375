define("portal/controllers/rentals/show/blackouts", ["exports", "ember-concurrency", "dayjs", "dayjs/plugin/utc"], function (_exports, _emberConcurrency, _dayjs, _utc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  _dayjs.default.extend(_utc.default);

  var _default = Ember.Controller.extend({
    queryParams: ['page'],
    page: 1,
    perPage: 20,
    blackouts: [],
    total: 0,
    sortBy: 'from',
    sortDir: 'asc',
    flashMessages: Ember.inject.service(),
    adminRequest: Ember.inject.service(),
    blackout: null,
    reason: '',
    loadBlackouts: (0, _emberConcurrency.task)(function* () {
      let orderBy = this.get('sortBy');

      if (this.get('sortDir') === 'desc') {
        orderBy = `-${orderBy}`;
      }

      let params = {
        owner_id: this.get('model.owner.id'),
        rental_id: this.get('model.id'),
        limit: this.perPage,
        offset: (this.get('page') - 1) * this.perPage,
        order_by: orderBy
      };
      yield this.store.query('blackout', params).then(blackouts => {
        this.set('blackouts', blackouts.toArray());
        this.set('total', blackouts.get('meta.total'));
      }).catch(err => {
        this.flashMessages.danger(`We failed to load permanent blocks ${err}`);
      });
    }),
    unblockBlackout: (0, _emberConcurrency.task)(function* () {
      yield this.adminRequest.patch(`admin/blackouts/${this.blackout.id}/unblock`, {
        data: {
          reason: this.reason
        }
      }).then(() => {
        this.setProperties({
          blackout: null,
          reason: ''
        });
        this.loadBlackouts.perform();
      }).catch(err => {
        this.flashMessages.danger(`Failed to update permanent block ${err}`);
      });
    }),
    clearUnavailableDates: (0, _emberConcurrency.task)(function* (blackout) {
      if (!confirm('Are you sure you want to delete this block?')) {
        return;
      }

      yield this.adminRequest.post(`/rentals/${blackout.rentalId}/availability`, {
        data: {
          from: (0, _dayjs.default)(blackout.from).utc().format('YYYY-MM-DD'),
          to: (0, _dayjs.default)(blackout.to).utc().format('YYYY-MM-DD'),
          type: 'available',
          reason: 'Rental block cleared by admin'
        }
      }).then(() => {
        this.loadBlackouts.perform();
      }).catch(err => {
        this.flashMessages.danger(`Failed to delete block ${err}`);
      });
    }),
    actions: {
      setParam(param, value) {
        this.set('page', 1);
        this.set(param, value);
        this.loadBlackouts.perform();
      },

      setPage(page) {
        this.set('page', page);
        this.loadBlackouts.perform();
      },

      setSort(fieldName, direction) {
        this.setProperties({
          sortBy: fieldName,
          sortDir: direction,
          page: 1
        });
        this.loadBlackouts.perform();
      },

      clearFilters() {
        this.setProperties({
          page: 1
        });
        this.loadBlackouts.perform();
      },

      closeModal() {
        Ember.set(this.blackout, 'permanent', true);
        this.setProperties({
          blackout: null,
          reason: ''
        });
      }

    }
  });

  _exports.default = _default;
});