define("portal/components/tags-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2oW5P9Ap",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n    \"],[7,\"table\",true],[10,\"class\",\"table table-striped\"],[8],[0,\"\\n      \"],[7,\"thead\",true],[8],[0,\"\\n        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"rental-tags-id\"],null],false],[9],[0,\"\\n          \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"rental-tags-name\"],null],false],[9],[0,\"\\n          \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"rental-tags-slug\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"rentalTags\"]]],null,{\"statements\":[[0,\"          \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"td\",true],[8],[1,[23,1,[\"id\"]],false],[9],[0,\"\\n            \"],[7,\"td\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n            \"],[7,\"td\",true],[8],[1,[23,1,[\"slug\"]],false],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/components/tags-container/template.hbs"
    }
  });

  _exports.default = _default;
});