define("portal/models/affiliate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend({
    user: belongsTo('user', {
      async: true
    }),
    affiliateNetwork: belongsTo('affiliateNetwork', {
      async: true
    }),
    keys: attr()
  });

  _exports.default = _default;
});