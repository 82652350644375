define("portal/components/stalled-driver/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NbaUEF+a",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[8],[0,\"\\n  \"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"bookings.show\",[24,[\"driver\",\"booking_id\"]]]],{\"statements\":[[1,[24,[\"driver\",\"booking_id\"]],false]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n  \"],[1,[24,[\"driver\",\"booking_from\"]],false],[0,\" - \"],[1,[24,[\"driver\",\"booking_to\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n  #\"],[1,[24,[\"driver\",\"id\"]],false],[0,\" \"],[1,[24,[\"driver\",\"first_name\"]],false],[0,\" \"],[1,[24,[\"driver\",\"last_name\"]],false],[0,\"\\n  \"],[7,\"br\",true],[8],[9],[0,\"\\n  License State: \"],[1,[24,[\"driver\",\"license_state\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n  \"],[1,[28,\"loading-button\",null,[[\"tagName\",\"buttonClass\",\"buttonText\",\"loading\",\"action\"],[\"span\",\"btn btn-primary\",\"Force Checkr\",[24,[\"forceCheckr\",\"isRunning\"]],[28,\"perform\",[[24,[\"forceCheckr\"]],[24,[\"driver\"]]],null]]]],false],[0,\"\\n  \"],[1,[28,\"loading-button\",null,[[\"tagName\",\"buttonClass\",\"buttonText\",\"loading\",\"action\"],[\"span\",\"btn btn-success\",\"Approve Driver\",[24,[\"approveDriver\",\"isRunning\"]],[28,\"perform\",[[24,[\"approveDriver\"]],[24,[\"driver\"]]],null]]]],false],[0,\"\\n  \"],[1,[28,\"loading-button\",null,[[\"tagName\",\"buttonClass\",\"buttonText\",\"loading\",\"action\"],[\"span\",\"btn btn-danger\",\"Reject Driver\",[24,[\"rejectDriver\",\"isRunning\"]],[28,\"perform\",[[24,[\"rejectDriver\"]],[24,[\"driver\"]]],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/components/stalled-driver/template.hbs"
    }
  });

  _exports.default = _default;
});