define("portal/adapters/custom-adapter", ["exports", "@outdoorsyco/ember-shared-data/adapters/application", "portal/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    session: Ember.inject.service(),
    host: _environment.default.APP.javaHost,

    // ember-simple-auth
    authorize(xhr) {
      let accessToken = this.get('session.authKey');

      if (Ember.isPresent(accessToken)) {
        xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
      }
    },

    handleResponse(status, headers, payload, requestData) {
      payload = payload.data ? payload.data : payload;
      return this._super(status, headers, payload, requestData);
    }

  });

  _exports.default = _default;
});