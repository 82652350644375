define("portal/controllers/rentals/show/analytics", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    statData: null,
    inquisitorRequest: Ember.inject.service(),
    grid: {
      y: {
        lines: [{
          value: 0,
          text: 'Not shown'
        }, {
          value: 25,
          text: 'Page 1'
        }, {
          value: 50,
          text: 'Page 2'
        }, {
          value: 75,
          text: 'Page 3'
        }, {
          value: 100,
          text: 'Page 4'
        }]
      }
    },
    _loadStats: (0, _emberConcurrency.task)(function* (rentalId) {
      let data = yield this.get('inquisitorRequest').request(`metrics/${rentalId}`);
      let controlData = [];
      data.forEach(day => {
        if (day.variant === 'default') {
          day.date = (0, _moment.default)(day.date, 'YYYY-MM-DD');
          controlData.push(day);
        }
      });
      controlData = controlData.sort((a, b) => {
        return a.date - b.date;
      });
      let xAxis = ['x'];
      let impressions = ['Impressions'];
      let views = ['Views'];
      let requests = ['Requests'];
      let pageRank = ['Origin Rank'];
      let viewedRank = ['Viewed Rank'];
      controlData.forEach(day => {
        xAxis.push(day.date);
        impressions.push(day.impressions);
        views.push(day.views);
        requests.push(day.requests);
        pageRank.push(day.rank_at_origin);
        viewedRank.push(day.average_page_rank);
      });
      this.set('data', {
        x: 'x',
        columns: [xAxis, impressions, views, requests],
        type: 'line',
        types: {
          Requests: 'bar'
        },
        axes: {
          Requests: 'y2'
        },
        colors: {
          Impressions: '#FF8C00',
          Requests: '#006400',
          Views: '#4682B4'
        },
        regions: {
          Impressions: [{
            style: 'dashed'
          }]
        }
      });
      this.set('data2', {
        x: 'x',
        columns: [xAxis, pageRank, viewedRank],
        type: 'line',
        colors: {
          'Origin Rank': '#FF8C00',
          'Viewed Rank': '#006400'
        }
      });
      this.set('axis', {
        x: {
          type: 'timeseries',
          tick: {
            format: '%Y-%m-%d'
          }
        }
      });
      this.set('axis2', {
        x: {
          type: 'timeseries',
          tick: {
            format: '%Y-%m-%d'
          }
        },
        y: {
          range: {
            min: -1,
            max: 150
          }
        }
      });
      this.set('statData', data);
    })
  });

  _exports.default = _default;
});