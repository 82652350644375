define("portal/switchback/config", [], function () {
  "use strict";

  /* eslint-env node */

  /**
   * See @outdoorsyco/ember-switchback/lib/default-config.js for
   * the default config values.
   */
  module.exports = {};
});