define("portal/services/lippert-rentals-service", ["exports", "@outdoorsyco/ember-shared-data/services/lippert-rentals-service"], function (_exports, _lippertRentalsService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _lippertRentalsService.default;
    }
  });
});