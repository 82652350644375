define("portal/controllers/rental-review", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const perPage = 50;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'rental_id'],
    page: 1,
    totalPages: Ember.computed('model.length', function () {
      if ((this.get('model.length') || 0) === perPage) {
        return this.page + 1;
      }

      return this.page;
    }),
    adminRequest: Ember.inject.service(),
    rentalsForReview: Ember.computed.alias('model'),
    flashMessages: Ember.inject.service(),
    rejectionConfirmationModalVisible: false,
    rental_id: null,
    loadRentalsForReview: (0, _emberConcurrency.task)(function* () {
      let options = {
        limit: perPage,
        offset: (this.page - 1) * perPage,
        rental_id: this.rental_id
      };
      let response = yield this.get('adminRequest').request('rentals/needs-review', {
        data: options
      });
      this.set('model', response);
    }),
    actions: {
      setPage(page) {
        this.set('page', page);
        this.loadRentalsForReview.perform();
      },

      approve(rental) {
        this.get('adminRequest').request(`rentals/${rental.id}/review-status`, {
          method: 'PATCH',
          data: JSON.stringify({
            status: 'approved',
            reason: rental.reason
          })
        }).then(() => {
          this.get('model').removeObject(rental);
        }).catch(() => {
          this.get('flashMessages').danger('Error approving rental.');
        });
      },

      showRejectionConfirmationModal(rental) {
        this.setProperties({
          rentalToReject: rental,
          rejectionConfirmationModalVisible: true
        });
      },

      hideRejectionConfirmationModal() {
        this.setProperties({
          rentalToReject: undefined,
          rejectionConfirmationModalVisible: false,
          showErrors: false
        });
      },

      reject(rental) {
        if (!rental.reason) {
          this.set('showErrors', true);
          return;
        }

        this.get('adminRequest').request(`rentals/${rental.id}/review-status`, {
          method: 'PATCH',
          data: JSON.stringify({
            status: 'rejected',
            reason: rental.reason
          })
        }).then(() => {
          this.get('model').removeObject(rental);
          this.send('hideRejectionConfirmationModal');
        }).catch(() => {
          this.get('flashMessages').danger('Error rejecting rental.');
        });
      }

    }
  });

  _exports.default = _default;
});