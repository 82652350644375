define("portal/serializers/ugc-review", ["exports", "ember-data", "portal/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      tags: {
        deserialize: 'records',
        serialize: true
      }
    },
    keyForRelationship: function keyForRelationship(key) {
      return key;
    },
    serialize: function serialize(snapshot) {
      return {
        content: snapshot.attr('content'),

        /*rating: snapshot.attr('rating'),*/
        review_id: snapshot.attr('reviewId'),
        ugc_place_id: Number(snapshot.belongsTo('place', {
          id: true
        })),
        ugc_category_id: Number(snapshot.belongsTo('category', {
          id: true
        })),
        ugc_sub_category_id: Number(snapshot.belongsTo('subCategory', {
          id: true
        })),
        ugc_tags: (snapshot.hasMany('tags', {
          ids: true
        }) || []).map(id => Number(id)),
        owner_id: Number(snapshot.belongsTo('owner', {
          id: true
        }))
      };
    },

    normalize(model, hash) {
      hash.category = hash.ugc_category_id;
      hash.place = hash.ugc_place_id;
      hash.subCategory = hash.ugc_sub_category_id;
      hash.tags = Ember.makeArray(hash.ugc_tags);
      hash.owner = hash.owner_id;
      delete hash.ugc_category_id;
      delete hash.ugc_sub_category_id;
      delete hash.ugc_place_id;
      delete hash.ugc_tags;
      delete hash.owner_id;
      return this._super(model, hash);
    }

  });

  _exports.default = _default;
});