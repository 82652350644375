define("portal/models/insurance-plan", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    label: attr('string'),
    comprehensiveCoverage: attr('boolean', {
      defaultValue: false
    }),
    comprehensiveMaximum: attr('string'),
    ownerComprehensiveDeductible: attr('string'),
    renterComprehensiveDeductible: attr('string'),
    liabilityCoverage: attr('boolean', {
      defaultValue: false
    }),
    liabilityMaximum: attr('string'),
    ownerLiabilityDeductible: attr('string'),
    renterLiabilityDeductible: attr('string'),
    requiresDriverVerification: attr('boolean', {
      defaultValue: true
    }),
    renterFeePercentage: attr('number'),
    // float64
    ownerFeePercentage: attr('number'),
    // float64
    country: attr('string'),
    locality: attr('string'),
    // admin only
    tiered: attr('boolean', {
      defaultValue: false
    }),
    minimumDailyFee: attr('centsToDollars'),
    // uint64
    minimumYear: attr('number'),
    // int
    approvedVehicleTypes: attr('array', {
      defaultValue: []
    }),
    // utils.DBStringSlice
    dealerOnly: attr('boolean', {
      defaultValue: false
    }),
    hidden: attr('boolean', {
      defaultValue: false
    }),
    allowUnregistered: attr('boolean', {
      defaultValue: false
    }),
    compPolicyURL: attr('string'),
    liabilityPolicyURL: attr('string'),
    bundleRequired: attr('boolean', {
      defaultValue: false
    }),
    hasOverridePlan: attr('boolean', {
      defaultValue: false
    }),
    insurancePlanOverride: belongsTo('insurance-plan-override', {
      async: true
    }),
    created: attr('date'),
    updated: attr('date'),
    rfp: Ember.computed('renterFeePercentage', 'insurancePlanOverride.renterFeePercentage', function () {
      if (this.get('insurancePlanOverride.renterFeePercentage')) {
        return this.get('insurancePlanOverride.renterFeePercentage');
      }

      return this.get('renterFeePercentage');
    }),
    ofp: Ember.computed('ownerFeePercentage', 'insurancePlanOverride.ownerFeePercentage', function () {
      if (this.get('insurancePlanOverride.ownerFeePercentage')) {
        return this.get('insurancePlanOverride.ownerFeePercentage');
      }

      return this.get('ownerFeePercentage');
    })
  });

  _exports.default = _default;
});