define("portal/routes/bookings/show/insurance-claim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class InsuranceClaimRoute extends Ember.Route {
    async model() {
      const booking = super.model(...arguments);
      return booking;
    } // This was originally in an afterModel hook and it did not behave properly


    setupController(controller, model) {
      super.setupController(...arguments);
      controller.loadClaim.perform(model);
    }

  }

  _exports.default = InsuranceClaimRoute;
});