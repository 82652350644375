define("portal/adapters/rule", ["exports", "ember-data", "portal/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;

  var _default = JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.APP.pricingHost,
    headers: Ember.computed(function () {
      let headers = {};

      if (window.Outdoorsy && window.Outdoorsy.metrics && window.Outdoorsy.metrics.getIdentifier) {
        let {
          sid,
          did
        } = window.Outdoorsy.metrics.getIdentifier();
        headers['X-App-Session'] = sid;
        headers['X-Device-ID'] = did;
      }

      return headers;
    }).volatile(),

    authorize(xhr) {
      let accessToken = this.get('session.data.authenticated.token');

      if (Ember.isPresent(accessToken)) {
        xhr.setRequestHeader('Authorization', `Token=${accessToken.replace('guest:', '')}`);
      }
    }

  });

  _exports.default = _default;
});