define("portal/components/validations/tint-line/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    adminRequest: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: 'tr',
    check: null,
    booking: null,
    afterChange: null,
    toUserID: '',
    acceptedDrivers: Ember.A(),
    // we currently support reassigning user specific validations for id verification
    canReassign: Ember.computed('check.{status,arguments.booking_id}', 'booking', 'acceptedDrivers.[]', function () {
      let len = this.acceptedDrivers.length || 0;

      if (len <= 1) {
        return false;
      }

      let args = Ember.get(this, 'check.arguments');
      let isUserValidation = Ember.isPresent(args.booking_id) && Ember.isPresent(args.user_id);
      return Ember.get(this, 'check.status') === 'passed' && isUserValidation;
    }),
    forceApprove: (0, _emberConcurrency.task)(function* () {
      if (!this.actionReason) {
        this.flashMessages.danger('Add a reason to continue');
        return;
      }

      if (!confirm('Are you sure you want to force approve this validation?')) {
        return;
      }

      yield this.adminRequest.request(`admin/validations/${this.check.validation_id}/force`, {
        method: 'POST',
        data: JSON.stringify({
          reason: this.actionReason,
          booking_id: parseInt(this.booking.id)
        })
      });

      if (Ember.get(this, 'afterChange')) {
        Ember.get(this, 'afterChange')();
      }
    }),
    reassign: (0, _emberConcurrency.task)(function* () {
      if (!Ember.get(this, 'canReassign')) {
        return;
      }

      let fromUserID = Ember.get(this, 'check.arguments.user_id');

      if (Ember.isBlank(fromUserID) || Ember.isBlank(this.toUserID)) {
        this.flashMessages.danger('Old and New User ID must be set');
        return;
      }

      let info = `Are you sure you want to re-assign this validation from ${fromUserID} to user ${this.toUserID}`;

      if (!confirm(info)) {
        return;
      }

      let valId = Ember.get(this, 'check.validation_id');

      if (!valId) {
        Ember.set(this, 'error', 'No validation id found');
        return;
      }

      try {
        yield this.adminRequest.post(`admin/validations/${valId}/reassign`, {
          data: JSON.stringify({
            from_user_id: fromUserID,
            to_user_id: String(this.toUserID)
          })
        });
        Ember.tryInvoke(this, 'afterChange');
        Ember.set(this, 'toUserID', null);
      } catch (error) {
        let msg = 'There was an error reassigning the validation';

        if (error && error.payload) {
          msg = `${error.payload.error}`;

          if (error.payload.original_error) {
            msg += `- ${error.payload.original_error}`;
          }
        }

        this.flashMessages.danger(msg);
      }
    }),
    canRetry: Ember.computed('check.status', function () {
      let status = Ember.get(this, 'check.status');
      return status === 'error';
    }),
    retry: (0, _emberConcurrency.task)(function* () {
      let valId = Ember.get(this, 'check.validation_id');

      if (!valId) {
        Ember.set(this, 'error', 'No validation id found');
        return;
      }

      try {
        yield this.adminRequest.post(`admin/validations/${valId}/retry`);
        yield (0, _emberConcurrency.timeout)(5000);
        Ember.tryInvoke(this, 'afterChange');
      } catch (error) {
        let msg = 'There was an error retrying the validation';

        if (error && error.payload) {
          msg = `${error.payload.error}`;

          if (error.payload.original_error) {
            msg += `- ${error.payload.original_error}`;
          }
        }

        this.flashMessages.danger(msg);
      }
    }),
    actions: {
      updateToUser(option) {
        Ember.set(this, 'toUserID', option.value);
      }

    }
  });

  _exports.default = _default;
});