define("portal/routes/trust-safety-queue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params, transition) {
      let qp = transition.to.queryParams;
      return this.store.query('ts-queue-item', {
        status: 'unchecked,in_review',
        order_by: 'score',
        user_id: qp.user_id,
        booking_id: qp.booking_id,
        service: qp.service,
        id: qp.id
      });
    }

  });

  _exports.default = _default;
});