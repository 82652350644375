define("portal/mixins/social-auth", ["exports", "ember-window-mock"], function (_exports, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    login: Ember.inject.service(),

    loginPath(method) {
      let appConfig = Ember.getOwner(this).resolveRegistration('config:environment');

      const state = this.get('login')._convertedState(this.get('state') || this.get('session.state'));

      return `${appConfig.APP.apiHost}/v0/auth/${method}/login?state=${state}`;
    },

    actions: {
      launchAuth(method) {
        return _emberWindowMock.default.location.href = this.loginPath(method);
      }

    }
  });

  _exports.default = _default;
});