define("portal/components/sort-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    name: '',
    fieldName: '',
    direction: 'desc',
    sortBy: '',
    sortDir: 'desc',
    attributeBindings: ['href'],
    classNames: ['nowrap'],
    href: '#',
    sortAscending: Ember.computed('sortDir', function () {
      return this.get('sortDir') === 'asc';
    }),

    click() {
      let dir = this.get('sortDir') === 'desc' ? 'asc' : 'desc';
      this.get('onClick')(this.get('fieldName'), dir);
    }

  });

  _exports.default = _default;
});