define("portal/components/ts-queue-item-row/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TsQueueItemRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('args.item'), _dec4 = Ember.computed.alias('args.selected'), _dec5 = Ember.computed.alias('args.onClick'), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class TsQueueItemRowComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);

      _initializerDefineProperty(this, "item", _descriptor3, this);

      _initializerDefineProperty(this, "selected", _descriptor4, this);

      _initializerDefineProperty(this, "onToggle", _descriptor5, this);

      _initializerDefineProperty(this, "deleteItem", _descriptor6, this);
    }

    get statusOptions() {
      return ['unchecked', 'in_review', 'cleared', 'rejected'];
    }

    get ownerObject() {
      if (['user', 'booking'].indexOf(this.item.objectType) !== -1) {
        return this.store.findRecord(this.item.objectType, this.item.objectId);
      }

      return {};
    }

    toggleEditNotesModal(item) {
      let oType = item.get('objectType');

      if (oType === 'user' || oType === 'booking') {
        Ember.set(item, 'expandNotes', !item.expandNotes);
        let params = {
          internal: true
        };

        if (oType === 'user') {
          params.user_id = this.item.objectId;
        } else if (oType === 'booking') {
          params.booking_id = this.item.objectId;
        }

        this.store.query('note', params).then(notes => {
          Ember.set(this, 'objectNotes', notes);
        });
        return;
      }

      this.args.toggleEditNotesModal();
    }

    updateStatus(item, status) {
      if (status === 'rejected') {
        if (!window.confirm('Danger, Will Robinson! Rejection will ban the user and cancel all bookings with a full refund. This is irreversible!')) {
          return;
        }
      }

      Ember.set(item, 'status', status);
      item.save().then(() => {}).catch(() => {
        this.get('flashMessages').danger(`Error setting status to ${item.get('status')}`);
      });
    }

    toggleSelect() {
      if (this.onToggle) {
        this.onToggle();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "item", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selected", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onToggle", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleEditNotesModal", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "toggleEditNotesModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateStatus", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "updateStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleSelect", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSelect"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "deleteItem", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (item) {
        try {
          yield item.destroyRecord();
          this.flashMessages.success('Item deleted');
        } catch (err) {
          this.flashMessages.danger(err);
        }
      };
    }
  })), _class));
  _exports.default = TsQueueItemRowComponent;
});