define("portal/controllers/impact-search", ["exports", "ember-concurrency-decorators", "portal/config/environment", "moment"], function (_exports, _emberConcurrencyDecorators, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ImpactSearchController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.readOnly('model'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class ImpactSearchController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "adminRequest", _descriptor, this);

      _initializerDefineProperty(this, "apiRequest", _descriptor2, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "bookings", _descriptor5, this);

      _initializerDefineProperty(this, "totalResultCount", _descriptor6, this);

      _initializerDefineProperty(this, "filter", _descriptor7, this);

      _defineProperty(this, "chargeTypes", [{
        value: 'booking_charge',
        text: ''
      }, {
        value: 'reservation_charge',
        text: ''
      }, {
        value: 'remainder_charge',
        text: ''
      }, {
        value: 'referral_credit',
        text: ''
      }, {
        value: 'fx_fee_charge',
        text: ''
      }, {
        value: 'trip_insurance_charge',
        text: ''
      }, {
        value: 'security_deposit_charge',
        text: ''
      }, {
        value: 'deductible',
        text: ''
      }, {
        value: 'gift_card_charge',
        text: ''
      }, {
        value: 'cash_fee_charge',
        text: ''
      }]);

      _defineProperty(this, "refundTypes", [{
        value: 'refund',
        text: ''
      }, {
        value: 'cash_fee_refund',
        text: ''
      }, {
        value: 'gift_card_refund',
        text: ''
      }, {
        value: 'referral_credit_refund',
        text: ''
      }, {
        value: 'fx_fee_refund',
        text: ''
      }, {
        value: 'trip_insurance_refund',
        text: ''
      }, {
        value: 'security_deposit_refund',
        text: ''
      }, {
        value: 'owner_refund',
        text: ''
      }, {
        value: 'cash_refund',
        text: ''
      }]);

      _defineProperty(this, "creditTypes", [{
        value: 'cash_conversion',
        text: ''
      }, {
        value: 'marketing',
        text: ''
      }, {
        value: 'employee_trip',
        text: ''
      }, {
        value: 'referrer',
        text: ''
      }, {
        value: 'referee',
        text: ''
      }, {
        value: 'support*',
        text: ''
      }, // The asterisk here is intentional
      {
        value: 'review',
        text: ''
      }, {
        value: 'dealer',
        text: ''
      }]);

      _initializerDefineProperty(this, "moreOptions", _descriptor8, this);

      _initializerDefineProperty(this, "abacusEnv", _descriptor9, this);

      _initializerDefineProperty(this, "search", _descriptor10, this);

      _initializerDefineProperty(this, "downloadCsv", _descriptor11, this);
    }

    get envOptions() {
      return ['', 'production', 'staging'];
    }

    toggleOptions() {
      this.moreOptions = !this.moreOptions;
    }

    clearFilter() {
      this.filter = {
        created_gte: (0, _moment.default)('2021-05-12'),
        event_names: null,
        is_cancelled: null,
        cancelled_status: null,
        renter_cancelled: null,
        owner_cancelled: null,
        is_handed_off: null,
        has_discount_code: null,
        discount_code_type: null,
        has_charge_authorized: null,
        has_charge_captured: null,
        has_payout: null,
        has_failed_payout: null,
        include_charge_types: [],
        exclude_charge_types: [],
        include_refund_types: [],
        exclude_refund_types: [],
        has_affirm_loan_captured: null,
        has_security_deposit_claim: null,
        has_rebooked: null,
        has_rebooked_pre_departure: null,
        has_rebooked_post_departure: null,
        has_rebooked_unprocessable: null,
        has_refund: null,
        has_reversal: null,
        has_withdraw: null,
        has_transfer: null,
        has_unprocessable_event: null,
        credit_types: [],
        has_sales_tax: null,
        outdoorsy_hold_tax: null,
        federal_withholding: null,
        source: null,
        has_dispute_funds_reinstated: null,
        has_dispute_funds_withdrawn: null,
        has_manual_reversal: null,
        has_manual_refund: null,
        has_credit_conversion: null,
        has_owner_credits: null,
        has_unexpected_balance: null,
        has_pending_balance: null,
        has_dealer_cash: null,
        has_paypal: null,
        has_apple_pay: null,
        has_google_pay: null,
        has_credit_card: null,
        has_gateway_charges: null
      };
    }

    abacusRequest(url, method) {
      let abacusHost = _environment.default.APP.abacusHost;
      let abacusKey = _environment.default.APP.abacusKey;

      if (this.abacusEnv === 'production') {
        abacusHost = 'https://abacus.outdoorsy.com';
      } else if (this.abacusEnv === 'staging') {
        abacusHost = 'https://abacus.staging.outdoorsy.com';
      }

      let cleanFilter = Object.assign({}, this.filter);
      Object.keys(cleanFilter).forEach( // keep false values
      k => (cleanFilter[k] === null || cleanFilter[k] === undefined) && delete cleanFilter[k]);

      if (!cleanFilter.is_cancelled) {
        delete cleanFilter['cancelled_status'];
      } // just silly


      if (cleanFilter.created_gte) {
        cleanFilter.created_gte = (0, _moment.default)(cleanFilter.created_gte).format('YYYY-MM-DD');
      }

      console.log(cleanFilter);
      return this.apiRequest.request(`${abacusHost}${url}`, {
        method,
        data: cleanFilter,
        headers: {
          Authorization: `Bearer ${abacusKey}`
        }
      }).then(resp => {
        // fetchResponseMeta is not defined for non-json response types. (ex csv download)
        if (resp.fetchResponseMeta) {
          const headers = resp.fetchResponseMeta.headers;
          let count = headers.get('Total-Results');
          this.totalResultCount = count;
        }

        return resp;
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adminRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "bookings", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "totalResultCount", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filter", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return {
        created_gte: (0, _moment.default)(),
        event_names: null,
        is_cancelled: null,
        cancelled_status: null,
        renter_cancelled: null,
        owner_cancelled: null,
        is_handed_off: null,
        has_discount_code: null,
        discount_code_type: null,
        has_charge_authorized: null,
        has_charge_captured: null,
        has_payout: null,
        has_failed_payout: null,
        include_charge_types: [],
        exclude_charge_types: [],
        include_refund_types: [],
        exclude_refund_types: [],
        has_affirm_loan_captured: null,
        has_security_deposit_claim: null,
        has_rebooked: null,
        has_rebooked_pre_departure: null,
        has_rebooked_post_departure: null,
        has_rebooked_unprocessable: null,
        has_unprocessable_event: null,
        has_refund: null,
        has_withdraw: null,
        has_reversal: null,
        has_transfer: null,
        credit_types: [],
        has_sales_tax: null,
        federal_withholding: null,
        outdoorsy_hold_tax: null,
        source: null,
        has_dispute_funds_reinstated: null,
        has_dispute_funds_withdrawn: null,
        has_manual_reversal: null,
        has_manual_refund: null,
        has_credit_conversion: null,
        has_owner_credits: null,
        has_unexpected_balance: null,
        has_pending_balance: null,
        has_dealer_cash: null,
        has_paypal: null,
        has_apple_pay: null,
        has_google_pay: null,
        has_credit_card: null,
        has_gateway_charges: null
      };
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "moreOptions", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "abacusEnv", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleOptions", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggleOptions"), _class.prototype), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "search", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        let url = `/rv_reservations`;
        this.model = yield this.abacusRequest(url, 'post');
      };
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "downloadCsv", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        const url = `/rv_reservations?export=csv`;
        let csvString = yield this.abacusRequest(url, 'post');
        const blob = new Blob([csvString], {
          type: 'text/csv'
        });

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, 'filename.csv');
        } else {
          var a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(blob, {
            type: 'text/csv'
          });
          a.download = `booking-list.csv`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "clearFilter", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilter"), _class.prototype)), _class));
  _exports.default = ImpactSearchController;
});