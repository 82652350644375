define("portal/utils/is-error-status", ["exports", "@outdoorsyco/ember-shared-data/utils/is-error-status"], function (_exports, _isErrorStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _isErrorStatus.default;
    }
  });
});