define("portal/components/partner-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ClOaKYY",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input-field\",null,[[\"value\",\"label\",\"placeholder\",\"required\"],[[24,[\"partner\",\"name\"]],\"Partner Name\",\"Partner Name\",true]]],false],[0,\"\\n\\n\"],[1,[28,\"input-field\",null,[[\"value\",\"label\",\"placeholder\",\"required\"],[[24,[\"partner\",\"slug\"]],\"Slug\",\"Slug\",true]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"label\",true],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[24,[\"partner\",\"active\"]]]]],false],[0,\"\\n    Active\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"loading-button\",null,[[\"buttonClass\",\"buttonText\",\"loading\",\"action\"],[\"btn btn-primary\",[28,\"t\",[\"ui-save\"],null],[24,[\"partner\",\"isSaving\"]],\"savePartner\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/components/partner-form/template.hbs"
    }
  });

  _exports.default = _default;
});