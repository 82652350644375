define("portal/controllers/reports/insurance-report", ["exports", "moment", "portal/controllers/-csv-export"], function (_exports, _moment, _csvExport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_csvExport.default, {
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    queryParams: ['from', 'to', 'page', 'statuses', 'sources'],
    approved: false,
    exportAll: true,
    page: 1,
    perPage: 50,
    totalResultCount: 0,
    bookings: null,
    from: (0, _moment.default)().format('YYYY-MM-DD'),
    to: (0, _moment.default)().add('days', 2).format('YYYY-MM-DD'),

    init() {
      this._super(...arguments);

      this.set('csvRoute', `/admin/bookings/insurance-report`);
      this.bookings = [];
      this.availableStatuses = [{
        display: 'Approved',
        value: 'approved'
      }, {
        display: 'Imminent',
        value: 'imminent'
      }, {
        display: 'Handed off',
        value: 'handed_off'
      }, {
        display: 'Returned',
        value: 'returned'
      }];
      this.statuses = ['approved', 'imminent'];
      this.availableSources = [{
        display: 'Combo',
        value: 'combo'
      }, {
        display: 'Wheelbase',
        value: 'wheelbase'
      }, {
        display: 'Outdoorsy',
        value: 'outdoorsy'
      }];
      this.sources = ['combo', 'wheelbase', 'outdoorsy'];
    },

    fromDate: Ember.computed('from', function () {
      let from = this.get('from');

      if (typeof from === 'string') {
        from = (0, _moment.default)(from, 'YYYY-MM-DD');
      }

      return from;
    }),
    toDate: Ember.computed('to', function () {
      let to = this.get('to');

      if (typeof to === 'string') {
        to = (0, _moment.default)(to, 'YYYY-MM-DD');
      }

      return to;
    }),
    totalPages: Ember.computed('totalResultCount', function () {
      let total = this.get('totalResultCount') || 0;
      let perPage = this.get('perPage');
      return Math.ceil(total / perPage);
    }),
    selectedDates: Ember.computed('to', 'from', function () {
      return `${this.get('from')} - ${this.get('to')}`;
    }),
    actions: {
      loadBookings() {
        this._loadBookings();
      },

      setPage(page) {
        this.set('page', page);

        this._loadBookings();
      },

      setDates(startDate, endDate) {
        this.set('from', (0, _moment.default)(startDate).format('YYYY-MM-DD'));
        this.set('to', (0, _moment.default)(endDate).format('YYYY-MM-DD'));
      },

      submit() {
        this._loadBookings();
      },

      downloadCsv() {
        this._downloadCsv();
      }

    },

    _loadBookings() {
      let params = this._getQueryParams();

      this.set('loading', true);
      this.get('adminRequest').request('/admin/bookings/insurance-report', {
        data: params
      }).then(response => {
        // It's possible for multiple requests to be happening at once, we
        // want to display the results for the currently selected query params.
        if (JSON.stringify(params) !== JSON.stringify(this._getQueryParams())) {
          return;
        }

        this.set('totalResultCount', Number(response.meta.total));
        this.set('bookings', response.data.reports);
      }).catch(() => {
        this.get('flashMessages').danger('An error has occurred.');
      }).finally(() => {
        this.set('loading', false);
      });
    },

    _getQueryParams(fromExport) {
      let from = this.get('from');
      let to = this.get('to');
      let limit = this.get('perPage');
      let statuses = this.get('statuses').join(',');
      let sources = this.get('sources').join(',');
      let offset = (this.get('page') - 1) * limit;

      if (fromExport) {
        let exportAll = this.get('exportAll');

        if (exportAll) {
          limit = 0;
          offset = 0;
        }
      }

      let params = {
        from: from,
        to: to,
        statuses: statuses,
        source: sources,
        offset: offset,
        limit: limit
      };
      return params;
    },

    _downloadCsv() {
      let csvDownload = this.get('csvDownload');
      this.adminRequest.request(csvDownload, {
        method: 'GET'
      }).then(csvString => {
        const blob = new Blob([csvString], {
          type: 'text/csv'
        });

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, 'filename.csv');
        } else {
          var a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(blob, {
            type: 'text/csv'
          });
          a.download = `insurance-list.csv`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }).catch(() => {
        this.flashMessages.danger('There was an issue generating your file.');
      }).finally(() => {
        this.send('cancelCSV');
      });
    }

  });

  _exports.default = _default;
});