define("portal/styles/pages/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "notes-container": "notes-container",
    "note": "note",
    "red": "red",
    "green": "green",
    "blue": "blue",
    "yellow": "yellow",
    "note-meta": "note-meta"
  };
  _exports.default = _default;
});