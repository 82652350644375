define("portal/components/stalled-driver/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: ['checkRun:check-run'],
    adminRequest: Ember.inject.service(),
    checkRun: false,
    approveDriver: (0, _emberConcurrency.task)(function* (driver) {
      yield this.get('adminRequest').request('verify/identity/finalize', {
        method: 'POST',
        data: JSON.stringify({
          driver_id: driver.id,
          score: 'passed'
        })
      });
      this.set('checkRun', true);

      if (this.get('afterChange')) {
        this.get('afterChange')();
      }
    }),
    denyDriver: (0, _emberConcurrency.task)(function* (driver) {
      yield this.get('adminRequest').request('verify/identity/finalize', {
        method: 'POST',
        data: JSON.stringify({
          driver_id: driver.id,
          score: 'failed'
        })
      });
      this.set('checkRun', true);

      if (this.get('afterChange')) {
        this.get('afterChange')();
      }
    }),
    forceCheckr: (0, _emberConcurrency.task)(function* (driver) {
      yield this.get('adminRequest').request(`/verify/driver/${driver.id}/force`, {
        method: 'POST',
        data: JSON.stringify({
          free_check: true
        })
      });
      this.set('checkRun', true);

      if (this.get('afterChange')) {
        this.get('afterChange')();
      }
    })
  });

  _exports.default = _default;
});