define("portal/instance-initializers/set-locale", ["exports", "portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    let domainLocales = _environment.default.domainLocales || {};
    let intl = appInstance.lookup('service:intl');
    let host = window.location.host;
    let locale = domainLocales[host] || 'en';
    intl.setLocale(locale);
  }

  var _default = {
    name: 'set-locale',
    initialize
  };
  _exports.default = _default;
});