define("portal/helpers/money-formatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),

    compute(params, hash) {
      // set default options, cannot modify incoming read-only hash
      let options = Object.assign({}, hash || {});

      if (Ember.isBlank(options['format'])) {
        let userCurrency = false;

        if (this.get('session.loggedIn')) {
          userCurrency = this.get('session.currentAccount.localeBaseCurrency') || this.get('session.user.localeBaseCurrency');
        }

        let currency = userCurrency || 'USD';
        options['format'] = currency;
      }

      options['style'] = 'currency';
      options['currency'] = options['format'];
      options['fallback'] = 0;
      let value = params[0] || 0.0;

      if (options['cents'] && value !== 0) {
        value = parseInt(value) / 100;
      } // force negative


      let negative = options['forceNegative'] || value < 0;

      if (negative) {
        value = 0 - Math.abs(value);
      }

      let result = this.get('intl').formatNumber(value, options);

      if (negative || options['parens']) {
        result = `(${result})`;
      }

      return result;
    }

  });

  _exports.default = _default;
});