define("portal/controllers/users/show/affiliate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    userController: Ember.inject.controller('users.show'),
    actions: {
      createAffiliate() {
        let affiliate = this.store.createRecord('affiliate', {
          user: this.get('userController.model')
        });
        this.set('activeAffiliate', affiliate);
      },

      cancelActiveAffiliate() {
        this.set('activeAffiliate', null);
        this.get('store').unloadAll('affiliate');
        this.send('reloadRoute');
      },

      editAffiliate(affiliate) {
        this.set('activeAffiliate', affiliate);
      }

    }
  });

  _exports.default = _default;
});