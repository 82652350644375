define("portal/styles/pac-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "pac-container": "pac-container",
    "hdpi": "hdpi",
    "pac-logo": "pac-logo",
    "pac-icon": "pac-icon",
    "pac-item": "pac-item"
  };
  _exports.default = _default;
});