define("portal/modifiers/tether-to", ["exports", "@outdoorsyco/ember-switchback/modifiers/tether-to"], function (_exports, _tetherTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _tetherTo.default;
    }
  });
});