define("portal/controllers/users/show/employees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* Required due to route setup, do not delete */
  var _default = Ember.Controller.extend({});

  _exports.default = _default;
});