define("portal/components/header-nav/component", ["exports", "portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      this.set('config', _environment.default);
    },

    onSubmit(e) {
      e.preventDefault();
      e.stopPropagation();
    },

    actions: {
      setSearchType(type) {
        if (type === this.get('searchType')) {
          this.set('searchType', '');
        } else {
          this.set('searchType', type);
        }
      }

    }
  });

  _exports.default = _default;
});