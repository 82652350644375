define("portal/controllers/bookings/show/comms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortedOwner: Ember.computed.sort('commsToOwner', function (a, b) {
      return b.get('sent') - a.get('sent');
    }),
    sortedRenter: Ember.computed.sort('commsToRenter', function (a, b) {
      return b.get('sent') - a.get('sent');
    })
  });

  _exports.default = _default;
});