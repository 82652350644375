define("portal/routes/users/show/external", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel() {
      this._super(...arguments);

      let controller = this.controllerFor('users/show/external');
      Ember.run.scheduleOnce('afterRender', this, () => {
        controller.get('_loadUser').perform();
        controller.get('_loadLocations').perform();
      });
    }

  });

  _exports.default = _default;
});