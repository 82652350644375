define("portal/adapters/discount-code", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType() {
      return 'discount-codes';
    }

  });

  _exports.default = _default;
});