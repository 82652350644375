define("portal/adapters/nydus-user", ["exports", "@outdoorsyco/ember-nydus/adapters/nydus-user"], function (_exports, _nydusUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _nydusUser.default;
    }
  });
});