define("portal/helpers/titleize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.titleize = titleize;
  _exports.default = void 0;

  function titleize(value) {
    let _value = value;

    if (Ember.isArray(_value)) {
      _value = _value[0];
    }

    if (typeof _value !== 'string') {
      return _value;
    }

    _value = _value.replace(/^\s+|\s+$/g, '');
    let specials = {
      a: 'Class A',
      b: 'Class B',
      c: 'Class C',
      vin: 'VIN',
      imminent: 'Starts Soon',
      rv: 'RV',
      'miles/gallon': 'miles / gal',
      negotiating: 'Quote',
      owner_accepted: 'Approved',
      owner_declined: 'Declined',
      owner_cancelled: 'Cancelled',
      renter_cancelled: 'Renter Cancelled',
      renter_withdrew: 'Renter Withdrew',
      renter_not_started: 'Driving Check Required',
      pending_intl: 'Pending International Check',
      all_approved: 'Approved',
      some_approved: 'Some Drivers Approved',
      none_approved: 'No Approved Drivers',
      vehicle_not_started: 'Vehicle not submitted',
      user: 'Customer',
      booking: 'Reservation'
    };

    if (specials[_value.toLowerCase()]) {
      return specials[_value.toLowerCase()];
    }

    return _value.split(/[_\-\s]/).map(section => section.capitalize()).join(' ');
  }

  var _default = Ember.Helper.helper(titleize);

  _exports.default = _default;
});