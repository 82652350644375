define("portal/adapters/webhook-request", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType() {
      return `webhooks/:webhook_id/requests`;
    },

    buildURL(type, id, snapshot, requestType, queryParams) {
      let webhookId;
      let qp = queryParams || {};

      if (snapshot) {
        webhookId = snapshot.belongsTo('webhook', {
          id: true
        });
      } else if (qp && qp.webhook_id) {
        webhookId = qp.webhook_id;
        delete queryParams.webhook_id;
      }

      let url = this._super(type, id, snapshot);

      url = url.replace(':webhook_id', webhookId);
      return url;
    }

  });

  _exports.default = _default;
});