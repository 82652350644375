define("portal/controllers/bookings/show/iterable", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const did = 'iterable-test';
  const sid = 'iterable-test';
  const appId = 'iterable-test';

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.eventOptions = [{
        name: 'Booking : Lifecycle : Renter created request',
        role: 'renter',
        type: 'lifecycle'
      }, {
        name: 'Booking : Lifecycle : Owner received request',
        role: 'owner',
        type: 'lifecycle'
      }, {
        name: 'Booking : Lifecycle : Renter approved',
        role: 'renter',
        type: 'lifecycle'
      }, {
        name: 'Booking : Lifecycle : Renter cancelled',
        role: 'renter',
        type: 'lifecycle'
      }, {
        name: 'Booking : Lifecycle : Renter withdrew',
        role: 'renter',
        type: 'lifecycle'
      }, {
        name: 'Booking : Lifecycle : Owner approved',
        role: 'owner',
        type: 'lifecycle'
      }, {
        name: 'Booking : Lifecycle : Owner cancelled',
        role: 'owner',
        type: 'lifecycle'
      }, {
        name: 'Booking : Lifecycle : Owner declined',
        role: 'owner',
        type: 'lifecycle'
      }, {
        name: 'Booking : Lifecycle : Sealed',
        role: 'renter',
        type: 'lifecycle'
      }, {
        name: 'Booking : Lifecycle : Rental handed off',
        role: 'owner',
        type: 'lifecycle'
      }, {
        name: 'Booking : Lifecycle : Rental returned',
        role: 'owner',
        type: 'lifecycle'
      }, {
        name: 'Booking : Lifecycle : Renter reviewed',
        role: 'renter',
        type: 'lifecycle'
      }, {
        name: 'Booking : Lifecycle : Credit card submitted',
        role: 'renter',
        type: 'lifecycle'
      }, {
        name: 'Booking : Lifecycle : Credit card charged',
        role: 'renter',
        type: 'lifecycle'
      }, {
        name: 'Booking : Lifecycle : Affirm payment charged',
        role: 'renter',
        type: 'lifecycle'
      }];
      let checkoutFlowEvents = [{
        name: 'Booking : Lifecycle : Message step completed',
        type: 'checkout',
        step: 'message'
      }, {
        name: 'Booking : Lifecycle : Addons step completed',
        type: 'checkout',
        step: 'addons'
      }, {
        name: 'Booking : Lifecycle : Questions step completed',
        type: 'checkout',
        step: 'questions'
      }, {
        name: 'Booking : Lifecycle : Payment step completed',
        type: 'checkout',
        step: 'payment'
      }, {
        name: 'Booking : Lifecylce : Custom insurance step completed',
        type: 'checkout',
        step: 'insurance-custom'
      }, {
        name: 'Booking : Lifecycle : Outdoorsy insurance step completed',
        type: 'checkout',
        step: 'insurance-outdoorsy'
      }, {
        name: 'Booking : Lifecycle : Verify dmv step completed',
        type: 'checkout',
        step: 'verify-dmv'
      }, {
        name: 'Booking : Lifecycle : Verify id step completed',
        type: 'checkout',
        step: 'verify-id'
      }, {
        name: 'Booking : Lifecycle : Roadside assistance step completed',
        type: 'checkout',
        step: 'roadside-assistance'
      }, {
        name: 'Booking : Lifecycle : Trip insurance step completed',
        type: 'checkout',
        step: 'trip-insurance'
      }, {
        name: 'Booking : Lifecycle : Interior damage protection step completed',
        type: 'checkout',
        step: 'interior-damage-protection'
      }, {
        name: 'Booking : Lifecycle : Confirmation step completed',
        type: 'checkout',
        step: 'confirmation'
      }];
      this.eventOptions = this.eventOptions.concat(checkoutFlowEvents);
      let otherEvents = [{
        name: 'Searched for a rental',
        data: {
          device: 'm',
          referrer: 'https://www.google.com/',
          library: appId,
          sent_at: '2019-7-9 23:16:11',
          app_id: appId,
          url: 'https://www.outdoorsy.com/rv-search?feature=&ne_lat=47.766889&ne_lng=-122.6118458&page=1&q=Poulsbo%2C%20Washington%2C%20United%20States&score=&sleeps=2&style=&sw_lat=47.71730489999999&sw_lng=-122.6758709&type=',
          path: '/rv-search',
          search: '?feature=&ne_lat=47.766889&ne_lng=-122.6118458&page=1&q=Poulsbo%2C%20Washington%2C%20United%20States&score=&sleeps=2&style=&sw_lat=47.71730489999999&sw_lng=-122.6758709&type=',
          hostname: 'www.outdoorsy.com',
          title: 'Get Great Rates on Amazing RV Rentals | Outdoorsy',
          filters: {
            page: 1,
            q: 'Poulsbo, Washington, United States',
            neLat: '47.766889',
            neLng: '-122.6118458',
            swLat: '47.71730489999999',
            swLng: '-122.6758709',
            style: ['drive'],
            sleeps: 2,
            score: '',
            minPrice: null,
            maxPrice: null,
            from: '2020-08-08',
            to: '2020-08-14',
            keywords: null,
            relativeDate: null,
            datePeriod: null,
            deliveryRadius: 0,
            lat: null,
            lng: null,
            perPage: 26
          },
          event: 'Searched for a rental',
          perPage: 26,
          lat: 47.74210140107931,
          lng: -122.643873595555,
          experiments: {},
          type: 'event',
          sid: sid,
          did: did
        }
      }, {
        name: 'Viewed Product',
        data: {
          device: 'm',
          library: appId,
          sent_at: '2019-7-9 23:22:18',
          app_id: appId,
          url: 'https://www.outdoorsy.com/rv-rental/powell_oh/2014_thor-motor-coach_hurricane_105702-listing?from=2019-08-08&to=2019-08-14',
          path: '/rv-rental/powell_oh/2014_thor-motor-coach_hurricane_105702-listing',
          search: '?from=2019-08-08&to=2019-08-14',
          hostname: 'www.outdoorsy.com',
          title: '2013 Thor Motor Coach Hurricane Motor Home Class A Rental in Powell, OH | Outdoorsy',
          event: 'Viewed Product',
          product_id: '1',
          sku: '1',
          category: 'a',
          name: '2013 Thor Motor Coach Hurricane',
          sleeps: 7,
          rental_id: '1',
          image_url: 'https://res.cloudinary.com/outdoorsy/image/upload/a_exif,q_auto,f_auto,w_auto,e_improve,h_630,w_1200,c_fill/c_scale,e_overlay,g_south_east,l_outdoorsy-logo,w_150,x_10,y_10/v1548277425/p/rentals/105702/images/kgbfwihrulnv3hfplfpf.jpg',
          from: '2020-08-08',
          to: '2020-08-14',
          instant_book: true,
          experiments: {},
          type: 'event',
          sid: sid,
          did: did
        }
      }, {
        name: 'Saved a search',
        data: {
          app_id: appId,
          context: {},
          did: did,
          event: 'Server: Saved a search',
          filters: {
            destination: 'Calgary, AB',
            from: '2020-08-23',
            q: 'Calgary, AB',
            sleeps: 1,
            style: 'drive',
            to: '2020-08-27'
          },
          from: '2020-08-23',
          library: appId,
          sid: sid,
          to: '2020-08-27',
          type: 'event'
        }
      }, {
        name: 'Favorited a listing',
        data: {
          app_id: appId,
          context: {},
          details: {
            image_url: 'https://res.cloudinary.com/outdoorsy/image/upload/v1533067297/p/rentals/78832/images/circsbk78zkzuxlptbxt.jpg',
            rental_location: {
              city: 'Longmont',
              country: 'US',
              lat: 0,
              lng: 0,
              state: 'CO'
            },
            rental_name: 'Georgie Boy 36 Encounter',
            rental_price: 10500,
            rental_url: '/rv-rental/longmont_co/2002_georgie-boy_36-encounter_78832-listing'
          },
          did: did,
          event: 'Server: Favorited a listing',
          library: appId,
          rental_id: 1,
          sid: sid,
          type: 'event'
        }
      }];
      this.eventOptions = this.eventOptions.concat(otherEvents);
      let imageCount = 16;
      this.imageNumber = Math.floor(Math.random() * imageCount) + 1;
    },

    selectedEvent: null,
    actions: {
      eventSelected(event) {
        let data = {};

        if (event.data) {
          data = Object.assign({}, event.data, {
            uid: this.model.id
          });
        } else if (event.type === 'lifecycle') {
          data = this._serverLifecyleEvent(event);
        } else if (event.type === 'checkout') {
          data = this._checkoutFlowEvent(event);
        }

        this.set('tempData', JSON.stringify(data, null, 2));
        this.set('selectedEvent', event);
      }

    },

    _serverLifecyleEvent(event) {
      let actor = event.role;
      let recipient = actor === 'renter' ? 'owner' : 'renter';
      let userId = Number(this.model.get(`${actor}.id`)); // fully loaded in route afterModel

      let rental = this.model.get('rental');
      return {
        event: event.name,
        type: 'event',
        actor: `outdoorsy-${actor}`,
        recipient: `outdoorsy-${recipient}`,
        role: actor,
        app_id: 'golang-api',
        library: appId,
        booking_id: Number(this.model.id),
        booking_url: `https://www.outdoorsy.com/bookings/${this.model.id}`,
        from: this.model.get('from').format('YYYY-MM-DD'),
        to: this.model.get('to').format('YYYY-MM-DD'),
        includes_addons: this.model.get('items.length') > 0,
        instamatch: this.model.get('isInstamatch'),
        instant_book: this.model.get('instantBook'),
        owner_approved: this.model.get('ownerAccepted'),
        renter_approved: this.model.get('renterAccepted'),
        rental_id: Number(this.model.get('rental.id')),
        rv_image_url: this.model.get('rental.primaryImage.url'),
        rv_name: this.model.get('rental.name'),
        status: this.model.get('status'),
        rig_location: `${rental.get('location.city')}, ${rental.get('location.state')}`,
        owner_name: this.model.get('owner.name'),
        sleeps: rental.get('sleeps'),
        price_per_night: this.model.get('total') / this.model.get('duration'),
        total_cost: this.model.get('total'),
        length: rental.get('vehicleLength'),
        vehicle_type: rental.get('type'),
        insurance_tier: '',
        currency: this.model.get('localeBaseCurrency'),
        sid: sid,
        did: did,
        uid: userId,
        context: {}
      };
    },

    _checkoutFlowEvent(event) {
      return {
        event: event.name,
        type: 'event',
        device: 'd',
        library: appId,
        app_id: appId,
        url: `https://www.outdoorsy.com/dashboard/checkout/${this.model.id}`,
        path: `/dashboard/checkout/${this.model.id}/addons`,
        search: '',
        hostname: window.location.hostname,
        checkout_id: Number(this.model.id),
        step: 3,
        step_name: event.step,
        sleeps: this.model.get('rental.sleeps'),
        booking_id: Number(this.model.id),
        rental_id: Number(this.model.get('rental.id')),
        checkout_name: 'renter-request',
        value: this.model.get('total'),
        total: this.model.get('total'),
        coupon: '',
        discount: 0,
        currency: 'USD',
        from: this.model.get('from').format('YYYY-MM-DD'),
        to: this.model.get('to').format('YYYY-MM-DD'),
        experiments: {},
        sid: sid,
        did: did,
        uid: Number(this.model.get('renter.id'))
      };
    },

    _sendEvent: (0, _emberConcurrency.task)(function* () {
      try {
        let data = JSON.stringify(JSON.parse(this.tempData));
        yield this.ajax.request('https://nmj4dtb1ag.execute-api.us-west-2.amazonaws.com/production/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        });
        this.flashMessages.success('Event Sent!');
      } catch (e) {
        this.flashMessages.danger(`Error: ${e}`);
      }
    })
  });

  _exports.default = _default;
});