define("portal/templates/rentals/show/tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w5JKMUwr",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Tags\"],null],false],[0,\"\\n\"],[5,\"tags-container\",[],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/rentals/show/tags.hbs"
    }
  });

  _exports.default = _default;
});