define("portal/routes/insurance-plans", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    beforeModel() {
      if (this.session.user.get('adminLevel') < 2) {
        this.transitionTo('search');
      }
    },

    model() {
      return this.store.query('insurance-plan', {
        unregistered_only: false,
        SkipDealerCheck: true,
        SkipLocalityCheck: true
      });
    }

  });

  _exports.default = _default;
});