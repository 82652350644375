define("portal/components/mobile-pane/scroller", ["exports", "ember-mobile-pane/components/mobile-pane/scroller"], function (_exports, _scroller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _scroller.default;
    }
  });
});