define("portal/templates/handoffs/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NcthSI2j",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\nTotal handoff images uploaded: \"],[1,[23,0,[\"model\",\"total_handoff_upload_count\"]],false],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\nTotal handoff images missing exif data: \"],[1,[23,0,[\"model\",\"total_missing_exif_count\"]],false],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\nPercentage of images missing exif data: \"],[1,[23,0,[\"model\",\"display_exif_percentage\"]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/handoffs/index.hbs"
    }
  });

  _exports.default = _default;
});