define("portal/mixins/stated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['action'],
    action: null,
    // we need to set the observer after the model
    modelObserver: Ember.observer('action', function () {
      if (this.get('action')) {
        return this.send('fireAction');
      }
    }),
    actions: {
      fireAction() {
        return Ember.run.scheduleOnce('routerTransitions', () => {
          const action = this.get('action');

          if (action) {
            this.send(action);
          }

          return this.set('action', null);
        });
      },

      setState(action) {
        let search = location.search.substring(1); // remove user id - we don't want the user login to be stored

        search = search.replace(/&{0,1}user_id=[\w]+/gi, ''); // remove token

        search = search.replace(/&{0,1}token=[\w]+/gi, '');

        if (action) {
          // remove current action if we have one
          search = search.replace(/&{0,1}action=[\w]+/gi, '');

          if (search.length > 1) {
            search += '&';
          }

          search += `action=${encodeURIComponent(action)}`;
        }

        let url = window.location.pathname;

        if (search) {
          url += `?${search}`;
        }

        return this.get('session').set('state', url);
      }

    }
  });

  _exports.default = _default;
});