define("portal/adapters/partner-user", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType() {
      return `partners/:partner_id/users`;
    },

    buildURL(type, id, snapshot) {
      let url = this._super(type, id, snapshot);

      let partnerID = snapshot.belongsTo('partner', {
        id: true
      });
      let userID = snapshot.belongsTo('user', {
        id: true
      });

      if (snapshot.record.get('isNew')) {
        url = url.replace(':partner_id', partnerID);
        return url;
      }

      let path = `partners/${partnerID}/users/${userID}`;
      url = url.replace(/partners\/:partner_id\/users\/[\d]+/, path);
      return url;
    }

  });

  _exports.default = _default;
});