define("portal/routes/users/show/activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel() {
      let controller = this.controllerFor('users/show/activity');
      Ember.run.next(this, function () {
        controller.loadActivities.perform();
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          page: 1
        });
      }
    }

  });

  _exports.default = _default;
});