define("portal/adapters/api-key", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType() {
      return `accounts/:account_id/keys`;
    },

    buildURL(type, id, snapshot, requestType, queryParams) {
      let accountId;
      let qp = queryParams || {};

      if (snapshot) {
        accountId = snapshot.belongsTo('account', {
          id: true
        });
      } else if (qp && qp.account_id) {
        accountId = qp.account_id;
        delete queryParams.account_id;
      }

      if (id && snapshot) {
        id = snapshot.record.get('name');
      }

      let url = this._super(type, id, snapshot);

      url = url.replace(':account_id', accountId);
      return url;
    }

  });

  _exports.default = _default;
});