define("portal/controllers/rentals/show/insurance/index", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apiRequest: Ember.inject.service(),
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    disableRetry: false,
    waitingForReload: false,
    rentalBundles: Ember.A([]),
    vinCheckResponse: Ember.computed('model.vinCheck.raw_response', function () {
      let check = this.get('model.vinCheck.raw_response');

      if (!check) {
        return null;
      }

      return JSON.parse(check);
    }),
    vinCheckError: Ember.computed('model.vinCheck.error', function () {
      let error = this.get('model.vinCheck.error');
      return {
        error
      };
    }),
    canRetryVinCheck: Ember.computed('model.vinCheck.{status,error}', function () {
      let status = this.get('model.vinCheck.status');

      if (['error', 'failed'].indexOf(status) !== -1) {
        let error = this.get('model.vinCheck.error') || '';
        return error.indexOf('checksum failed') !== -1 || error.indexOf('auth_failed') !== -1 || error.indexOf('invalid character') !== -1;
      }

      return false;
    }),
    canShowInsurance: Ember.computed('model.owner.{insuranceCoverage,dealer},model.insuranceCoverage', function () {
      let insuranceCoverage = this.model.insuranceCoverage;

      if (!insuranceCoverage && this.model.owner.dealer) {
        insuranceCoverage = this.model.owner.insuranceCoverage;
      }

      return insuranceCoverage === 'normal' || insuranceCoverage === '' || insuranceCoverage === 'delivery';
    }),
    actions: {
      changePlan(plan) {
        this.set('model.insurancePlan', plan);
      },

      saveRental() {
        this.set('vinError', null);
        let rID = this.get('model.id');
        let changed = this.get('model').changedAttributes();

        if (changed['vehicleVin']) {
          this.adminRequest.patch(`rentals/${rID}/vin`, {
            data: JSON.stringify({
              vin: this.get('model.vehicleVin')
            })
          }).catch(err => {
            let msg = 'There was an error saving the VIN';

            if (err.status === 400) {
              msg = 'VIN appears to be invalid. Please try again';
            }

            this.set('vinError', msg);
          });
        }

        this.get('model').save();
      },

      retryVinCheck() {
        this.set('disableRetry', true);
        let valId = this.get('model.vinCheck.validation_id');

        if (!valId) {
          this.set('vinError', 'No validation id found on vin check');
          return;
        }

        this.adminRequest.post(`admin/validations/${valId}/retry`).then(() => {
          setTimeout(() => {
            this.get('model').reload().then(() => {
              this.set('disableRetry', false);
            });
          }, 10000);
        }).catch(err => {
          let msg = 'There was an error retrying the validation';

          if (err.status === 400) {
            msg = 'VIN appears to be invalid. Please try again';
          }

          this.set('vinError', msg);
          this.set('disableRetry', false);
        });
      }

    },
    forceApproveRunning: Ember.computed.or('forceApprove.isRunning', 'waitingForReload'),
    forceApprove: (0, _emberConcurrency.task)(function* () {
      if (!this.actionReason) {
        return this.flashMessages.danger('Add a reason to continue');
      }

      if (!window.confirm('Are you sure you want to force approve this validation?')) {
        return;
      }

      try {
        let validationId = this.get('model.vinCheck.validation_id');
        yield this.adminRequest.request(`admin/validations/${validationId}/force`, {
          method: 'POST',
          data: JSON.stringify({
            reason: this.actionReason
          })
        });
      } catch (error) {
        this.flashMessages.danger('Error force approving validation');
        this.logger.error(error);
      }

      this.set('waitingForReload', true);
      setTimeout(() => {
        this.model.reload().then(() => {
          this.set('waitingForReload', false);
        });
      }, 4000);
    }),
    _resetInsuranceState: (0, _emberConcurrency.task)(function* () {
      let rentalId = this.model.id;
      yield this.adminRequest.post(`admin/rentals/${rentalId}/reset-insurance`);
      yield this.model.reload();
      this.get('flashMessages').success('Insurance status has been reset');
    }),
    _retryInsuranceState: (0, _emberConcurrency.task)(function* () {
      let rentalId = this.model.id;
      yield this.adminRequest.post(`admin/enqueue/calculateRentalInsuranceState`, {
        data: JSON.stringify({
          rental_id: rentalId
        })
      });
      yield this.model.reload();
      this.get('flashMessages').success('Insurance application has been resubmitted, this could take a few seconds');
    }),
    _loadApplications: (0, _emberConcurrency.task)(function* (rentalId) {
      let insurance = yield this.get('adminRequest').request('insurance', {
        data: {
          rental_id: rentalId
        }
      });
      this.set('insuranceApplications', insurance);
    }),
    _loadBundles: (0, _emberConcurrency.task)(function* (rentalId) {
      if (this.rentalBundles.length > 0) {
        return;
      }

      let url = `rentals/${rentalId}/bundles?`;
      let owner = yield this.model.owner;

      if (!owner || !owner.dealer) {
        return;
      }

      if (owner && owner.dealer) {
        url += 'dealerOwned=true';
      }

      let vehicleBundles = yield this.apiRequest.request(url, {
        data: {}
      });
      url += '&delivery=true';
      let deliveryBundles = yield this.apiRequest.request(url, {
        data: {}
      });

      if (deliveryBundles.length > 0) {
        this.rentalBundles.pushObjects(deliveryBundles);
      }

      if (vehicleBundles.length > 0) {
        this.rentalBundles.pushObjects(vehicleBundles);
      }
    })
  });

  _exports.default = _default;
});