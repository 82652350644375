define("portal/serializers/affiliate-network", ["exports", "ember-data", "portal/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    // API -> what the model expects
    normalize(typeClass, hash) {
      let data = hash.attributes;
      data.id = hash.id;
      return this._super(typeClass, data);
    },

    // Model -> what the API expects
    serialize(record, options) {
      const data = this._super(record, options);

      return {
        data: {
          id: data.id,
          type: 'affiliate_network',
          attributes: data
        }
      };
    }

  });

  _exports.default = _default;
});