define("portal/controllers/users/show/validations", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apiRequest: Ember.inject.service(),
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    _any: 'any',
    queryParams: ['page'],
    page: 1,
    perPage: 25,
    total: 0,
    validatorType: null,
    validatorStatus: null,

    init() {
      this._super(...arguments);

      this.validatorTypes = [{
        label: 'Any',
        value: this._any
      }, {
        label: 'Tint',
        value: 'tint'
      }, {
        label: 'Roamly Risk',
        value: 'roamly_risk'
      }, {
        label: 'Persona',
        value: 'persona'
      }, {
        label: 'Evident',
        value: 'evident'
      }, {
        label: 'Tessera',
        value: 'tessera'
      }];
      this.validatorStatuses = [{
        label: 'Any',
        value: this._any
      }, {
        label: 'Pending',
        value: 'pending'
      }, {
        label: 'Processing',
        value: 'processing'
      }, {
        label: 'Passed',
        value: 'passed'
      }, {
        label: 'Flagged',
        value: 'flagged'
      }, {
        label: 'Error',
        value: 'error'
      }, {
        label: 'Failed',
        value: 'failed'
      }];
      this.set('validatorType', this._any);
      this.set('validatorStatus', this._any);
    },

    // Computed property to map validations to corresponding bookings and drivers
    validationsData: Ember.computed('validations.[]', 'drivers.[]', 'bookings.[]', function () {
      const validations = this.get('validations') || [];
      const drivers = this.get('drivers') || [];
      const bookings = this.get('bookings') || [];
      const data = [];
      validations.forEach((validation, index) => {
        const booking = bookings ? bookings[index] : undefined;
        const bookingDrivers = drivers[index] || [];
        data.push({
          check: validation,
          booking: booking,
          acceptedDrivers: bookingDrivers
        });
      });
      return data;
    }),
    reload: (0, _emberConcurrency.task)(function* () {
      yield this.model.reload();

      this._loadUserValidations.perform();
    }),
    _loadUserValidations: (0, _emberConcurrency.task)(function* () {
      this.set('loading', true);
      let offset = (this.page - 1) * this.perPage;
      let options = {
        limit: this.perPage,
        offset: offset,
        order_by: '-created'
      };

      if (this.validatorType !== this._any) {
        options.validator = this.validatorType;
      }

      if (this.validatorStatus !== this._any) {
        options.status = this.validatorStatus;
      }

      this.set('options', options);

      try {
        const response = yield this.get('adminRequest').request(`admin/users/${this.get('model.id')}/validations`, {
          data: options
        });

        if (Ember.isEqual(this.get('options'), options)) {
          let validations = [];
          let bookings = [];
          let acceptedDrivers = [];
          response.forEach(item => {
            bookings.push(item.booking);

            if (item.booking) {
              delete item.booking;
            }

            acceptedDrivers.push(item.accepted_drivers);

            if (item.accepted_drivers) {
              delete item.accepted_drivers;
            }

            validations.push(item);
          });
          this.set('validations', validations);
          this.set('drivers', acceptedDrivers);
          this.set('bookings', bookings);
          const headers = response.fetchResponseMeta.headers;
          const total = headers.get('Total-Results');
          this.set('total', total);
        }
      } catch (err) {
        this.flashMessages.danger('There was an issue loading user validations...');
      }

      if (Ember.isEqual(this.get('options'), options)) {
        this.set('loading', false);
      }
    }),
    actions: {
      setPage(page) {
        this.set('page', page);
        this.send('loadData');
      },

      setValidatorType(option) {
        this.set('validatorType', option.value);
        this.send('loadData');
      },

      setValidationStatus(option) {
        this.set('validatorStatus', option.value);
        this.send('loadData');
      },

      loadData() {
        if (this.page <= 0 || this.total <= 0) {
          this.set('page', 1);
        }

        if (this.total < 0) {
          this.set('total', 0);
        }

        this._loadUserValidations.perform();
      }

    }
  });

  _exports.default = _default;
});