define("portal/routes/bookings/show/comms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel(model) {
      let controller = this.controllerFor('bookings/show/comms');
      this.store.query('comm-log', {
        sender_id: model.get('renter.id'),
        receiver_id: model.get('owner.id')
      }).then(results => {
        controller.set('commsToOwner', results);
      });
      this.store.query('comm-log', {
        sender_id: model.get('owner.id'),
        receiver_id: model.get('renter.id')
      }).then(results => {
        controller.set('commsToRenter', results);
      });
    }

  });

  _exports.default = _default;
});