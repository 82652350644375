define("portal/components/mobile-pane/infinite-scroller/child", ["exports", "ember-mobile-pane/components/mobile-pane/infinite-scroller/child"], function (_exports, _child) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _child.default;
    }
  });
});