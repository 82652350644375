define("portal/models/incomplete-booking", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    renterName: attr('string'),
    renterEmail: attr('string'),
    renterPhone: attr('string'),
    renterId: attr('string'),
    ownerName: attr('string'),
    ownerEmail: attr('string'),
    ownerPhone: attr('string'),
    ownerId: attr('string'),
    rentalName: attr('string'),
    rentalState: attr('string'),
    rentalCity: attr('string'),
    renterApproved: attr('boolean'),
    ownerApproved: attr('boolean'),
    rentalId: attr('string'),
    messageCount: attr('number'),
    notesCount: attr('number'),
    bookingStatus: attr('string'),
    bookingCreated: attr('simpleDate'),
    bookingFrom: attr('simpleDate'),
    bookingTo: attr('simpleDate'),
    conversation: belongsTo('conversation', {
      inverse: null
    })
  });

  _exports.default = _default;
});