define("portal/utils/date-of-birth", ["exports", "@outdoorsyco/ember-shared-data/utils/date-of-birth"], function (_exports, _dateOfBirth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_dateOfBirth).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _dateOfBirth[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _dateOfBirth[key];
      }
    });
  });
});