define("portal/controllers/bookings/show/drivers", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    prompt: Ember.inject.service(),
    session: Ember.inject.service(),
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    features: Ember.inject.service(),
    selectedDriver: null,
    booking: Ember.computed.readOnly('model'),
    driver: null,
    isDriverTagUpdatesEnabled: Ember.computed('features', function () {
      return this.features.isEnabled('driverTagUpdates');
    }),
    bookingIsAddCheckEligible: Ember.computed('booking.status', function () {
      return !['handed_off', 'returned', 'canceled', 'expired'].includes(this.booking.status);
    }),
    hasPendingTintChecksForDrivers: Ember.computed('booking.drivers.[]', function () {
      let hasPendingTintChecks = false;
      let drivers = Ember.get(this, 'booking.drivers') || [];
      drivers.forEach(d => {
        let checks = d.checks || [];
        checks.forEach(ch => {
          if (ch.method === 'tint' && ch.status === 'pending') {
            hasPendingTintChecks = true;
            return;
          }
        });
      });
      return hasPendingTintChecks;
    }),
    runPendingTintChecks: (0, _emberConcurrency.task)(function* () {
      yield this.adminRequest.post(`admin/enqueue/runPendingTintChecksForBooking`, {
        data: JSON.stringify({
          booking_id: Ember.get(this, 'booking.id')
        })
      });
      let i = 0;
      let ok = false;

      while (!ok) {
        if (i > 5) {
          ok = true;
          this.flashMessages.success('pending tint checks queued to run.');
          break;
        }

        yield Ember.get(this, 'booking.drivers').reload();

        if (this.hasPendingTintChecksForDrivers === false) {
          ok = true;
          break;
        }

        i++;
        yield (0, _emberConcurrency.timeout)(1500);
      }
    }),
    addCheck: (0, _emberConcurrency.task)(function* (method, driver) {
      try {
        yield this.adminRequest.post('/admin/bookings/add-check', {
          data: JSON.stringify({
            method,
            user_id: Number(driver.id),
            booking_id: Number(this.booking.id)
          })
        });
        this.flashMessages.success(`${Ember.String.capitalize(method)} check added for ${driver.firstName} ${driver.lastName}`);
      } catch (error) {
        this.logger.error(error);
        this.flashMessages.danger('Error adding check');
      }

      yield this.reloadDrivers.perform();
    }),
    switchIdVerify: (0, _emberConcurrency.task)(function* (newMethod, driver) {
      let info = `Are you sure you want to switch id verification to ${newMethod}? This will delete the current validation and create a new one. Reach out to cs-eng if you questions.`;

      if (!confirm(info)) {
        return;
      }

      let check;

      if (newMethod === 'persona') {
        check = driver.get('hasEvidentCheck.firstObject');
      } else if (newMethod === 'evident') {
        check = driver.get('hasPersonaCheck.firstObject');
      }

      if (!check) {
        this.flashMessages.danger('Unable to find id validation');
        return;
      }

      let valId = Ember.get(check, 'validation_id');

      if (!valId) {
        this.flashMessages.danger('No validation id found');
        return;
      }

      try {
        // we expect one active id verification, so we delete the existing and create the other
        yield this.adminRequest.delete(`admin/validations/${valId}`);
        yield this.addCheck.perform(newMethod, driver);
      } catch (error) {
        let msg = `There was an error switching to ${newMethod}`;

        if (error && error.payload) {
          msg = `${error.payload.error}`;

          if (error.payload.original_error) {
            msg += `- ${error.payload.original_error}`;
          }
        }

        this.flashMessages.danger(msg);
      }
    }),
    reloadDrivers: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.booking.drivers.reload(); // if driver has no more checks, hide the list

        if (Ember.get(this, 'selectedDriver.checks.length') === 0) {
          Ember.set(this, 'selectedDriver', null);
        }
      } catch (error) {
        this.logger.error(error);
        this.flashMessages.danger('Error reloading drivers');
      }
    }),
    deleteDriver: (0, _emberConcurrency.task)(function* (passenger) {
      if (confirm('Are you sure you want to delete this passenger? Deleting the passenger does not delete the associated driver checks.')) {
        try {
          yield this.adminRequest.delete(`/bookings/${this.booking.id}/users/${passenger.id}`);
          this.reloadDrivers.perform();
        } catch (err) {
          this.flashMessages.danger('There was a problem deleting the passenger');
        }
      }
    }),
    hardFailDriver: (0, _emberConcurrency.task)(function* (passenger) {
      if (!this.actionReason) {
        this.flashMessages.danger('Add a reason to continue');
        return;
      }

      try {
        yield this.adminRequest.post(`/admin/bookings/${this.booking.id}/hard-fail/${passenger.id}`, {
          data: JSON.stringify({
            reason: this.actionReason
          })
        });
        this.reloadDrivers.perform();
        Ember.set(this, 'hardFailModal', false);
      } catch (err) {
        this.flashMessages.danger(`There was a problem hard failing the driver's checks`);
      }
    }),
    acceptedDrivers: Ember.computed('booking.drivers.[]', function () {
      return this.booking.drivers.filter(d => {
        return !d.legacyDriver;
      }).map(d => {
        return {
          name: d.fullName,
          value: d.id
        };
      });
    }),
    actions: {
      async updateBirthdate(driver) {
        await this.prompt.show('driver-missing-fields', {
          driver: driver,
          booking: this.booking
        }, {
          minWidth: 36
        });
      },

      async addTintCheckForRenter() {
        const renter = await this.booking.renter;
        this.addCheck.perform('tint', renter);
      },

      showDriver(driver) {
        if (this.selectedDriver === driver) {
          driver = null;
        }

        Ember.set(this, 'selectedDriver', driver);
      },

      async addPassenger() {
        await this.prompt.show('prompt/add-passenger', {
          booking: this.booking,
          location: 'admin-portal/booking'
        }, {
          minWidth: 22,
          maxWidth: 26
        });
        this.reloadDrivers.perform();
      },

      hardFail(driver) {
        Ember.set(this, 'actionReason', '');
        Ember.set(this, 'driver', driver);
        Ember.set(this, 'hardFailModal', true);
      }

    }
  });

  _exports.default = _default;
});