define("portal/adapters/bundle", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType() {
      return 'bundles';
    },

    buildURL(type, id, snapshot, requestType, query) {
      let url = this._super(type, id, snapshot, requestType, query);

      if (requestType === 'query' && query.owner_id) {
        url = url.replace('/bundles', `/users/${query.owner_id}/bundles`);
        delete query.owner_id;
      }

      return url;
    }

  });

  _exports.default = _default;
});