define("portal/models/activity", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  const {
    PromiseObject
  } = _emberData.default;

  var _default = Model.extend({
    receiver: belongsTo('user', {
      async: true
    }),
    actor: belongsTo('user', {
      async: true
    }),
    booking: belongsTo('booking', {
      async: true
    }),
    rental: belongsTo('rental', {
      async: true
    }),
    type: attr('string'),
    read: attr('boolean'),
    actingUserId: attr('number'),
    actorSimpleId: attr('number'),
    actorFirstName: attr('string'),
    actorAvatarUrl: attr('string'),
    actorInfo: attr('meta'),
    actorLastName: attr('string'),
    bookingFrom: attr('simpleDate'),
    bookingTo: attr('simpleDate'),
    bookingSimpleId: attr('number'),
    meta: attr('meta'),
    created: attr('moment-date'),
    timestamp: attr('date'),
    hasActingUser: Ember.computed('actingUserId', function () {
      let actingUserId = this.get('actingUserId');
      let userId = this.get('actorSimpleId');
      return actingUserId !== 0 && actingUserId !== userId;
    }),
    actingUser: Ember.computed('actingUserId', function () {
      if (this.get('hasActingUser')) {
        return PromiseObject.create({
          promise: this.get('store').findRecord('user', this.get('actingUserId')).then(data => {
            return {
              id: data.get('id'),
              firstName: data.get('firstName'),
              lastName: data.get('lastName')
            };
          })
        });
      }

      return {
        id: this.get('actorSimpleId'),
        firstName: this.get('actorFirstName'),
        lastName: this.get('actorLastName')
      };
    }),
    friendlyType: Ember.computed('type', function () {
      switch (this.get('type')) {
        case 'booking_new':
          return 'New reservation request';

        case 'message_new':
          return 'New message';

        case 'booking_status_changed':
          return 'Reservation status changed';

        case 'booking_renter_accepted':
          return 'Renter completed reservation';

        case 'booking_security_deposit_charge_failed':
          return 'Security deposit charge failed';

        case 'booking_automatic_payment_failed':
          return 'Reservation charge failed';

        case 'review_new':
          return 'New review received';

        default:
          return 'New Activity';
      }
    })
  });

  _exports.default = _default;
});