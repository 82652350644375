define("portal/adapters/rental", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType() {
      return 'rentals';
    },

    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (requestType === 'findRecord') {
        let url = this._buildURL(modelName, id);

        let params = [];

        if (snapshot.adapterOptions) {
          let keys = Object.keys(snapshot.adapterOptions);
          keys.forEach(k => {
            params.push(`${k}=${snapshot.adapterOptions[k]}`);
          });
        }

        if (params.length) {
          return `${url}?${params.join('&')}`;
        }

        return url;
      }

      return this._super(modelName, id, snapshot, requestType, query);
    },

    urlForQuery(query, modelName) {
      let url = this._super(query, modelName);

      if (query.from && query.to) {
        url = url.replace('/rentals', '/rentals/available-for-dates');
      }

      return url;
    },

    updateRecord(store, type, snapshot) {
      var _changedAttrs$petFrie;

      const changedAttrs = snapshot.changedAttributes();

      if (((_changedAttrs$petFrie = changedAttrs.petFriendly) === null || _changedAttrs$petFrie === void 0 ? void 0 : _changedAttrs$petFrie[0]) === true && Object.keys(changedAttrs).length > 1) {
        Ember.getOwner(this).lookup('logger:outdoorsy').debug('maybe wiping data', changedAttrs);
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});