define("portal/routes/users/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    resetController(controller, isExiting) {
      if (isExiting) {
        return controller.setProperties({
          complete: false,
          error: null,
          street: null,
          city: null,
          state: null,
          zip: null,
          lat: null,
          lng: null,
          slug: null,
          businessName: null,
          firstName: null,
          lastName: null,
          userId: null,
          token: null
        });
      }
    }

  });

  _exports.default = _default;
});