define("portal/styles/insurance-plans", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "iplink": "iplink",
    "selected": "selected",
    "insurance-plans": "insurance-plans",
    "dot": "dot",
    "override": "override"
  };
  _exports.default = _default;
});