define("portal/components/new-affiliate/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    affiliate: null,
    flashMessages: Ember.inject.service(),
    actions: {
      saveChanges() {
        this.get('affiliate').save().then(() => {
          if (this.get('onClose')) {
            this.get('onClose')();
          }
        }).catch(() => {
          this.get('flashMessages').danger('There was an issue saving your changes');
        });
      }

    }
  });

  _exports.default = _default;
});