define("portal/mixins/social-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    facebookLike(options) {
      const actionProperties = {
        object: options.link
      };

      if (options.image) {
        actionProperties['image'] = options.image;
      }

      return FB.ui({
        method: 'share_open_graph',
        action_type: 'og.Likes',
        action_properties: JSON.stringify(actionProperties)
      }, () => {
        /* success */
      });
    },

    facebookShare(options) {
      return FB.ui({
        method: 'share',
        href: options.link
      });
    }

  });

  _exports.default = _default;
});