define("portal/routes/bookings/show/gl-impact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class GLImpactRoute extends Ember.Route {
    async model() {
      const booking = super.model(...arguments);
      return booking;
    }

    async afterModel() {
      let controller = this.controllerFor('bookings/show/gl-impact');
      Ember.run.next(this, function () {
        controller.loadLedgerReport.perform();
      });
    }

  }

  _exports.default = GLImpactRoute;
});