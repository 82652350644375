define("portal/authenticators/outdoorsy", ["exports", "ember-simple-auth/authenticators/base", "portal/config/environment", "fetch"], function (_exports, _base, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const host = _environment.default.APP.apiHost;
  const namespace = 'v0';

  var _default = _base.default.extend({
    session: Ember.inject.service(),
    cookies: Ember.inject.service(),

    restore(data) {
      return new Ember.RSVP.Promise((resolve
      /*, reject*/
      ) => {
        resolve(data);
      });
    },

    /**
     * Outdoorsy Authenticate method
     * @param  {String}   email         User Email
     * @param  {String}   password      User Password
     * @param  {Boolean}  dealerUser    Is a Dealer User?
     * @param  {Integer}  user_id       (url-login) User ID
     * @param  {String}   token         (url-login) Session token
     * @param  {Object}   options       I.e. guest
     * @return {Promise}                Session data
     */
    authenticate(email, password, dealerUser, userId, token, options) {
      if (email && password) {
        return new Ember.RSVP.Promise((resolve, reject) => {
          (0, _fetch.default)(`${host}/${namespace}/auth/creds/login`, {
            method: 'POST',
            body: JSON.stringify({
              email,
              password,
              dealer_user: dealerUser
            }),
            headers: this.get('headers')
          }).then(response => {
            Ember.run(() => {
              if (!response.ok) {
                response.json().then(error => {
                  reject(error.error);
                });
              } else {
                response.json().then(loginData => {
                  if (loginData && loginData.show_2fa_screen === true) {
                    // for email login
                    // when 2fa is required most requests will return an error
                    // create a 2fa-session cookie and redirect to the 2fa flow
                    this.cookies.write('2fa-session', JSON.stringify(loginData), {
                      path: '/',
                      maxAge: 2147483647,
                      raw: true
                    });
                    window.location.href = '/security';
                    return;
                  } else {
                    resolve(loginData);
                  }
                });
              }
            });
          }).catch(error => {
            reject(error);
          });
        });
      } else if (userId && token) {
        return new Ember.RSVP.Promise((resolve
        /*, reject*/
        ) => {
          if (options && options.guest) {
            token = `guest:${token}`;
          }

          Ember.run(() => {
            resolve({
              user_id: userId,
              token
            });
          });
        });
      }

      return Ember.RSVP.reject();
    },

    invalidate(data) {
      return new Ember.RSVP.Promise((resolve
      /*, reject*/
      ) => {
        resolve(data);
      });
    },

    headers: Ember.computed('session.authKey', function () {
      let headers = {
        'content-type': 'application/json; charset=utf-8'
      };
      const authKey = this.get('session.authKey');

      if (authKey) {
        headers['Authorization'] = `token=${authKey}`;
      }
      /**
       * Adds header from mobile bouncer. See <Bouncer::MobileDiscount /> from
       * outdoorsy-search.
       */


      if (window && window.localStorage) {
        let applyCredit = window.localStorage.getItem('mobile-apply-credit');

        if (applyCredit) {
          headers['X-Apply-Credit'] = applyCredit;
        }
      }

      return headers;
    })
  });

  _exports.default = _default;
});