define("portal/routes/discount-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  This is a makeshift solution so we can re-use the @outdoorsyco/ember-shared-data template, but not use the showModal approach.
  search and dashboard use the modal-dialog addon while admin-portal and wheelbase use a custom modal-container
  dashboard and wheelbase support showModal using outlets where admin-portal does not
  
    Top level route/controller: /discount-codes handles actions
    Index level route/controller: /discount-codes.index (from @outdoorsyco/ember-shared-data) handles rendering
  */
  var _default = Ember.Route.extend({
    rentalCategories: Ember.computed(function () {
      return ['rv', 'stay'];
    }),
    actions: {
      newDiscountCode() {
        let code = this.store.createRecord('discountCode', {
          applyToField: 'service_fee'
        });
        this.controller.set('editDiscountCode', code);
      },

      editDiscountCode(code) {
        this.controller.set('editDiscountCode', code);
      },

      closeModal() {
        this.controller.set('editDiscountCode', null);
      }

    }
  });

  _exports.default = _default;
});