define("portal/controllers/bookings/show/edit", ["exports", "portal/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    apiError: Ember.inject.service(),
    bookingService: Ember.inject.service('booking'),
    booking: Ember.computed.alias('model'),

    get isProduction() {
      return window.location.host === 'portal.outdoorsy.com';
    },

    get penaltyPrice() {
      const owner = this.store.peekRecord('user', this.get('booking.ownerSummary.id'));

      if (!owner) {
        return 0;
      }

      return this.bookingService.calculatePenaltyPrice(this.booking, owner.bookingRestrictions);
    },

    get remainingInstabookCancellations() {
      const owner = this.store.peekRecord('user', this.get('booking.ownerSummary.id'));

      if (!owner) {
        return 0;
      }

      return this.bookingService.calculateRemainingInstabookCancellations(owner.bookingRestrictions);
    },

    availableStatuses: Ember.computed('booking.status', function () {
      // allow status changes in dev and staging
      if (!this.isProduction) {
        return ['negotiating', 'approved', 'imminent', 'handed_off', 'returned'];
      } // you can do nothing in these initial states


      if (['conversation', 'negotiating', 'draft'].includes(this.get('booking.status'))) {
        return [];
      } // the only valid status from expired, declined, withdrew is go back to negotiating


      if (['owner_declined', 'renter_withdrew', 'expired'].includes(this.get('booking.status'))) {
        return ['negotiating'];
      } // if vehicle got into returned state, only allow it back to handed_off


      if (this.get('booking.status') === 'returned') {
        return ['handed_off'];
      }

      let list = ['approved', 'imminent', 'handed_off'];

      if (this.get('booking.isHandedOffOrStarted')) {
        list.push('returned');
      }

      return list;
    }),

    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    availableServices: [],
    availableBundles: [],
    refundAsCredits: false,
    waivePenalties: false,
    comment: '',
    dailyOptions: [{
      display: 'Charged Per Day',
      value: true
    }, {
      display: 'One Time Charge',
      value: false
    }],
    insuranceOptions: [{
      display: '-- Select --',
      value: undefined
    }, {
      display: 'Get Insurance',
      value: 'normal'
    }, {
      display: 'Reject Insurance',
      value: 'waived'
    }, {
      display: 'Delivery Only',
      value: 'delivery'
    }],

    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */
    allowEditSecurityDeposit: Ember.computed('booking.{isCampsite,securityDepositStatus}', 'session.user.adminLevel', function () {
      const hasNotPaid = this.get('booking.securityDepositStatus') === 'unpaid';
      const isReleased = this.get('booking.securityDepositStatus') === 'released';
      const isWaived = this.get('booking.securityDepositStatus') === 'waived';
      const isUpperAdmin = this.get('session.user.adminLevel') > 0;
      const isCampsite = this.get('booking.isCampsite');
      return (hasNotPaid || isWaived || isReleased) && isUpperAdmin && !isCampsite;
    }),
    allowChangeToOwnerCancelation: Ember.computed('booking.{totalPaid,status}', 'session.user.adminLevel', function () {
      const hasPayment = this.get('booking.totalPaid') > 0;
      const isUpperAdmin = this.get('session.user.adminLevel') > 0;
      const isNotOwnerCancelled = this.get('booking.status') !== 'owner_cancelled';
      return hasPayment && isUpperAdmin && isNotOwnerCancelled;
    }),
    allowRenterCancelation: Ember.computed('booking.status', 'session.user.adminLevel', function () {
      const isUpperAdmin = this.get('session.user.adminLevel') > 0;
      const validStatus = ['approved', 'imminent', 'handed_off'].indexOf(this.get('booking.status')) !== -1;
      return isUpperAdmin && validStatus;
    }),
    datesChanged: Ember.computed('model.{from,to,hasDirtyAttributes}', function () {
      let changes = this.model.changedAttributes();
      return changes.from || changes.to;
    }),
    eligibleForBundles: Ember.computed('availableBundles.[]', 'model.rental.insuranceEligible', function () {
      return this.get('availableBundles.length') && this.get('model.rental.insuranceEligible');
    }),
    currentBundle: Ember.computed('model.premiumServices.[]', function () {
      return this.get('model.premiumServices').find(s => s.get('serviceId') === 0 && s.get('bundleId'));
    }),
    qualifiedServices: Ember.computed('model.premiumServices.[]', 'availableServices.[]', function () {
      return this.get('availableServices').filterBy('qualified', true);
    }),
    serviceOptions: Ember.computed('qualifiedServices.[]', 'model.premiumServices.[]', function () {
      let list = [];
      let qualified = this.get('qualifiedServices');
      let existing = this.get('model.premiumServices');

      for (let i = 0; i < qualified.length; i++) {
        let s = existing.findBy('serviceId', Number(qualified[i].get('id')));

        if (Ember.isBlank(s)) {
          list.push({
            display: qualified[i].get('name'),
            value: qualified[i].get('id')
          });
        }
      }

      return list;
    }),
    params: Ember.computed('model.owner', function () {
      return {
        owner_id: this.get('model.owner.id')
      };
    }),
    actions: {
      async refundBooking(refundAsCredits) {
        try {
          await this.adminRequest.patch(`admin/bookings/${this.model.id}/confirm-cancellation`, {
            data: {
              as_credits: refundAsCredits
            }
          });
          await this.model.reload();
          this.flashMessages.success('Refund successful!');
        } catch (e) {
          this.logger.error(e);
          this.flashMessages.danger('There was an issue refunding this booking...');
        }
      },

      editBundle(bundle, close) {
        let current = bundle ? (this.get('availableBundles') || []).findBy('id', bundle.get('bundleId')) : null;
        this.set('newBundle', current);
        this.set('changeBundle', close);
      },

      updateBundle() {
        this.get('_saveBundle').perform(this.get('newBundle'));
      },

      removeBundle() {
        this.get('_saveBundle').perform(null);
      },

      updateStatus() {
        let newStatusReason = (this.get('newStatusReason') || '').trim();

        if (newStatusReason === '' || newStatusReason.length < 15) {
          alert('A reason for manually switching status is required (15 char minimum)');
          return;
        }

        if (this.get('newStatus') && newStatusReason) {
          this.set('savingStatus', true);
          this.get('adminRequest').patch(`admin/bookings/${this.model.get('id')}/revert`, {
            data: JSON.stringify({
              status: this.get('newStatus'),
              reason: this.get('newStatusReason')
            })
          }).then(() => {
            this.model.reload();
          }).catch(e => {
            this.logger.error(e);
            this.apiError.danger(e, 'status could not be updated');
          }).finally(() => {
            this.setProperties({
              savingStatus: false,
              newStatus: null,
              newStatusReason: null
            });
          });
        }
      },

      changeStatus(status) {
        this.set('newStatus', status);
      },

      changeToOwnerCancellation() {
        const bookingId = Number(this.get('booking.id'));
        this.setProperties({
          loading: true,
          cancelError: null
        });
        this.get('adminRequest').patch(`/bookings/${bookingId}/status`, {
          data: JSON.stringify({
            status: 'owner_cancelled',
            as_credits: this.refundAsCredits,
            cancel_reason: 'Owner Cancellation performed by Outdoorsy Support',
            comment: this.comment,
            waive_penalties: this.waivePenalties
          })
        }).then(() => {
          this.set('ownerCancelByAdmin', false);
          this.model.reload();
        }).catch(err => {
          let error = 'There was an error changing this booking to an owner cancellation';

          if (err && err.payload && err.payload.error) {
            error = `${error}, ${err.payload.error}`;
          }

          this.set('cancelError', error);
        }).finally(() => {
          this.set('loading', false);
        });
      },

      changeToRenterCancellation() {
        const bookingId = Number(this.get('booking.id'));
        const renterId = Number(this.get('booking.renter.id'));
        this.setProperties({
          loading: true,
          cancelError: null
        });
        this.get('adminRequest').patch(`bookings/${bookingId}/status?user_id=${renterId}`, {
          data: JSON.stringify({
            as_credits: this.refundAsCredits,
            status: 'renter_cancelled'
            /* This issues a direct transfer/payout with no fees */
            ,
            cancel_reason: 'Renter Cancellation performed by Outdoorsy Support',
            comment: this.comment
          })
        }).then(() => {
          this.set('renterCancelByAdmin', false);
          this.model.reload();
        }).catch(err => {
          let error = 'There was an error changing this booking to a renter cancellation';

          if (err && err.payload && err.payload.error) {
            error = `${error}, ${err.payload.error}`;
          }

          this.set('cancelError', error);
        }).finally(() => {
          this.set('loading', false);
        });
      },

      cancelRoadside() {
        let roadsideService = this.get('model.roadsideService');

        if (roadsideService) {
          this.set('cancelingRoadside', true);
          roadsideService.destroyRecord().then(() => {
            this.model.reload();
          }).catch(response => {
            let error = 'Bad news bud, it kicked backs';

            if (response && response.errors && response.errors.length) {
              error = `${response.errors[0].title} - ${response.errors[0].detail}`;
            }

            this.get('flashMessages').danger(error);
          }).finally(() => {
            this.set('cancelingRoadside', false);
          });
        }
      },

      cancelTripInsurance() {
        let tripService = this.get('model.tripInsuranceService');

        if (tripService) {
          this.set('cancelingService', true);
          tripService.destroyRecord().then(() => {
            this.model.reload();
          }).catch(response => {
            let error = 'Bad news bud, it kicked backs';

            if (response && response.errors && response.errors.length) {
              error = `${response.errors[0].title} - ${response.errors[0].detail}`;
            }

            this.get('flashMessages').danger(error);
          }).finally(() => {
            this.set('cancelingService', false);
          });
        }
      },

      keepPrices() {
        this.setProperties({
          recalculateError: null,
          keepPricesLoading: true
        });
        this.model.save().then(() => {
          this.setProperties({
            rentalChanged: false,
            keepPricesLoading: false
          });
        }).catch(response => {
          let error = 'There was an error saving your changes';

          if (response && response.errors && response.errors.length) {
            error = `${response.errors[0].title} ${response.errors[0].detail}`;
          }

          this.setProperties({
            recalculateError: error,
            keepPricesLoading: false
          });
        });
      },

      updatePrices() {
        this.setProperties({
          recalculateError: null,
          updatePricesLoading: true
        });
        this.model.set('recalculate', true);
        this.model.save().then(() => {
          this.setProperties({
            updatePricesLoading: false,
            rentalChanged: false
          });
        }).catch(response => {
          let error = 'There was an error saving your changes';

          if (response && response.errors && response.errors.length) {
            error = `${response.errors[0].title} ${response.errors[0].detail}`;
          }

          this.setProperties({
            recalculateError: error,
            updatePricesLoading: false
          });
        });
      },

      changePlan(plan) {
        this.model.set('insurancePlan', plan);
      },

      updateRentalId() {
        if (this.get('newRentalId')) {
          this.setProperties({
            loadingRental: true,
            rentalError: false
          });
          this.store.findRecord('rental', this.get('newRentalId')).then(rental => {
            if (rental.get('owner.id') !== this.model.get('owner.id')) {
              this.set('rentalError', 'The new rental must be owned by the same user');
              return;
            }

            this.model.set('rental', rental);
            this.model.save().then(() => {
              this.set('loadingRental', false);
            }).catch(() => {
              this.setProperties({
                loadingRental: false,
                rentalError: 'Your new rental could not be saved'
              });
            });
          }).catch(() => {
            this.setProperties({
              loadingRental: false,
              rentalError: 'That rental could not be found for these dates'
            });
          });
        }
      },

      newLineItem(defer) {
        let l = this.store.createRecord('line-item', {
          booking: this.model,
          count: 1,
          description: '',
          price: 0,
          daily: false,
          deferred: defer
        });
        this.model.get('lineItems').addObject(l);
      },

      removeLineItem(m) {
        this.set('reloadingPrice', true);
        m.destroyRecord().then(() => {
          this.model.reload().then(() => {
            this.set('reloadingPrice', false);
          });
        });
      },

      showTaxRateItem(item) {
        let currentItem = this.get('showItemTaxRates');

        if (currentItem && item === currentItem) {
          item = null;
        }

        this.set('showItemTaxRates', item);
      },

      addPremiumService(option) {
        let serviceId = parseInt(option.value, 10);
        let url = `${_environment.default.APP.apiHost}/v0/bookings/${this.get('model.id')}/services/${serviceId}`;
        this.get('adminRequest').post(url).then(() => {
          this.get('model').reload();
        }).catch(e => {
          this.logger.error(e);
          this.apiError.danger(e, 'ui-error-saving');
        });
      },

      removeService(m) {
        this.set('reloadingPrice', true);
        m.destroyRecord().then(() => {
          this.model.reload().then(() => {
            this.set('reloadingPrice', false);
          });
        });
      },

      async saveService(service) {
        if (!service) {
          return;
        }

        let url = `${_environment.default.APP.apiHost}/v0/booking_services/${service.id}`;
        let data = {};

        if (service.percentType !== '') {
          data.percent = service.percent;
        } else {
          let price = parseInt((service.price * 100).toFixed(0));
          data.price = price;
        }

        try {
          await this.adminRequest.patch(url, {
            data: data
          });
          await this.model.reload();
          service.toggleProperty('editing');
        } catch (e) {
          this.logger.error(e);
          this.apiError.danger(e, 'ui-error-saving');
        }
      },

      saveBooking() {
        this.set('loading', true);
        let promises = [];
        this.model.get('lineItems').forEach(m => {
          if ((m.get('hasDirtyAttributes') || m.get('isNew')) && m.get('name')) {
            return promises.push(m.save());
          }
        });
        Ember.RSVP.allSettled(promises).then(() => {
          this.set('loading', false);
          this.model.save().then(() => {
            return true;
          }).catch(response => {
            let msg = 'there was an error saving your booking';

            if (response && response.errors && response.errors.length) {
              msg = `${response.errors[0].title} ${response.errors[0].detail}`;
            }

            this.set('error', msg);
          });
        });
      }

    },
    _saveBundle: (0, _emberConcurrency.task)(function* (newBundle) {
      yield this.get('adminRequest').post(`/bookings/${this.get('model.id')}/bundles`, {
        data: {
          bundle_id: newBundle ? newBundle.id : null
        }
      });
      yield this.get('model').reload();
      this.setProperties({
        newBundle: null,
        changeBundle: false
      });
    })
  });

  _exports.default = _default;
});