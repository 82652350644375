define("portal/components/survey-show-long-answer", ["exports", "@outdoorsyco/ember-shared-data/components/survey-show-long-answer"], function (_exports, _surveyShowLongAnswer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _surveyShowLongAnswer.default;
    }
  });
});