define("portal/services/pricing-request", ["exports", "portal/config/environment", "ember-ajax/services/ajax"], function (_exports, _environment, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    host: _environment.default.APP.javaHost,
    session: Ember.inject.service(),
    headers: Ember.computed({
      get() {
        let headers = {
          'content-type': 'application/json; charset=utf-8'
        };
        let accessToken = this.get('session.authKey');

        if (Ember.isPresent(accessToken)) {
          headers['Authorization'] = `Token=${accessToken.replace('guest:', '')}`;
        }

        return headers;
      }

    }).volatile()
  });

  _exports.default = _default;
});