define("portal/utils/years", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = years;

  function years(highYear, lowYear) {
    let results = [];

    for (let i = highYear; i >= lowYear; i--) {
      results.push(i);
    }

    return results;
  }
});