define("portal/adapters/default-selections", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType() {
      this._super(...arguments);

      return `bookings`;
    },

    buildURL() {
      let url = this._super(...arguments);

      return `${url}/apply-selections`;
    }

  });

  _exports.default = _default;
});