define("portal/serializers/booking", ["exports", "@outdoorsyco/ember-shared-data/serializers/booking"], function (_exports, _booking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class _default extends _booking.default {
    constructor() {
      super(...arguments);
      this.attrs = _objectSpread(_objectSpread({}, this.attrs), {}, {
        partner: {
          deserialize: 'records',
          serialize: false
        },
        partnerApp: {
          deserialize: 'records',
          serialize: false
        },
        // don't send addons back until we're ready
        // this causes issues when booking items are modified outside
        // of the PATCH /bookings/:id request which is the norm today
        addons: {
          deserialize: 'records',
          serialize: false
        }
      });
    }

    normalize(typeClass, hash) {
      if (hash.partner_app && hash.partner_app.partner) {
        hash.partner = hash.partner_app.partner;
      }

      return super.normalize(typeClass, hash);
    }

  }

  _exports.default = _default;
});