define("portal/models/tag", ["exports", "@outdoorsyco/ember-shared-data/models/tag"], function (_exports, _tag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _tag.default;
    }
  });
});