define("portal/controllers/users/show/activity", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    page: 1,
    perPage: 20,
    total: 0,
    loadActivities: (0, _emberConcurrency.task)(function* () {
      let limit = 20;
      let params = {
        user_id: this.get('model.id'),
        user_activity_only: true,
        offset: (this.get('page') - 1) * limit,
        limit: limit
      };
      yield this.store.query('activity', params).then(activities => {
        this.set('activities', activities.toArray());
        this.set('total', activities.get('meta.unread'));
      }).catch(err => {
        this.flashMessages.danger(`We failed to load activity ${err}`);
      });
    }),
    actions: {
      setParam(param, value) {
        this.set('page', 1);
        this.set(param, value);
        this.loadActivities.perform();
      },

      setPage(page) {
        this.set('page', page);
        this.loadActivities.perform();
      }

    }
  });

  _exports.default = _default;
});