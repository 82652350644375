define("portal/templates/users/show/activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E0i22nNn",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"User Activity\"],null],false],[0,\"\\n\"],[7,\"div\",true],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pull-right\"],[8],[0,\"\\n    \"],[1,[28,\"pagination-component\",null,[[\"current\",\"total\",\"perPage\",\"oneWay\",\"setPage\"],[[24,[\"page\"]],[24,[\"total\"]],[24,[\"perPage\"]],true,[28,\"action\",[[23,0,[]],\"setPage\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"activity-table\",null,[[\"activities\"],[[24,[\"activities\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/users/show/activity.hbs"
    }
  });

  _exports.default = _default;
});