define("portal/routes/payout-issues", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel() {
      // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
      Ember.run.scheduleOnce('afterRender', () => {
        this.controllerFor('payout-issues').send('loadData');
      });
    }

  });

  _exports.default = _default;
});