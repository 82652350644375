define("portal/helpers/image-formatter", ["exports", "@outdoorsyco/ember-shared-data/helpers/image-formatter"], function (_exports, _imageFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _imageFormatter.default;
    }
  });
  Object.defineProperty(_exports, "imageFormatter", {
    enumerable: true,
    get: function get() {
      return _imageFormatter.imageFormatter;
    }
  });
});