define("portal/templates/bookings/show/reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8xWGLeu2",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Reviews\"],null],false],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"model\",\"isCampsite\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-8\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[8],[0,\"Owner Review for Renter\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"ownerReview\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"review-item\",null,[[\"review\",\"editReview\",\"deleteReview\"],[[24,[\"ownerReview\"]],[28,\"action\",[[23,0,[]],\"editReview\"],null],[28,\"action\",[[23,0,[]],\"deleteReview\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Pending\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"hr\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-8\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[8],[0,\"Renter Review for Owner\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"renterReview\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"review-item\",null,[[\"review\",\"editReview\",\"deleteReview\"],[[24,[\"renterReview\"]],[28,\"action\",[[23,0,[]],\"editReview\"],null],[28,\"action\",[[23,0,[]],\"deleteReview\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Pending\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"edit-review\",null,[[\"review\",\"close\"],[[24,[\"editReview\"]],[28,\"action\",[[23,0,[]],\"closeReview\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/bookings/show/reviews.hbs"
    }
  });

  _exports.default = _default;
});