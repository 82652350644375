define("portal/controllers/rentals/show/index", ["exports", "portal/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const cacheClearToken = 'thispasswordisdumbbutsecurityorsomething';

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    rental: Ember.computed.alias('model'),

    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    distanceOptions: ['miles', 'kilometers'],
    lengthOptions: ['feet', 'meters'],
    weightOptions: ['lbs', 'kg'],
    liquidOptions: ['gallons', 'liters'],

    get previewLink() {
      const host = _environment.default.APP.outdoorsyHost || 'https://www.outdoorsy.com';
      const uri = this.rental.slug;
      return `${host}${uri}`;
    },

    vehicleHideStatus: Ember.computed('rental.hidden', function () {
      return this.rental.hidden ? 'Unhide' : 'Hide';
    }),
    actions: {
      async setRentalDistanceUnit(unit) {
        this.rental.localeDistanceUnit = unit;

        if (this.get('rental.mileageUsageItem.id')) {
          let item = await this.rental.mileageUsageItem;
          item.unit = unit;
        }

        if (this.get('rental.deliveryUsageItem.id')) {
          let item = await this.rental.deliveryUsageItem;
          item.unit = unit;
        }
      },

      toggleHidden() {
        let action = this.rental.hidden ? 'show' : 'hide';
        this.adminRequest.post(`admin/rentals/${this.rental.id}/${action}`).then(data => {
          this.model.reload();
          let verb = data.rental_hidden ? 'hidden' : 'made visible';
          this.flashMessages.success(`Vehicle has been ${verb}`);
        });
      },

      undelete() {
        this.adminRequest.patch(`rentals/${this.rental.id}/undelete`).then(() => {
          this.rental.reload();
          this.flashMessages.success(`Vehicle has been undeleted`);
        });
      },

      async approveImage(image) {
        try {
          let data = image.serialize();
          data.status = 'approved';
          await this.adminRequest.patch(`rentals/${this.rental.id}/images/${image.id}`, {
            data
          });
          await this.rental.reload();
        } catch (error) {
          let msg = 'An error has occurred';

          if (error && error.payload) {
            msg = error.payload.error;
          }

          this.flashMessages.danger(msg);
        }
      },

      async saveRental() {
        try {
          if (this.wasSupportingHealthcareHeroes && !this.isSupportingHealthcareHeroes) {
            let path = `rentals/${this.rental.id}/tags/${this.healthcareHeroTag.id}`;
            await this.adminRequest.delete(path);
          } else if (!this.wasSupportingHealthcareHeroes && this.isSupportingHealthcareHeroes) {
            let path = `rentals/${this.rental.id}/tags/${this.healthcareHeroTag.id}`;
            await this.adminRequest.post(path);
          }

          await this.model.save();

          if (this.model.get('mileageUsageItem.hasDirtyAttributes')) {
            let item = await this.model.mileageUsageItem;
            await item.save();
          }

          if (this.model.get('deliveryUsageItem.hasDirtyAttributes')) {
            let item = await this.model.deliveryUsageItem;
            await item.save();
          }

          this.flashMessages.success(`Vehicle saved!`);
        } catch (e) {
          console.log(e);
          this.flashMessages.error(`An error occurred`);
        }
      },

      duplicateRental() {
        this.adminRequest.request(`rentals/${this.rental.id}/duplicate`, {
          method: 'POST'
        }).then(rental => {
          // fetch it to put it in the store.
          this.store.findRecord('rental', rental.id).then(r => {
            this.transitionToRoute('rentals.show', r);
            this.flashMessages.success(`Vehicle Duplicated Successfully`);
          });
        }).catch(() => {
          alert('Failed to duplicate rental');
        });
      },

      updateRentalPosition(lat, lng) {
        if (lat && lng) {
          this.set('rental.location.lat', lat);
          this.set('rental.location.lng', lng);
        }
      }

    },
    reIndexRental: (0, _emberConcurrency.task)(function* () {
      let searchApiUrl = _environment.default.APP.searchApi;

      try {
        yield this.apiRequest.post(`${searchApiUrl}/index/rentals/${this.rental.id}`);
        yield (0, _emberConcurrency.timeout)(5000);
        this.flashMessages.success(`You successfully kicked off the re indexing of this listing`);
      } catch (e) {
        this.flashMessages.danger(`Error: ${e}`);
      }
    }),
    _clearCache: (0, _emberConcurrency.task)(function* () {
      let url = `https://www.outdoorsy.com${this.rental.slug}`;

      try {
        yield this.apiRequest.request(`https://mvp.outdoorsy.com/api/purge?auth=${cacheClearToken}&url=${btoa(url)}`);
        this.flashMessages.success(`Successfully cleared the cache`);
      } catch (e) {
        this.flashMessages.danger(`Error: ${e}`);
      }
    })
  });

  _exports.default = _default;
});