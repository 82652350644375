define("portal/routes/partners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let promises = [this._loadAffiliates(), this._loadPartners()];
      return Ember.RSVP.all(promises).then(results => {
        return {
          affiliates: results[0],
          partners: results[1]
        };
      });
    },

    _loadAffiliates() {
      return this.store.query('affiliateNetwork', {});
    },

    _loadPartners() {
      return this.store.query('partner', {
        order_by: '-created'
      });
    },

    actions: {
      reloadPartners() {
        this._loadPartners().then(partners => {
          this.controller.set('model.partners', partners);
        });
      },

      reloadRoute() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});