define("portal/models/conversation", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/helpers/first-letter"], function (_exports, _emberData, _firstLetter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    intl: Ember.inject.service(),
    renter: belongsTo('user', {
      async: true
    }),
    owner: belongsTo('user', {
      async: true
    }),
    messages: hasMany('message', {
      async: false
    }),
    bookings: hasMany('booking', {
      async: false
    }),
    read: attr('boolean'),
    created: attr('momentDate'),
    updated: attr('momentDate'),
    nextTripFrom: attr('momentDate'),
    nextTripTo: attr('momentDate'),
    source: attr('string'),
    archived: attr('boolean'),

    /**
     * Compact object of the the owner model for quick display purposes.
     */
    ownerSummary: attr(),

    /**
     * Compact object of the the renter model for quick display purposes.
     */
    renterSummary: attr(),

    init() {
      this._super(...arguments);

      this.sortMessages = ['timestamp:desc'];
    },

    primaryBooking: Ember.computed('bookings.[]', function () {
      if (this.get('bookings')) {
        return this.get('bookings.lastObject');
      }

      return null;
    }),
    showFullRenterName: Ember.computed('bookings.[]', function () {
      let bookings = this.get('bookings');

      if (bookings) {
        return bookings.any(b => b.stage !== 'pending' && b.stage !== 'cancelled');
      }

      return false;
    }),
    sortedMessages: Ember.computed.sort('messages', 'sortMessages'),
    isOwner: Ember.computed('owner.id', 'session.{userId,ownedById}', function () {
      let userId = this.session.ownedById || this.session.userId;
      let ownerId = this.belongsTo('owner').id();
      return parseInt(ownerId) === parseInt(userId);
    }),
    tripDisplayStatus: Ember.computed('bookings.[]', function () {
      let bookings = this.get('bookings') || [];

      if (bookings.length) {
        return this.get('bookings.firstObject.friendlyStatus');
      }

      return this.get('intl').t('term-status-question');
    }),
    tripStatus: Ember.computed('bookings.[]', function () {
      let bookings = this.get('bookings') || [];

      if (bookings.length) {
        return this.get('bookings.firstObject.status');
      }

      return 'question';
    }),
    otherPerson: Ember.computed('isOwner', function () {
      return this.get('isOwner') ? this.get('renter') : this.get('owner');
    }),
    otherPersonSummary: Ember.computed('isOwner', function () {
      return this.isOwner ? this.renterSummary : this.ownerSummary;
    }),
    renterName: Ember.computed('isOwner', 'renterSummary', function () {
      if (this.isOwner && !this.showFullRenterName) {
        return `${this.renterSummary.first_name} ${(0, _firstLetter.firstLetter)(this.renterSummary.last_name)}`;
      }

      return `${this.renterSummary.first_name} ${this.renterSummary.last_name}`;
    }),
    ownerName: Ember.computed('ownerSummary', function () {
      return `${this.ownerSummary.first_name} ${this.ownerSummary.last_name}`;
    }),
    otherPersonName: Ember.computed('otherPersonSummary', function () {
      if (this.isOwner) {
        return this.renterName;
      }

      return `${this.otherPersonSummary.first_name} ${this.otherPersonSummary.last_name}`;
    }),
    friendlySource: Ember.computed('bookings.[]', function () {
      let bookings = this.get('bookings') || [];

      if (!bookings.length) {
        return 'Outdoorsy';
      }

      return this.get('bookings.firstObject.displaySource');
    }),
    friendlyStatus: Ember.computed('bookings.[]', function () {
      let intl = this.get('intl');
      let bookings = this.get('bookings') || [];

      if (!bookings.length) {
        return intl.t('term-question');
      }

      return this.get('bookings.firstObject.displayStatus');
    })
  });

  _exports.default = _default;
});