define("portal/routes/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      return Ember.run.debounce(controller, 'send', 'loadResults', 500, false);
    },

    resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        return controller.send('clearFilters');
      }
    },

    actions: {
      // if we're already here, cancel transition
      willTransition(transition) {
        if (this.get('routeName') === transition.targetName) {
          transition.abort();
          return;
        }
      }

    }
  });

  _exports.default = _default;
});