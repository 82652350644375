define("portal/styles/_jquery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-helper-hidden": "ui-helper-hidden",
    "ui-helper-hidden-accessible": "ui-helper-hidden-accessible",
    "ui-helper-reset": "ui-helper-reset",
    "ui-helper-clearfix": "ui-helper-clearfix",
    "ui-helper-zfix": "ui-helper-zfix",
    "ui-front": "ui-front",
    "ui-state-disabled": "ui-state-disabled",
    "ui-icon": "ui-icon",
    "ui-widget-overlay": "ui-widget-overlay",
    "ui-sortable-handle": "ui-sortable-handle",
    "ui-datepicker": "ui-datepicker",
    "ui-datepicker-header": "ui-datepicker-header",
    "ui-datepicker-prev": "ui-datepicker-prev",
    "ui-datepicker-next": "ui-datepicker-next",
    "ui-datepicker-prev-hover": "ui-datepicker-prev-hover",
    "ui-datepicker-next-hover": "ui-datepicker-next-hover",
    "ui-datepicker-title": "ui-datepicker-title",
    "ui-datepicker-month": "ui-datepicker-month",
    "ui-datepicker-year": "ui-datepicker-year",
    "ui-datepicker-buttonpane": "ui-datepicker-buttonpane",
    "ui-datepicker-current": "ui-datepicker-current",
    "ui-datepicker-multi": "ui-datepicker-multi",
    "ui-datepicker-group": "ui-datepicker-group",
    "ui-datepicker-multi-2": "ui-datepicker-multi-2",
    "ui-datepicker-multi-3": "ui-datepicker-multi-3",
    "ui-datepicker-multi-4": "ui-datepicker-multi-4",
    "ui-datepicker-group-last": "ui-datepicker-group-last",
    "ui-datepicker-group-middle": "ui-datepicker-group-middle",
    "ui-datepicker-row-break": "ui-datepicker-row-break",
    "ui-datepicker-rtl": "ui-datepicker-rtl"
  };
  _exports.default = _default;
});