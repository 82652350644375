define("portal/models/quote-request", ["exports", "@outdoorsyco/ember-shared-data/models/quote-request"], function (_exports, _quoteRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _quoteRequest.default;
    }
  });
});