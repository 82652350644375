define("portal/models/rental", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/models/rental"], function (_exports, _emberData, _rental) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  _rental.default.reopen({
    hidden: attr('boolean', {
      defaultValue: false
    }),
    sortScore: attr('number'),
    sortMetrics: attr(),
    sort: attr('number'),
    created: attr('simpleDate'),
    firstPublished: attr('simpleDate'),
    lastUnpublishDate: attr('simpleDate'),
    reviewStatus: attr(),
    external: attr('boolean'),
    reviewNotes: attr(),
    vinCheck: attr()
  });

  var _default = _rental.default;
  _exports.default = _default;
});