define("portal/routes/users/show/reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      Ember.set(controller, 'reviewType', 'user'); // only load rentals if we're an owner

      if (model.get('owner')) {
        Ember.set(controller, 'reviewType', 'owner'); // only load

        let rparams = {
          owner_id: model.get('id'),
          status: 'all',
          limit: 1000,
          hidden: true
        };
        this.store.query('rental', rparams).then(rentals => {
          controller.set('rentals', rentals);
        });
      }

      controller.loadReviews.perform();
    }

  });

  _exports.default = _default;
});