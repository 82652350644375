define("portal/controllers/integrations/request-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'service_id', 'booking_id', 'rental_id', 'failed'],
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    retrying: false,
    perPage: 25,
    page: 1,
    sortBy: 'id',
    sortDir: 'desc',
    service_id: null,
    booking_id: null,
    rental_id: null,
    failed: null,
    total: Ember.computed('model.meta.total', function () {
      return this.get('model.meta.total') || 0;
    }),
    serviceOptions: Ember.computed(function () {
      let options = [{
        display: '--All--',
        value: null
      }];
      this.store.peekAll('integration').forEach(e => {
        options.push({
          value: e.get('id'),
          display: e.get('name')
        });
      });
      return options;
    }),
    statusOptions: Ember.computed(function () {
      return [{
        display: '--All--',
        value: null
      }, {
        display: 'Success',
        value: false
      }, {
        display: 'Failed',
        value: true
      }];
    }),
    actions: {
      setSort(fieldName, direction) {
        this.setProperties({
          sortBy: fieldName,
          sortDir: direction,
          page: 1,
          limit: this.get('perPage')
        });
        this.send('loadRequestLogs');
      },

      setPage(page) {
        this.set('page', page);
        this.send('loadRequestLogs');
      },

      search() {
        this.send('loadRequestLogs');
      },

      loadRequestLogs() {
        let limit = this.get('perPage');
        let orderBy = this.get('sortBy');

        if (this.get('sortDir') === 'desc') {
          orderBy = `-${orderBy}`;
        }

        let params = {
          limit: limit,
          offset: (this.get('page') - 1) * limit,
          order: orderBy
        };

        if (this.get('service_id')) {
          params.service_id = this.get('service_id');
        }

        if (this.get('booking_id')) {
          params.booking_id = this.get('booking_id');
        }

        if (this.get('rental_id')) {
          params.rental_id = this.get('rental_id');
        }

        if (this.get('failed') === 'false') {
          params.failed = 'false';
        } else if (this.get('failed') === 'true') {
          params.failed = 'true';
        }

        this.store.query('serviceRequestLog', params).then(records => {
          this.set('model', records.toArray());
          this.set('total', records.get('meta.total'));
        });
      },

      retryRequest(req) {
        this.set('retrying', true);
        this.get('adminRequest').post(`integrations/request_log/${req.get('id')}/retry`).then(() => {
          this.set('retrying', false);
          req.reload();
        }).catch(resp => {
          this.get('flashMessages').danger(resp);
          this.set('retrying', false);
        });
      }

    }
  });

  _exports.default = _default;
});