define("portal/controllers/bundles/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'renter_type', 'country'],
    bundles: Ember.computed.filterBy('model', 'isNew', false),
    adminRequest: Ember.inject.service(),
    country: null,
    renter_type: null,
    renterOptions: Ember.computed(function () {
      return [{
        display: '--All--',
        value: null
      }, {
        display: 'Outdoorsy',
        value: 'outdoorsy'
      }, {
        display: 'Wheelbase',
        value: 'wheelbase'
      }];
    }),
    countryOptions: Ember.computed(function () {
      return [{
        display: '--All--',
        value: null
      }, {
        display: 'US',
        value: 'US'
      }, {
        display: 'CA',
        value: 'CA'
      }];
    }),
    countrySort: ['country:desc', 'tier:asc'],
    outdoorsyBundles: Ember.computed.filterBy('bundles', 'dealerOwnedRenter', false),
    sortedOutdoorsyBundles: Ember.computed.sort('outdoorsyBundles', 'countrySort'),
    wheelbaseBundles: Ember.computed.filterBy('bundles', 'dealerOwnedRenter', true),
    sortedWheelbaseBundles: Ember.computed.sort('wheelbaseBundles', 'countrySort'),
    filteredOutdoorsyBundles: Ember.computed('country', 'renter_type', 'sortedOutdoorsyBundles.[]', function () {
      return this.sortedOutdoorsyBundles.filter(e => {
        if (this.get('country') && e.get('country') !== this.get('country')) {
          return false;
        }

        return true;
      });
    }),
    filteredWheelbaseBundles: Ember.computed('country', 'renter_type', 'sortedWheelbaseBundles.[]', function () {
      return this.sortedWheelbaseBundles.filter(e => {
        if (this.get('country') && e.get('country') !== this.get('country')) {
          return false;
        }

        return true;
      });
    }),
    actions: {
      copyBundle(bundle) {
        this.get('adminRequest').post(`bundles/${bundle.get('id')}/duplicate`).then(() => {
          this.send('reloadBundles');
          this.get('flashMessages').success('Bundle copied successfully');
        });
      },

      reloadBundles: function reloadBundles() {
        this.store.findAll('bundle').then(response => {
          this.set('model', response);
        });
      }
    }
  });

  _exports.default = _default;
});