define("portal/components/new-affiliate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+cmLgvNU",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input-field\",null,[[\"value\",\"label\",\"placeholder\"],[[24,[\"affiliate\",\"name\"]],\"Name\",\"Affiliate / Network Name\"]]],false],[0,\"\\n\"],[1,[28,\"input-field\",null,[[\"value\",\"label\",\"placeholder\"],[[24,[\"affiliate\",\"template\"]],\"Template\",\"URL Template\"]]],false],[0,\"\\n\"],[7,\"h5\",true],[8],[0,\"Available Variables\"],[9],[0,\"\\n\"],[7,\"ul\",true],[8],[0,\"\\n  \"],[7,\"li\",true],[8],[7,\"code\",true],[8],[0,\"outdoorsy_url_encoded\"],[9],[0,\" - URL Encoded version of the final Outdoorsy link\"],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[7,\"code\",true],[8],[0,\"outdoorsy_url_unencoded\"],[9],[0,\" - Unencoded version of link, useful for linking directly to Outdoorsy\"],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"Any other variable you include will be filled based on the individual affiliates partner settings\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[28,\"loading-button\",null,[[\"buttonClass\",\"buttonText\",\"loading\",\"action\"],[\"btn btn-primary\",[28,\"t\",[\"ui-save\"],null],[24,[\"model\",\"isSaving\"]],\"saveChanges\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/components/new-affiliate/template.hbs"
    }
  });

  _exports.default = _default;
});