define("portal/routes/bookings/show/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    adminRequest: Ember.inject.service(),

    afterModel(model) {
      let controller = this.controllerFor('bookings/show/index');
      this.get('adminRequest').request(`bookings/${model.get('id')}/bundles`, {
        method: 'GET'
      }).then(bundles => {
        controller.set('availableBundles', bundles.bundles);
      });

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});