define("portal/routes/impact-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ImpactSearchRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        }
      });
    }

    model(params) {
      let page = params.page || 1;
      let limit = 100;
      let offset = (page - 1) * limit;
      return {
        page,
        limit,
        offset
      };
    }

    async afterModel(req) {
      let controller = this.controllerFor('impact-search');
      Ember.run.next(this, function () {
        controller.search.perform(req);
      });
    }

  }

  _exports.default = ImpactSearchRoute;
});