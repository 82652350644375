define("portal/controllers/bookings/show/gl-impact", ["exports", "ember-concurrency-decorators", "ember-concurrency", "portal/config/environment"], function (_exports, _emberConcurrencyDecorators, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GLImpactController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.readOnly('model'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class GLImpactController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['created_lte']);

      _initializerDefineProperty(this, "adminRequest", _descriptor, this);

      _initializerDefineProperty(this, "apiRequest", _descriptor2, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "booking", _descriptor5, this);

      _initializerDefineProperty(this, "created_lte", _descriptor6, this);

      _initializerDefineProperty(this, "showRevenueEvents", _descriptor7, this);

      _initializerDefineProperty(this, "revenueEvents", _descriptor8, this);

      _initializerDefineProperty(this, "report", _descriptor9, this);

      _initializerDefineProperty(this, "showUnprocessed", _descriptor10, this);

      _initializerDefineProperty(this, "htmlDisplay", _descriptor11, this);

      _initializerDefineProperty(this, "platformEvent", _descriptor12, this);

      _initializerDefineProperty(this, "moreOptions", _descriptor13, this);

      _initializerDefineProperty(this, "abacusEnv", _descriptor14, this);

      _initializerDefineProperty(this, "getRevenueEvents", _descriptor15, this);

      _initializerDefineProperty(this, "createRevenueEvents", _descriptor16, this);

      _initializerDefineProperty(this, "reprocessEvent", _descriptor17, this);

      _initializerDefineProperty(this, "reprocessAccountingEventsForBoooking", _descriptor18, this);

      _initializerDefineProperty(this, "viewAccountingEvent", _descriptor19, this);

      _initializerDefineProperty(this, "diffPreviousBookedEvent", _descriptor20, this);

      _initializerDefineProperty(this, "manualSendPlatformEvent", _descriptor21, this);

      _initializerDefineProperty(this, "loadLedgerReport", _descriptor22, this);

      _initializerDefineProperty(this, "downloadCsv", _descriptor23, this);
    }

    get envOptions() {
      return ['', 'production', 'staging'];
    }

    get platformEvents() {
      return ['rv.booked', 'rv.rebooked', 'rv.handed_off', 'rv.cancelled'];
    }

    get isProduction() {
      return window.location.host === 'portal.outdoorsy.com';
    }

    toggleProcessed() {
      this.showUnprocessed = !this.showUnprocessed;
    }

    toggleOptions() {
      this.moreOptions = !this.moreOptions;
    }

    abacusRequest(url, method) {
      let abacusHost = _environment.default.APP.abacusHost;
      let abacusKey = _environment.default.APP.abacusKey;

      if (this.abacusEnv === 'production') {
        abacusHost = 'https://abacus.outdoorsy.com';
      } else if (this.abacusEnv === 'staging') {
        abacusHost = 'https://abacus.staging.outdoorsy.com';
      }

      return this.apiRequest.request(`${abacusHost}${url}`, {
        method,
        headers: {
          Authorization: `Bearer ${abacusKey}`
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adminRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "apiRequest", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "booking", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "created_lte", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showRevenueEvents", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "revenueEvents", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "report", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return {};
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showUnprocessed", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "htmlDisplay", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "platformEvent", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'rv.booked';
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "moreOptions", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "abacusEnv", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleProcessed", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "toggleProcessed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleOptions", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "toggleOptions"), _class.prototype), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "getRevenueEvents", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        this.showRevenueEvents = true;
        let url = `bookings/${this.booking.id}/payment-events`;

        try {
          const response = yield this.adminRequest.request(url);
          this.revenueEvents = response;
        } catch (e) {
          let error = Ember.get(e, 'payload.error');
          this.flashMessages.danger(error);
        }
      };
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "createRevenueEvents", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        this.showRevenueEvents = true;
        let url = `bookings/${Ember.get(this, 'booking.id')}/payment-events`;

        try {
          const response = yield this.adminRequest.post(url);
          this.revenueEvents = response;
        } catch (e) {
          let error = Ember.get(e, 'payload.error');
          this.flashMessages.danger(error);
        }
      };
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "reprocessEvent", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (eventId) {
        if (typeof eventId !== 'string') {
          return;
        }

        try {
          yield this.abacusRequest(`/accounting-events/${eventId}/reprocess`, 'post');
          yield (0, _emberConcurrency.timeout)(5000);
          yield this.loadLedgerReport.perform();
        } catch (e) {
          this.flashMessages.danger(e);
        }
      };
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "reprocessAccountingEventsForBoooking", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        let bookingId = this.model.id;

        try {
          yield this.abacusRequest(`/rv_reservation/${bookingId}/reprocess`, 'post');
          yield (0, _emberConcurrency.timeout)(5000);
          yield this.loadLedgerReport.perform();
          this.flashMessages.success('Events are reprocessing. Refresh the report as needed');
        } catch (e) {
          this.flashMessages.danger(e);
        }
      };
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "viewAccountingEvent", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (eventId) {
        if (typeof eventId !== 'string') {
          return;
        }

        try {
          const data = yield this.abacusRequest(`/accounting-events/${eventId}`, 'get');
          this.showRevenueEvents = true;
          this.revenueEvents = [data];
        } catch (e) {
          this.flashMessages.danger(e);
        }
      };
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "diffPreviousBookedEvent", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (eventId) {
        try {
          const htmlString = yield this.abacusRequest(`/accounting-events/${eventId}/diff`, 'get');
          this.htmlDisplay = Ember.String.htmlSafe(htmlString);
        } catch (e) {
          this.flashMessages.danger(e);
        }
      };
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "manualSendPlatformEvent", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* () {
        let url = `bookings/${Ember.get(this, 'booking.id')}/platform-event?event=${this.platformEvent}`;

        try {
          yield this.adminRequest.post(url);
          this.flashMessages.success('Plaform event created. It can take up to a minute to process. Click `Refresh Report` to see it');
        } catch (e) {
          let error = Ember.get(e, 'payload.error');
          this.flashMessages.danger(error);
        }
      };
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "loadLedgerReport", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (event) {
        let url = `/reservations/${Ember.get(this, 'booking.id')}/gl-impact`;

        if (event && event.event_time) {
          this.created_lte = event.event_time;
          url += `?created_lte=${event.event_time}`;
        } else {
          this.created_lte = null;
        }

        this.report = yield this.abacusRequest(url, 'get');
      };
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "downloadCsv", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (type) {
        const url = `/reservations/${Ember.get(this, 'booking.id')}/gl-impact?export=${type}`;
        let csvString = yield this.abacusRequest(url, 'get');
        let id = Ember.get(this, 'booking.id');
        const blob = new Blob([csvString], {
          type: 'text/csv'
        });

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, 'filename.csv');
        } else {
          var a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(blob, {
            type: 'text/csv'
          });
          a.download = `booking-${id}-ledger.csv`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      };
    }
  })), _class));
  _exports.default = GLImpactController;
});