define("portal/templates/renter-bookings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jLut6Btm",
    "block": "{\"symbols\":[\"renter\",\"index\"],\"statements\":[[1,[28,\"page-title\",[\"Renter bookings\"],null],false],[0,\"\\n\"],[7,\"h2\",true],[8],[0,\"Renter booking requests\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"renter-bookings row\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"renters\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"booking/rental-detail\",null,[[\"renter\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mhl\"],[8],[0,\"\\n    \"],[1,[28,\"pagination-pager\",null,[[\"count\",\"current\",\"change\"],[[28,\"readonly\",[[24,[\"totalPages\"]]],null],[28,\"readonly\",[[24,[\"page\"]]],null],[28,\"action\",[[23,0,[]],\"setPage\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/renter-bookings.hbs"
    }
  });

  _exports.default = _default;
});