define("portal/templates/components/menu-button", ["exports", "@outdoorsyco/ember-switchback/components/menu-button/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _template.default;
    }
  });
});