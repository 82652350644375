define("portal/services/card-setup", ["exports", "@outdoorsyco/ember-shared-data/services/card-setup"], function (_exports, _cardSetup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _cardSetup.default;
    }
  });
});