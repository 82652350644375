define("portal/utils/states", ["exports", "@outdoorsyco/outdoorsy-addon/utils/states"], function (_exports, _states) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_states).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _states[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _states[key];
      }
    });
  });
});