define("portal/models/ts-queue-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    lastModifiedBy: belongsTo('user'),
    createdBy: belongsTo('user'),
    objectId: attr('number'),
    objectType: attr('string'),
    score: attr('number'),
    status: attr('string'),
    service: attr('string'),
    serviceLink: attr('string'),
    notes: attr('string'),
    created: attr('momentDate'),
    driverVerificationFailureReason: attr('string'),
    objectLink: Ember.computed('objectType', 'objectId', function () {
      return `/${this.objectType}s/${this.objectId}`;
    }),
    objectLinkName: Ember.computed('objectType', 'objectId', function () {
      return `${this.objectType.charAt(0).toUpperCase() + this.objectType.substr(1)} ${this.objectId}`;
    }),
    serviceLinkName: Ember.computed('service', function () {
      return `${this.service.charAt(0).toUpperCase() + this.service.substr(1)}`;
    }),
    queueLink: Ember.computed('id', function () {
      return `/trust-safety-queue?${this.objectType}_id=${this.objectId}`;
    })
  });

  _exports.default = _default;
});