define("portal/components/edit-insurance-plan/component", ["exports", "portal/components/edit-insurance-plan/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    rvTypes: [{
      display: 'Class A',
      value: 'a'
    }, {
      display: 'Class B',
      value: 'b'
    }, {
      display: 'Class C',
      value: 'c'
    }, {
      display: 'Camper Van',
      value: 'camper-van'
    }, {
      display: 'Truck Camper',
      value: 'truck-camper'
    }, {
      display: 'Trailer',
      value: 'trailer'
    }, {
      display: 'Travel Trailer',
      value: 'travel-trailer'
    }, {
      display: 'Folding Trailer',
      value: 'folding-trailer'
    }, {
      display: 'Utility Trailer',
      value: 'utility-trailer'
    }, {
      display: 'Fifth Wheel',
      value: 'fifth-wheel'
    }, {
      display: 'Park Model',
      value: 'park-model'
    }, {
      display: 'Tow Vehicle',
      value: 'tow-vehicle'
    }, {
      display: 'Toy Hauler',
      value: 'toy-hauler'
    }],
    actions: {
      cancel() {
        if (!this.get('model.isNew') && this.get('model.hasDirtyAttributes')) {
          this.get('model').rollbackAttributes();
        }

        this.sendAction('closeModal'); //eslint-disable-line ember/closure-actions
      },

      saveChanges() {
        this.set('error', ''); // if no changes, skip save

        if (!this.get('model.hasDirtyAttributes')) {
          this.sendAction('closeModal'); //eslint-disable-line ember/closure-actions

          return;
        }

        this.get('model').save().then(plan => {
          this.sendAction('planSaved', plan); //eslint-disable-line ember/closure-actions
        }).catch(res => {
          let msg = 'An error ocurred while trying to save';

          if (res && res.errors && res.errors.length) {
            msg = res.errors[0].title;
          }

          this.set('error', msg);
        });
      }

    }
  });

  _exports.default = _default;
});