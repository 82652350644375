define("portal/styles/pages/dashboard-discount-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "dashboard-account-discount-codes": "dashboard-account-discount-codes",
    "btn": "btn",
    "discount-codes-edit-btn": "discount-codes-edit-btn",
    "discount-codes-delete-btn": "discount-codes-delete-btn"
  };
  _exports.default = _default;
});