define("portal/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5mkkj8eT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-4 col-sm-offset-4\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"environment\",\"isDevelopmentOrTest\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"login-form\",null,[[\"loginSuccess\"],[[28,\"action\",[[23,0,[]],\"loginSuccess\"],null]]]],false],[0,\"\\n      \"],[5,\"hr\",[[12,\"class\",\"  margin-y-2 \"]],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"  margin-y-4 \"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n      \"],[5,\"button\",[],[[\"@href\"],[[23,0,[\"ssoLink\"]]]],{\"statements\":[[0,\"\\n        \"],[7,\"img\",true],[10,\"class\",\"  margin--x-0.5 \"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"src\",\"/@outdoorsyco/ember-shared-data/images/google-icon.svg\"],[11,\"alt\",[28,\"t\",[\"sign-in-with-google\"],null]],[8],[9],[0,\"\\n          \"],[1,[28,\"t\",[\"sign-in-with-google\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/login.hbs"
    }
  });

  _exports.default = _default;
});