define("portal/components/service-bundle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    categories: ['insurance-bundle'],
    serviceList: Ember.computed('model.id', function () {
      return this.get('store').findAll('integration');
    }),
    featureTypeList: ['', 'insurance-protection', 'property-damage'],
    ruleSort: ['rule.rate:asc'],
    featureSort: ['feature.category:asc'],
    sortedRules: Ember.computed.sort('model.rules', 'ruleSort'),
    sortedFeatures: Ember.computed.sort('model.features', 'featureSort'),
    pricingRules: Ember.computed.filterBy('sortedRules', 'type', 'pricing'),
    eligibilityRules: Ember.computed.filterBy('model.rules', 'type', 'eligibility'),
    actions: {
      setParam(param, value) {
        // select field with complex object
        if (typeof value === 'object' && value.value) {
          this.set(`model.${param}`, value.value);
        } else {
          this.set(`model.${param}`, value);
        }
      },

      setFeatureService(feature, value) {
        if (value) {
          feature.set('serviceId', value.get('id'));
        } else {
          feature.set('serviceId', null);
        }
      },

      setFeatureType(feature, value) {
        feature.set('type', value);
      },

      addFeature() {
        let feature = this.get('store').createRecord('bundle-feature');
        this.get('model.features').pushObject(feature);
      },

      addRule(type) {
        let rule = this.get('store').createRecord('bundle-rule', {
          type: type
        });
        this.get('model.rules').pushObject(rule);
      },

      saveBundle() {
        if (!this.get('model.name') || !this.get('model.description') || !this.get('model.category')) {
          this.set('error', 'Please fill in name, description, and category fields');
          return;
        }

        if (this.get('saveBundle')) {
          this.get('saveBundle')(this.get('model'));
        }
      },

      saveFeature(feature) {
        this.set('loadingMessage', 'Saving...');
        feature.save().then(() => {
          this.get('flashMessages').success('Feature Saved Successfully');
        }).catch(() => {
          this.get('flashMessages').danger('An error has occurred.');
        }).finally(() => {
          this.set('loadingMessage', null);
        });
      },

      deleteFeature(feature) {
        if (confirm('Are you sure you want to delete this feature?')) {
          feature.destroyRecord();
        }
      }

    }
  });

  _exports.default = _default;
});