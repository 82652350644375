define("portal/templates/components/pass-fail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YmWMaN9+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"value\"]]],null,{\"statements\":[[7,\"i\",true],[10,\"class\",\"fa fa-check pass\"],[8],[9]],\"parameters\":[]},{\"statements\":[[7,\"i\",true],[10,\"class\",\"fa fa-remove fail\"],[8],[9]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/components/pass-fail.hbs"
    }
  });

  _exports.default = _default;
});