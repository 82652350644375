define("portal/adapters/file", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    // TODO: Can move this to application adapter as we roll out support for more endpoints
    coalesceFindRequests: true,

    urlForFindRecord(id) {
      let baseUrl = this.buildURL();
      return `${baseUrl}/files?ids=${id}`;
    }

  });

  _exports.default = _default;
});