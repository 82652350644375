define("portal/controllers/login", ["exports", "portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    environment: Ember.inject.service(),
    ssoLink: Ember.computed(function () {
      let ssoHost = _environment.default.APP.ssoHost;
      return `${ssoHost}/saml`;
    }),
    actions: {
      async loginSuccess(userId, token) {
        await this.session.userLoggedIn(userId, token);
      }

    }
  });

  _exports.default = _default;
});