define("portal/components/user-partner-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    partnerUser: null,

    didInsertElement() {
      this._super(...arguments);

      this._loadPartners();
    },

    _loadPartners() {
      return this.get('store').query('partner', {}).then(partners => {
        this.set('partners', partners);
      });
    },

    actions: {
      saveChanges() {
        let userPartner = this.get('userPartner');

        if (!userPartner) {
          return;
        }

        if (!userPartner.get('partner.id')) {
          this.get('flashMessages').danger('A partner is required');
          return;
        }

        userPartner.save().then(up => {
          if (this.get('saved')) {
            this.get('saved')(up);
          }
        });
      }

    }
  });

  _exports.default = _default;
});