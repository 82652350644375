define("portal/routes/rentals/show/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RentalNotes extends Ember.Route {
    afterModel(model) {
      let controller = this.controllerFor('rentals/show/notes');
      controller.set('rentalNotes', this.store.query('note', {
        rental_id: model.id,
        internal: true
      }));
    }

  }

  _exports.default = RentalNotes;
});