define("portal/controllers/search", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    queryParams: ['page'],
    page: 1,
    perPage: 20,
    total: 0,
    applicationController: Ember.inject.controller('application'),
    search: Ember.computed.alias('applicationController.search'),
    searchType: Ember.computed.alias('applicationController.searchType'),
    searchFields: Ember.computed('search', 'searchType', function () {
      const type = this.get('searchType');
      const search = this.get('search') || ''; // eslint-disable-next-line no-useless-escape

      let phoneRegex = /^\+*(\(*[0-9]*\)*\-*\s*)*$/;

      if (type === 'user') {
        if (this.isId) {
          return 'customer ID and phone number';
        } else if (phoneRegex.test(search.trim())) {
          return 'phone number';
        }

        return 'customer first/last names, business name and email';
      } else if (type === 'booking') {
        if (this.isId) {
          return 'booking/rental/host/guest ID';
        }

        return 'host and guest emails';
      } else if (type === 'rental') {
        if (this.isId) {
          return 'rental/host ID';
        }

        return 'rental name and VIN';
      } else if (type === 'transaction') {
        if (this.isId) {
          return 'transaction/booking/host/guest ID';
        }

        return 'host first/lastname, business name and host/guest email';
      } else if (type === 'campground') {
        if (this.isId) {
          return 'campground ID';
        }

        return 'campground name, email, website, phone, provider_type or provider_hotel_code';
      }

      return 'customer email and ID';
    }),
    trimmedSearch: Ember.computed('search', function () {
      if (!this.search) {
        return;
      }

      return this.search.trim();
    }),
    campgroundsURL: Ember.computed('trimmedSearch', function () {
      return this.getCampgroundRepoURL(this.trimmedSearch);
    }),
    isId: Ember.computed('trimmedSearch', function () {
      let id = this.trimmedSearch;
      let reg = new RegExp('^[0-9]+$', 'g');

      if (!id) {
        return false;
      }

      return reg.exec(id);
    }),
    searchObserver: Ember.observer('search', 'searchType', function () {
      this.set('page', 1);
      Ember.run.debounce(this, 'send', 'loadResults', 300);
    }),
    actions: {
      clearFilters() {
        this.setProperties({
          search: null,
          page: 1
        });
      },

      setPage(page) {
        this.set('page', page);
        Ember.run.debounce(this, 'send', 'loadResults', 300);
      },

      loadResults() {
        this.loadSearchData.perform();
      }

    },
    loadSearchData: (0, _emberConcurrency.task)(function* () {
      this.set('loadingResults', true);
      const limit = this.perPage;
      const offset = (this.page - 1) * limit;
      const type = this.get('searchType');
      const searchQuery = this.get('search');
      let data = {
        limit: limit,
        offset: offset,
        object_type: type,
        search_query: searchQuery
      };

      try {
        let response = yield this.get('adminRequest').request('admin/search', {
          method: 'GET',
          data: data
        });
        this.normalize(response);
      } catch (err) {
        this.get('flashMessages').danger(err);
        this.setProperties({
          loadingResults: false,
          model: [],
          total: 0
        });
      }
    }).restartable(),

    normalize(response) {
      let results = [];

      if (response.data.object_data) {
        response.data.object_data.forEach(value => {
          let object;

          if (response.data.object_type === 'user') {
            object = {
              resultType: response.data.object_type,
              id: value.id,
              first_name: value.first_name,
              last_name: value.last_name,
              full_name: value.name,
              business_name: value.business_name.toUpperCase(),
              avatar: value.avatar_url,
              phone: value.phone_number,
              email: value.email,
              owned_by_id: value.owned_by_id
            };
          } else if (response.data.object_type === 'booking') {
            object = {
              resultType: response.data.object_type,
              id: value.id,
              to: value.to,
              from: value.from,
              status: value.booking_status,
              owner_id: value.owner_id,
              owner_email: value.owner_email,
              owner_name: value.owner_name,
              renter_email: value.renter_email,
              renter_id: value.renter_id,
              renter_name: value.renter_name,
              campground: value.campground
            };

            if (object.campground) {
              object.campground['campground_url'] = this.getCampgroundRepoURL(object.campground.campground_id);
            }
          } else if (response.data.object_type === 'rental') {
            object = {
              resultType: response.data.object_type,
              id: value.id,
              make: (value === null || value === void 0 ? void 0 : value.make.toUpperCase()) || '',
              model: (value === null || value === void 0 ? void 0 : value.model.toUpperCase()) || '',
              primary_image_url: value.primary_image_url,
              name: value.rental_name.toUpperCase() || 'Missing rental name',
              type: value.type,
              year: value.year,
              owner_id: value.user_id,
              owner_name: value.name,
              owner_email: value.email,
              license_plate: (value === null || value === void 0 ? void 0 : value.license_plate.toUpperCase()) || '',
              vin: (value === null || value === void 0 ? void 0 : value.vin.toUpperCase()) || ''
            };
          } else if (response.data.object_type === 'transaction') {
            object = {
              resultType: response.data.object_type,
              id: value.id,
              type: value.transaction_type,
              amount: value.transaction_amount,
              status: value.transaction_status,
              booking_id: value.booking.id,
              booking_status: value.booking.booking_status,
              owner_id: value.booking.owner_id,
              owner_email: value.booking.owner_email,
              owner_name: value.booking.owner_name,
              renter_id: value.booking.renter_id,
              renter_name: value.booking.renter_name,
              renter_email: value.booking.renter_email
            };
          } else if (response.data.object_type === 'campground') {
            object = {
              resultType: response.data.object_type,
              id: value.id,
              name: value.name,
              email: value.email,
              provider_hotel_code: value.provider_hotel_code,
              phone: value.phone_number,
              website: value.website,
              is_odn: value.is_odn,
              campground_url: this.getCampgroundRepoURL(value.id),
              primary_image_url: value.primary_image_url
            };
          }

          results.push(object);
        });
      }

      this.setProperties({
        loadingResults: false,
        model: results,
        total: response.meta.total
      });
    },

    getCampgroundRepoURL(target) {
      const config = Ember.getOwner(this).resolveRegistration('config:environment') || {};
      return `${config.rootURL}campgrounds/${target}`;
    }

  });

  _exports.default = _default;
});