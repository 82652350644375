define("portal/controllers/users/show/credits", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    earnedCredit: null,
    session: Ember.inject.service(),
    apiError: Ember.inject.service(),
    adminRequest: Ember.inject.service(),

    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    creditSourceOptions: ['support_adjustment', 'support_discount', 'support_refund', 'support_courtesy', 'support_other', 'renter_concierge', 'project_temp_housing', 'marketing', 'marketing_pr', 'influencer', 'claims', 'referee', 'referrer', 'employee_trip', 'employee_business_travel'],
    creditSourceOptionsFiltered: Ember.computed('session.user.adminLevel', function () {
      if (this.get('session.user.adminLevel') > 0) {
        return this.creditSourceOptions;
      }

      return ['support_courtesy'];
    }),
    canEditSource: Ember.computed('earnedCredit.source', function () {
      if (this.get('earnedCredit.isNew')) {
        return true;
      }

      return this.get('earnedCredit.canEdit');
    }),
    availableCredit: Ember.computed('credits.[]', function () {
      return this.get('credits').reduce((previousValue, item) => {
        return previousValue + item.get('amount');
      }, 0);
    }),
    needsAllocation: Ember.computed('credits.[]', function () {
      return this.get('credits').any(item => {
        // all credits need a source and any spent credits should have a parent.
        return item.get('source') === '' || item.get('amount') < 0 && !item.get('parentId');
      });
    }),
    allocateCredits: (0, _emberConcurrency.task)(function* () {
      let userId = this.get('model.id');

      try {
        yield this.get('adminRequest').post(`admin/users/${userId}/allocate-credits`);
        yield this.loadCredits.perform();
      } catch (e) {
        this.logger.error(e);
        this.apiError.danger(e, 'Failed to allocate credits');
      }
    }),
    loadCredits: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.store.query('earned-credit', {
          user_id: this.get('model.id')
        }).then(credits => {
          this.set('credits', credits);
        });
      } catch (e) {
        this.logger.error(e);
        this.apiError.danger(e, 'Failed to load credits');
      }
    }),
    saveCredit: (0, _emberConcurrency.task)(function* () {
      let credit = this.get('earnedCredit');

      if (!credit.get('amount')) {
        this.setProperties({
          error: 'Amount is required'
        });
        return;
      }

      if ((credit.get('description') || '').trim() === '') {
        this.setProperties({
          error: 'Description is required'
        });
        return;
      }

      if (!credit.get('source')) {
        this.setProperties({
          error: 'Source is required'
        });
        return;
      }

      this.setProperties({
        error: null
      });
      let promises = [];

      if (this.get('referredByUserId')) {
        let referredByUser = this.store.findRecord('user', this.get('referredByUserId')).then(referredBy => {
          credit.set('referredByUser', referredBy);
        });
        promises.push(referredByUser);
      }

      if (this.get('referredUserId')) {
        let referredUser = this.store.findRecord('user', this.get('referredUserId')).then(referred => {
          credit.set('referredUser', referred);
        });
        promises.push(referredUser);
      }

      if (this.get('bookingId')) {
        let booking = this.store.findRecord('booking', this.get('bookingId')).then(book => {
          credit.set('booking', book);
        });
        promises.push(booking);
      }

      if (this.get('expiresAt')) {
        credit.set('expires', this.get('expiresAt'));
      } else {
        credit.set('expires', null);
      }

      yield Ember.RSVP.all(promises).then(() => {
        credit.save().then(() => {
          this.loadCredits.perform();
          this.set('earnedCredit', null);
        }).catch(response => {
          let msg = 'There was an error saving the credit';
          msg = this.apiError.getMessage(response, msg);
          this.setProperties({
            error: msg
          });
        });
      }).catch(response => {
        // if any of the promises fails.
        let msg = 'There was an error saving the credit';
        msg = this.apiError.getMessage(response, msg);
        this.setProperties({
          error: msg
        });
      });
    }),
    actions: {
      newCredit() {
        this.set('earnedCredit', this.store.createRecord('earnedCredit', {
          user: this.get('model'),
          expires: null,
          deleted: null
        }));
      },

      editCredit(credit) {
        this.set('earnedCredit', credit);
        this.setProperties({
          referredByUserId: credit.get('referredByUser.id'),
          referredUserId: credit.get('referredUser.id'),
          bookingId: credit.get('booking.id'),
          expiresAt: credit.get('expires')
        });
      },

      closeCredit() {
        this.get('earnedCredit').rollbackAttributes();
        this.set('earnedCredit', null);
      },

      deleteCredit(credit) {
        credit.destroyRecord();
      },

      showRelated(credit) {
        let parentId = parseInt(credit.get('id'));
        this.get('credits').forEach(c => {
          c.set('highlight', false);

          if (parentId === c.get('parentId')) {
            c.set('highlight', true);
          }
        });
        credit.set('highlight', true);
      },

      clearHighlights() {
        this.get('credits').forEach(c => {
          c.set('highlight', false);
        });
      }

    }
  });

  _exports.default = _default;
});