define("portal/controllers/users/show/payments", ["exports", "portal/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    gateways: Ember.computed('loadGatewaysTask.lastSuccessful.value', function () {
      var _this$loadGatewaysTas, _this$loadGatewaysTas2;

      return (_this$loadGatewaysTas = (_this$loadGatewaysTas2 = this.loadGatewaysTask.lastSuccessful) === null || _this$loadGatewaysTas2 === void 0 ? void 0 : _this$loadGatewaysTas2.value) !== null && _this$loadGatewaysTas !== void 0 ? _this$loadGatewaysTas : [];
    }),
    noCards: Ember.computed('cards.[]', function () {
      return this.get('cards.length') === 0;
    }),
    canAddBank: Ember.computed('banks.[]', function () {
      return this.get('model.dealer') || this.get('banks.length') === 0 || this.get('banks').filterBy('archived', false).length === 0;
    }),
    demoEnvironment: Ember.computed(function () {
      return _environment.default.environment === 'demo' || _environment.default.environment === 'development';
    }),
    loadAuthorizations: (0, _emberConcurrency.task)(function* () {
      let results = yield this.adminRequest.request(`admin/users/${this.model.id}/authorizations`);
      Ember.set(this, 'plaidAuthorizations', results);
    }),
    loadGatewaysTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('payment-gateway', {
        owner_id: this.model.id
      });
    }),
    archiveAuth: (0, _emberConcurrency.task)(function* (auth) {
      if (window.confirm(`Are you sure you want to remove this bank?`)) {
        yield this.get('adminRequest').request(`users/authorizations/${auth.authorization_id}`, {
          method: 'DELETE'
        }).then(() => {
          this.loadAuthorizations.perform();
        });
      }
    }),
    archiveGatewayTask: (0, _emberConcurrency.task)(function* (gateway) {
      if (window.confirm(`Are you sure you want to remove this gateway?`)) {
        // using manual api cause destroyRecord causes ember to hide it
        yield this.get('adminRequest').delete(`users/gateways/${gateway.id}`);
        yield this.loadGatewaysTask.perform();
      }
    }),
    archiveBank: (0, _emberConcurrency.task)(function* (bank) {
      if (window.confirm(`Are you sure you want to remove this bank?`)) {
        // using manual api cause destroyRecord causes ember to hide it
        yield this.get('adminRequest').request(`users/banks/${bank.get('id')}`, {
          method: 'DELETE'
        }).then(() => {
          bank.reload();
        });
      }
    }),
    restoreBank: (0, _emberConcurrency.task)(function* (bank) {
      yield this.get('adminRequest').request(`users/banks/${bank.get('id')}/restore`, {
        method: 'POST'
      }).then(() => {
        bank.reload();
      });
    }),
    reloadBanks: (0, _emberConcurrency.task)(function* () {
      let banks = yield this.store.query('paymentProcessor', {
        owner_id: this.userId,
        archived: true
      });
      this.set('banks', banks);
    }),
    syncStripeCard: (0, _emberConcurrency.task)(function* (card) {
      let userId = this.model.get('id');
      yield this.get('adminRequest').patch(`admin/users/${userId}/cards/${card.get('id')}/sync`).then(() => {
        this.set('cards', this.store.query('card', {
          user_id: userId
        }));
      });
    }),
    actions: {
      // add/update bank isn't working for admins.
      // the bank gets added as their bank account and not the user's :(
      addBank(bank) {
        this.set('editBank', bank);
        this.toggleProperty('showBankModal');
      },

      bankConnected() {
        this.toggleProperty('showBankModal');
        this.set('editBank', null);
        this.reloadBanks.perform();
      },

      createTestCard() {
        if (!this.get('demoEnvironment')) {
          return;
        }

        this.get('adminRequest').request(`users/${this.get('model.id')}/test_card`, {
          method: 'POST'
        }).then(() => {
          // reload card
          this.set('cards', this.store.query('card', {
            user_id: this.get('model.id'),
            country: this.get('model.country')
          }));
        }).catch(error => {
          let msg = 'Unable to perform this request';

          if (error && error.payload) {
            msg = error.payload.error;
          }

          this.get('flashMessages').danger(msg);
        });
      }

    }
  });

  _exports.default = _default;
});