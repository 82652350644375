define("portal/adapters/quote-request", ["exports", "@outdoorsyco/ember-shared-data/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'v0',

    pathForType() {
      return `bids`;
    },

    urlForQuery({
      available
    }) {
      let host = this.get('host');
      let namespace = this.get('namespace');
      let url = `${host}/${namespace}/bids`;

      if (available) {
        return `${url}/available`;
      }

      return url;
    }

  });

  _exports.default = _default;
});