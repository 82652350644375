define("portal/adapters/conversation", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const inboxMetaDefaults = {
    all: 0,
    new: 0,
    upcoming: 0,
    completed: 0,
    archived: 0
  };

  var _default = _application.default.extend({
    session: Ember.inject.service(),
    namespace: 'v0',

    handleResponse(status, headers, payload, requestData) {
      this._super(...arguments);

      if (payload && typeof payload === 'object') {
        if (!payload.meta) {
          payload.meta = {};
        }

        payload.meta.inbox = inboxMetaDefaults;

        if (headers['meta'] && typeof headers['meta'] === 'string') {
          Ember.assign(payload.meta.inbox, JSON.parse(headers['meta']), {});
        }
      }

      return this._super(status, headers, payload, requestData);
    }

  });

  _exports.default = _default;
});