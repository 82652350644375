define("portal/components/validations/evident-line/component", ["exports", "ember-concurrency", "ember-window-mock"], function (_exports, _emberConcurrency, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function basePathFor(validationId) {
    return `admin/validations/${validationId}`;
  }

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    adminRequest: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: 'tr',
    check: null,
    booking: null,
    afterChange: null,
    historyVisible: false,
    checkName: 'Evident',
    toUserID: null,
    acceptedDrivers: Ember.A(),
    externalLink: Ember.computed('check.external_id', function () {
      return `https://customer.evidentid.com/#!/outdoorsy/requests/view/${Ember.get(this, 'check.external_id')}`;
    }),
    basePath: Ember.computed('check.validation_id', function () {
      return basePathFor(this.check.validation_id);
    }),
    actions: {
      async showHistory() {
        await this.getHistory.perform();
      },

      updateToUser(option) {
        Ember.set(this, 'toUserID', option.value);
      }

    },
    canRetry: Ember.computed('check.status', function () {
      let status = Ember.get(this, 'check.status');
      return status === 'error' || status === 'failed' && (Ember.get(this, 'check.failure_reason') || '').indexOf('timeout') !== -1;
    }),
    // we currently support reassigning user specific validations for id verification
    canReassign: Ember.computed('check.{status,arguments.booking_id}', 'booking', 'acceptedDrivers.[]', function () {
      let len = this.acceptedDrivers.length || 0;

      if (len <= 1) {
        return false;
      }

      let args = Ember.get(this, 'check.arguments') || {};
      let isUserValidation = Object.keys(args).length === 1 && Ember.isPresent(args.booking_id) && Ember.isPresent(args.user_id);
      return Ember.get(this, 'check.status') === 'passed' && isUserValidation;
    }),
    getHistory: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'historyVisible', true);
      const response = yield this.adminRequest.request(`${this.basePath}/history`);
      Ember.set(this, 'historyEntries', response.history);
    }),
    forceApprove: (0, _emberConcurrency.task)(function* () {
      if (!this.actionReason) {
        return this.flashMessages.danger('Add a reason to continue');
      }

      if (!_emberWindowMock.default.confirm('Are you sure you want to force approve this validation?')) {
        return;
      }

      let basePath = this.basePath;
      let args = Ember.get(this, 'check.arguments') || {};

      try {
        if (this.booking || Ember.isPresent(args.booking_id)) {
          if (!Ember.isPresent(args.booking_id)) {
            // if we don't have a booking id, we need to first duplicate this
            // validation, passing in the current booking id
            const new_args = {
              user_id: this.driver.id,
              booking_id: this.booking ? this.booking.id : Ember.get(this, 'check.arguments.booking_id')
            };
            const newValidationId = yield this._createDuplicateValidation.perform(new_args); // then force approve the new validation

            basePath = basePathFor(newValidationId);
          }
        }

        yield this.adminRequest.request(`${basePath}/force`, {
          method: 'POST',
          data: JSON.stringify({
            reason: this.actionReason
          })
        });
      } catch (error) {
        this.flashMessages.danger('Error force approving validation');
        this.logger.error(error);
      }

      Ember.tryInvoke(this, 'afterChange');
    }),

    _retryMessage() {
      return 'Are you sure you want to create a new validation request? This will void the previous request.';
    },

    retry: (0, _emberConcurrency.task)(function* () {
      if (!Ember.get(this, 'canRetry')) {
        return;
      }

      let info = this._retryMessage();

      if (!confirm(info)) {
        return;
      }

      let valId = Ember.get(this, 'check.validation_id');

      if (!valId) {
        Ember.set(this, 'error', 'No validation id found');
        return;
      }

      try {
        yield this.adminRequest.post(`admin/validations/${valId}/retry`);
        yield (0, _emberConcurrency.timeout)(5000);
        Ember.tryInvoke(this, 'afterChange');
      } catch (error) {
        let msg = 'There was an error retrying the validation';

        if (error && error.payload) {
          msg = `${error.payload.error}`;

          if (error.payload.original_error) {
            msg += `- ${error.payload.original_error}`;
          }
        }

        this.flashMessages.danger(msg);
      }
    }),
    reassign: (0, _emberConcurrency.task)(function* () {
      if (!Ember.get(this, 'canReassign')) {
        return;
      }

      let fromUserID = Ember.get(this, 'check.arguments.user_id');

      if (Ember.isBlank(fromUserID) || Ember.isBlank(this.toUserID)) {
        this.flashMessages.danger('Old and New User ID must be set');
        return;
      }

      let info = `Are you sure you want to re-assign this validation from ${fromUserID} to user ${this.toUserID}`;

      if (!confirm(info)) {
        return;
      }

      let valId = Ember.get(this, 'check.validation_id');

      if (!valId) {
        Ember.set(this, 'error', 'No validation id found');
        return;
      }

      try {
        yield this.adminRequest.post(`admin/validations/${valId}/reassign`, {
          data: JSON.stringify({
            from_user_id: fromUserID,
            to_user_id: String(this.toUserID)
          })
        });
        Ember.tryInvoke(this, 'afterChange');
      } catch (error) {
        let msg = 'There was an error reassigning the validation';

        if (error && error.payload) {
          msg = `${error.payload.error}`;

          if (error.payload.original_error) {
            msg += `- ${error.payload.original_error}`;
          }
        }

        this.flashMessages.danger(msg);
      }
    }),
    delete: (0, _emberConcurrency.task)(function* () {
      let info = `Are you sure you want to delete this validation? We trust you know what you're doing. Ask if you need help`;

      if (!confirm(info)) {
        return;
      }

      let valId = Ember.get(this, 'check.validation_id');

      if (!valId) {
        Ember.set(this, 'error', 'No validation id found');
        return;
      }

      try {
        yield this.adminRequest.delete(`admin/validations/${valId}`);
        Ember.tryInvoke(this, 'afterChange');
      } catch (error) {
        let msg = 'There was an error delete the validation';

        if (error && error.payload) {
          msg = `${error.payload.error}`;

          if (error.payload.original_error) {
            msg += `- ${error.payload.original_error}`;
          }
        }

        this.flashMessages.danger(msg);
      }
    }),
    _createDuplicateValidation: (0, _emberConcurrency.task)(function* (new_args) {
      const response = yield this.adminRequest.request(`${this.basePath}/duplicate`, {
        method: 'POST',
        data: JSON.stringify({
          new_args: new_args
        })
      });
      return response.id;
    })
  });

  _exports.default = _default;
});