define("portal/routes/bookings/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.store.findRecord('booking', params.booking_id);
    },

    async afterModel(booking) {
      let controller = this.controllerFor('bookings/show');
      await controller.loadTSQueueItems.perform(booking);
      await controller.loadUserNotes.perform(booking);

      if (booking.hasApprovedDrivers) {
        await controller.loadBookingDrivers.perform(booking);
      }

      if (booking.isCampsite) {
        await controller.loadCampground.perform(booking);
      }
    }

  });

  _exports.default = _default;
});