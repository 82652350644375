define("portal/templates/rentals/show/insurance/certificate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SnYcbq7c",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[],[[\"@route\"],[\"rentals.show.insurance\"]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"t\",[\"insurance-certificate-back-to-insurance\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n\"],[5,\"insurance/certificate-form\",[],[[\"@rental\"],[[23,0,[\"model\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/rentals/show/insurance/certificate.hbs"
    }
  });

  _exports.default = _default;
});