define("portal/models/card", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  let Card = (_dec = Ember.inject.service, _dec2 = belongsTo('user'), _dec3 = attr('string'), _dec4 = attr('string'), _dec5 = attr('string'), _dec6 = attr('string'), _dec7 = attr('boolean'), _dec8 = attr('string'), _dec9 = attr(), _dec10 = Ember.computed('brand'), (_class = class Card extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "owner", _descriptor2, this);

      _initializerDefineProperty(this, "brand", _descriptor3, this);

      _initializerDefineProperty(this, "lastFour", _descriptor4, this);

      _initializerDefineProperty(this, "expMonth", _descriptor5, this);

      _initializerDefineProperty(this, "expYear", _descriptor6, this);

      _initializerDefineProperty(this, "default", _descriptor7, this);

      _initializerDefineProperty(this, "stripeToken", _descriptor8, this);

      _initializerDefineProperty(this, "paymentProcessor", _descriptor9, this);
    }

    get slug() {
      return Ember.String.dasherize(this.brand || 'unknown');
    }

    get iconUrl() {
      const ICONS = {
        unknown: '/images/payment-method/unknown.png',
        visa: '/images/payment-method/visa.png',
        'american-express': '/images/payment-method/american-express.png',
        'master-card': '/images/payment-method/master-card.png',
        discover: '/images/payment-method/discover.png',
        'diners-club': '/images/payment-method/diners-club.png',
        jcb: '/images/payment-method/jcb.png'
      };
      return ICONS[this.slug] || ICONS['unknown'];
    }

    get isStripe() {
      return this.paymentProcessor.name === 'stripe';
    }

    get displayPaymentProcessor() {
      if (!this.paymentProcessor.name) {
        return '';
      }

      if (['stripe', 'affirm'].includes(this.paymentProcessor.name.toLowerCase())) {
        return this.paymentProcessor.name;
      }

      return this.intl.t(`term-payment-processor-${this.paymentProcessor.name}`);
    }
    /**
     * Deprecated. Don't use.
     */


    get brandInfo() {
      let brand = this.get('brand') || '';
      let slug = '';
      let friendlyName = '';
      let faIcon = '';

      switch (brand.toLowerCase()) {
        case 'visa':
          faIcon = 'fa-cc-visa';
          slug = 'visa';
          friendlyName = 'Visa Credit Card';
          break;

        case 'american express':
          faIcon = 'fa-cc-amex';
          slug = 'american-express';
          friendlyName = 'American Express Credit Card';
          break;

        case 'mastercard':
          faIcon = 'fa-cc-mastercard';
          slug = 'mastercard';
          friendlyName = 'MasterCard Credit Card';
          break;

        case 'discover':
          faIcon = 'fa-cc-discover';
          slug = 'discover';
          friendlyName = 'Discover Credit Card';
          break;

        case 'diners club':
          faIcon = 'fa-cc-diners-club';
          slug = 'diners-club';
          friendlyName = 'Diners ClubCredit Card';
          break;

        case 'jcb':
          faIcon = 'fa-cc-jcb';
          slug = 'jbc';
          friendlyName = 'JCB Credit Card';
          break;

        default:
          faIcon = 'fa-credit-card';
          slug = 'unknown';
          break;
      }

      return {
        slug: slug,
        image: `/img/credit-cards/${slug}.png`,
        cardName: friendlyName,
        faIcon: faIcon
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "owner", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "lastFour", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "expMonth", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "expYear", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "default", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "stripeToken", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "paymentProcessor", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "brandInfo", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "brandInfo"), _class.prototype)), _class)); // DOCS http://docs.outdoorsy.apiary.io/#reference/cards/cards-collection/read-many-cards

  _exports.default = Card;
});