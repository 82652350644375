define("portal/routes/users/show/payments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),

    setupController(controller, model) {
      this._super(...arguments);

      this.set('userId', model.id);
      controller.set('cards', this.store.query('card', {
        user_id: this.userId
      }));
      controller.set('banks', this.store.query('paymentProcessor', {
        owner_id: this.userId,
        archived: true
      }));
      controller.loadAuthorizations.perform();
    },

    actions: {
      async removeCard(card) {
        if (confirm('Are you sure you want to delete this card? This cannot be undone.')) {
          try {
            await card.destroyRecord();
            this.send('reloadCards');
            this.flashMessages.success('Payment Method Removed');
          } catch (error) {
            this.logger.error(error);
            this.flashMessages.danger('Error Removing Payment Method');
          }
        }
      },

      async makePrimaryCard(card) {
        card.set('default', true);

        try {
          await card.save();
          this.send('reloadCards');
          this.flashMessages.success('Card Set to Default');
        } catch (error) {
          this.logger.error(error);
          this.flashMessages.danger('Error Setting Card to Default');
        }
      },

      reloadCards() {
        Ember.run.later(this, () => {
          let controller = this.controllerFor('users.show.payments');
          controller.set('cards', this.store.query('card', {
            user_id: this.userId
          }));
        });
      }

    }
  });

  _exports.default = _default;
});