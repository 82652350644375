define("portal/routes/rentals/show/competitive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel(model) {
      let controller = this.controllerFor('rentals/show/competitive');
      Ember.run.scheduleOnce('afterRender', () => {
        controller.get('_loadStats').perform(model.get('id'));
        controller.get('_loadCompSet').perform(model.get('id'));
      });
    }

  });

  _exports.default = _default;
});