define("portal/components/partner-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    partner: null,
    flashMessages: Ember.inject.service(),
    actions: {
      savePartner() {
        let cp = this.get('partner');

        if (!cp) {
          return;
        }

        cp.save().then(p => {
          if (this.get('savedPartner')) {
            this.get('savedPartner')(p);
          }
        }).catch(response => {
          let msg = 'There was an issue saving your changes';

          if (response.errors && response.errors.length) {
            msg = response.errors[0].title;
          }

          this.get('flashMessages').danger(msg);
        });
      }

    }
  });

  _exports.default = _default;
});