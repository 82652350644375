define("portal/adapters/application", ["exports", "@outdoorsyco/ember-shared-data/adapters/application", "portal/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const adminEndpoints = [// :user_id is the literal string, <id> will be replaced by id
  'users/:user_id/activity', 'users/:user_id/cards', {
    path: 'users/:user_id/cards/<id>',
    types: ['deleteRecord']
  }, 'bookings', 'blackouts', 'conversations', 'conversations/<id>', 'discount-codes', 'notes', 'rentals', {
    path: 'tags',
    types: ['createRecord']
  }];

  var _default = _application.default.extend({
    session: Ember.inject.service(),
    namespace: 'v0',
    host: _environment.default.APP.apiHost,

    buildURL(modelName, id, snapshot, requestType
    /*, query */
    ) {
      // prepend /admin to the path, if it's an adminEndpoint
      let url = this._super(...arguments);

      adminEndpoints.forEach(endpoint => {
        const path = (endpoint.path || endpoint).replace('<id>', id);
        const matchesPath = url === `${this.host}/${this.namespace}/${path}`;
        const types = endpoint.types;
        const matchesType = !types || types.includes(requestType);

        if (matchesPath && matchesType) {
          url = `${this.host}/${this.namespace}/admin/${path}`;
        }
      });
      return url;
    },

    // ember-simple-auth
    authorize(xhr) {
      let accessToken = this.get('session.authKey');

      if (Ember.isPresent(accessToken)) {
        xhr.setRequestHeader('Admin', `Token=${accessToken.replace('guest:', '')}`);
      }
    }

  });

  _exports.default = _default;
});