define("portal/controllers/bookings/show/reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ownerReview: Ember.computed('reviews.[]', function () {
      return (this.reviews || Ember.A()).findBy('reviewer.id', Ember.get(this, 'model.owner.id'));
    }),
    renterReview: Ember.computed('reviews.[]', function () {
      return (this.reviews || Ember.A()).findBy('reviewer.id', Ember.get(this, 'model.renter.id'));
    }),
    actions: {
      editReview(model) {
        this.set('editReview', model);
      },

      closeReview() {
        this.set('editReview', null);
      },

      deleteReview(model) {
        if (confirm('Are you sure you want to delete this review? It cannot be recovered.')) {
          model.destroyRecord();
        }
      }

    }
  });

  _exports.default = _default;
});