define("portal/controllers/users/show/iterable", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const did = 'iterable-test';
  const sid = 'iterable-test';
  const appId = 'iterable-test';

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.eventOptions = [{
        name: 'Server: Request created',
        data: {
          actor: 'outdoorsy-renter',
          app_id: 'golang-api',
          booking_id: 1,
          booking_url: 'https://www.outdoorsy.com',
          context: {},
          did: did,
          event: 'Server: Request created',
          from: '2020-10-17',
          includes_addons: false,
          instamatch: false,
          instant_book: false,
          library: appId,
          owner_approved: false,
          recipient: 'outdoorsy-owner',
          rental_id: 95746,
          renter_approved: false,
          role: 'renter',
          rv_image_url: 'https://res.cloudinary.com/outdoorsy/image/upload/v1547735714/p/rentals/95746/images/xtfeb0i0ttntxvgaxzvz.jpg',
          rv_name: 'VanGoGoUSA - 2018 Custom Campervan',
          sid: sid,
          status: 'negotiating',
          to: '2020-10-20',
          type: 'event'
        }
      }, {
        name: 'Server: Booking sealed',
        data: {
          app_id: 'golang-api',
          booking_id: 1,
          context: {},
          event: 'Server: booking sealed',
          library: appId,
          role: 'renter',
          type: 'event'
        }
      }, {
        name: 'Checkout step viewed - message',
        data: {
          device: 'd',
          library: appId,
          app_id: appId,
          url: 'https://www.outdoorsy.com/dashboard/checkout/1/addons',
          path: '/dashboard/checkout/1/addons',
          search: '',
          hostname: 'www.outdoorsy.com',
          event: 'Checkout Step Viewed',
          checkout_id: '1',
          step: 1,
          step_name: 'message',
          sleeps: 8,
          rental_id: '95746',
          checkout_name: 'renter-request',
          value: 998.9,
          total: 1101.7,
          coupon: '',
          discount: 0,
          currency: 'USD',
          from: '2020-10-17',
          to: '2020-10-20',
          experiments: {},
          type: 'event',
          sid: sid,
          did: did,
          booking_id: '1'
        }
      }, {
        name: 'Checkout step viewed - payment',
        data: {
          device: 'd',
          library: appId,
          app_id: appId,
          url: 'https://www.outdoorsy.com/dashboard/checkout/1/addons',
          path: '/dashboard/checkout/1/addons',
          search: '',
          hostname: 'www.outdoorsy.com',
          event: 'Checkout Step Viewed',
          checkout_id: '1',
          step: 3,
          step_name: 'payment',
          sleeps: 8,
          rental_id: '95746',
          checkout_name: 'renter-request',
          value: 998.9,
          total: 1101.7,
          coupon: '',
          discount: 0,
          currency: 'USD',
          from: '2020-10-17',
          to: '2020-10-20',
          experiments: {},
          type: 'event',
          sid: sid,
          did: did,
          booking_id: '1'
        }
      }, {
        name: 'Checkout step completed - message',
        data: {
          device: 'd',
          library: appId,
          app_id: appId,
          url: 'https://www.outdoorsy.com/dashboard/checkout/1/addons',
          path: '/dashboard/checkout/1/addons',
          search: '',
          hostname: 'www.outdoorsy.com',
          event: 'Checkout Step Completed',
          checkout_id: '1',
          step: 1,
          step_name: 'message',
          sleeps: 8,
          rental_id: '95746',
          checkout_name: 'renter-request',
          value: 998.9,
          total: 1101.7,
          coupon: '',
          discount: 0,
          currency: 'USD',
          from: '2020-10-17',
          to: '2020-10-20',
          experiments: {},
          type: 'event',
          sid: sid,
          did: did,
          booking_id: '1'
        }
      }, {
        name: 'Checkout step completed - payment',
        data: {
          device: 'd',
          library: appId,
          app_id: appId,
          url: 'https://www.outdoorsy.com/dashboard/checkout/1/addons',
          path: '/dashboard/checkout/1/addons',
          search: '',
          hostname: 'www.outdoorsy.com',
          event: 'Checkout Step Completed',
          checkout_id: '1',
          step: 3,
          step_name: 'payment',
          sleeps: 8,
          rental_id: '95746',
          checkout_name: 'renter-request',
          value: 998.9,
          total: 1101.7,
          coupon: '',
          discount: 0,
          currency: 'USD',
          from: '2020-10-17',
          to: '2020-10-20',
          experiments: {},
          type: 'event',
          sid: sid,
          did: did,
          booking_id: '1'
        }
      }, {
        name: 'Searched for a rental',
        data: {
          device: 'm',
          referrer: 'https://www.google.com/',
          library: appId,
          sent_at: '2019-7-9 23:16:11',
          app_id: appId,
          url: 'https://www.outdoorsy.com/rv-search?feature=&ne_lat=47.766889&ne_lng=-122.6118458&page=1&q=Poulsbo%2C%20Washington%2C%20United%20States&score=&sleeps=2&style=&sw_lat=47.71730489999999&sw_lng=-122.6758709&type=',
          path: '/rv-search',
          search: '?feature=&ne_lat=47.766889&ne_lng=-122.6118458&page=1&q=Poulsbo%2C%20Washington%2C%20United%20States&score=&sleeps=2&style=&sw_lat=47.71730489999999&sw_lng=-122.6758709&type=',
          hostname: 'www.outdoorsy.com',
          title: 'Get Great Rates on Amazing RV Rentals | Outdoorsy',
          filters: {
            page: 1,
            q: 'Poulsbo, Washington, United States',
            neLat: '47.766889',
            neLng: '-122.6118458',
            swLat: '47.71730489999999',
            swLng: '-122.6758709',
            style: ['drive'],
            sleeps: 2,
            score: '',
            minPrice: null,
            maxPrice: null,
            from: '2020-08-08',
            to: '2020-08-14',
            keywords: null,
            relativeDate: null,
            datePeriod: null,
            deliveryRadius: 0,
            lat: null,
            lng: null,
            perPage: 26
          },
          event: 'Searched for a rental',
          perPage: 26,
          lat: 47.74210140107931,
          lng: -122.643873595555,
          experiments: {},
          type: 'event',
          sid: sid,
          did: did
        }
      }, {
        name: 'Viewed Product',
        data: {
          device: 'm',
          library: appId,
          sent_at: '2019-7-9 23:22:18',
          app_id: appId,
          url: 'https://www.outdoorsy.com/rv-rental/powell_oh/2014_thor-motor-coach_hurricane_105702-listing?from=2019-08-08&to=2019-08-14',
          path: '/rv-rental/powell_oh/2014_thor-motor-coach_hurricane_105702-listing',
          search: '?from=2019-08-08&to=2019-08-14',
          hostname: 'www.outdoorsy.com',
          title: '2013 Thor Motor Coach Hurricane Motor Home Class A Rental in Powell, OH | Outdoorsy',
          event: 'Viewed Product',
          product_id: '1',
          sku: '1',
          category: 'a',
          name: '2013 Thor Motor Coach Hurricane',
          sleeps: 7,
          rental_id: '1',
          image_url: 'https://res.cloudinary.com/outdoorsy/image/upload/a_exif,q_auto,f_auto,w_auto,e_improve,h_630,w_1200,c_fill/c_scale,e_overlay,g_south_east,l_outdoorsy-logo,w_150,x_10,y_10/v1548277425/p/rentals/105702/images/kgbfwihrulnv3hfplfpf.jpg',
          from: '2020-08-08',
          to: '2020-08-14',
          instant_book: true,
          experiments: {},
          type: 'event',
          sid: sid,
          did: did
        }
      }, {
        name: 'Saved a search',
        data: {
          app_id: appId,
          context: {},
          did: did,
          event: 'Server: Saved a search',
          filters: {
            destination: 'Calgary, AB',
            from: '2020-08-23',
            q: 'Calgary, AB',
            sleeps: 1,
            style: 'drive',
            to: '2020-08-27'
          },
          from: '2020-08-23',
          library: appId,
          sid: sid,
          to: '2020-08-27',
          type: 'event'
        }
      }, {
        name: 'Favorited a listing',
        data: {
          app_id: appId,
          context: {},
          details: {
            image_url: 'https://res.cloudinary.com/outdoorsy/image/upload/v1533067297/p/rentals/78832/images/circsbk78zkzuxlptbxt.jpg',
            rental_location: {
              city: 'Longmont',
              country: 'US',
              lat: 0,
              lng: 0,
              state: 'CO'
            },
            rental_name: 'Georgie Boy 36 Encounter',
            rental_price: 10500,
            rental_url: '/rv-rental/longmont_co/2002_georgie-boy_36-encounter_78832-listing'
          },
          did: did,
          event: 'Server: Favorited a listing',
          library: appId,
          rental_id: 1,
          sid: sid,
          type: 'event'
        }
      }];
      let imageCount = 16;
      this.imageNumber = Math.floor(Math.random() * imageCount) + 1;
    },

    selectedEvent: null,
    actions: {
      eventSelected(event) {
        let data = Object.assign({}, event.data, {
          uid: this.model.id
        });
        this.set('tempData', JSON.stringify(data, null, 2));
        this.set('selectedEvent', event);
      }

    },
    _sendEvent: (0, _emberConcurrency.task)(function* () {
      try {
        let data = JSON.stringify(JSON.parse(this.tempData));
        yield this.ajax.request('https://nmj4dtb1ag.execute-api.us-west-2.amazonaws.com/production/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        });
        this.flashMessages.success('Event Sent!');
      } catch (e) {
        this.flashMessages.danger(`Error: ${e}`);
      }
    })
  });

  _exports.default = _default;
});